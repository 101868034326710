import SettingsIcon from '@material-ui/icons/Settings';
import Accordion from "components/Accordion/Accordion";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import { LoginContext } from 'contexts/LoginContext';
import { useContext, useEffect } from 'react';
import ConfigCity from "views/Components/SystemConfigurations/ConfigCity";
import ConfigClass from "views/Components/SystemConfigurations/ConfigClass";
import ConfigEduBoard from "views/Components/SystemConfigurations/ConfigEduBoard";
import ConfigEducation from "views/Components/SystemConfigurations/ConfigEducation";
import ConfigObjective from "views/Components/SystemConfigurations/ConfigObjective";
import ConfigProfession from "views/Components/SystemConfigurations/ConfigProfession";
import ConfigState from "views/Components/SystemConfigurations/ConfigState";
import ConfigTargetAudience from "views/Components/SystemConfigurations/ConfigTargetAudience";
import IntrestListing from "views/Components/SystemConfigurations/IntrestListing";

function SystemConfigurationListing(props) {
  const { userPrivileges, providerType, setTestPageState, setQuestionPageState, setCoursePageState } = useContext(LoginContext);
  useEffect(() => {
    setTestPageState({ pageSize: 10, page: 1 });
    setQuestionPageState({ pageSize: 10, page: 1 });
    setCoursePageState({ pageSize: 10, page: 1 })

  }, [])
  console.log(providerType, 'providerTypeId')
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.SystemConfiguration.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  return (
    <div>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <SettingsIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {/* <h1>System Configurations</h1> */}
          {/* <GridItem xs={12} sm={12} md={6}>
            <h1>Users Listing</h1>
          </GridItem> */}
          {
            isPrivilege("ViewSystemConfiguration") == true ?
              <Accordion
                collapses={[
                  {
                    title: <h4>Interest</h4>,
                    content:

                      <IntrestListing></IntrestListing>
                  },
                  {
                    title: <h4>Class</h4>,
                    content:
                      <ConfigClass configurationType="Class"></ConfigClass>
                  },
                  {
                    title: <h4>State</h4>,
                    content:
                      <ConfigState configurationType="State"></ConfigState>
                  },
                  {
                    title: <h4>City</h4>,
                    content:
                      <ConfigCity configurationType="City"></ConfigCity>
                  },
                  {
                    title: <h4>Education</h4>,
                    content:
                      <ConfigEducation configurationType="Education"></ConfigEducation>
                  },
                  {
                    title: <h4>Education Board</h4>,
                    content:
                      <ConfigEduBoard configurationType="EduBoard"></ConfigEduBoard>
                  },
                  ,
                  {
                    title: <h4>Profession</h4>,
                    content:
                      <ConfigProfession configurationType="Profession"></ConfigProfession>
                  },
                  providerType != 3 && {

                    title: <h4>Objective</h4>,

                    content:
                      <ConfigObjective configurationType="Objective"></ConfigObjective>

                  },
                  providerType != 3 &&
                  {

                    title: <h4>Target Audience</h4>,

                    content:
                      <ConfigTargetAudience configurationType="TargetAudience"></ConfigTargetAudience>

                  }


                ].filter(Boolean)} /> : <h4 style={{ textAlign: 'center' }}>System configurations not found</h4>
          }
        </CardBody>
      </Card>

    </div>
  );
}

export default SystemConfigurationListing;
