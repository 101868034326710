import { List, ListItem, ListItemText, TextField } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

import CustomAlert from "components/CustomAlert/CustomAlert";
import { LoginContext } from "contexts/LoginContext";
import { getProviderById } from "hooks/providerHooks/useGetAllProvider";
import useUpdateProvider from "hooks/providerHooks/useUpdateProvider";
import { useContext, useEffect, useState } from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditProvider = ({ afterFinish, providerIdEdit }) => {
  const { providerId, setProviderName, setProviderCreatedDate, userPrivileges } = useContext(
    LoginContext
  );
  const [name, setName] = React.useState("");
  const [nameState, setnameState] = React.useState("");
  const [detailedName, setDetailedName] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [providerTypeId, setProviderTypeId] = React.useState(0);
  const [packageId, setPackageId] = React.useState(0);
  const [mobile, setMobile] = React.useState("");
  const [mobileState, setmobileState] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneState, setphoneState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailState, setemailState] = React.useState("");
  const [providerTypeName, setProviderTypeName] = useState("");
  const [packageTypeName, setPackageTypeName] = useState("");
  const [showContentsCurator, setShowContentsCurator] = useState(false);
  const [showAlert, setShowAlert] = useState(null);
  const [flag, setFlag] = useState(false);
  const [fromDateValue, setFromDateValue] = useState(null);
  const [toEndDateValue, setToEndDateValue] = useState(null);
  const [providerData, setProviderData] = useState({
    provider: {
      providerId: 0,
      name,
      detailedName,
      providerTypeId,
      packageId,
      logo: "",
      website,
      address,
      mobile,
      phone,
      email,
      gstInPercentage: 0,
      gstNumber: "",
      isActive: true,
      createdDate: new Date(),
      updatedDate: new Date(),
    },
    contentCuratorIds: [],
  });

  console.log("providerId: ", providerId, providerIdEdit);
  useEffect(() => {
    const providerKeyData = async () => {
      try {
        if (providerIdEdit == null) {
          providerIdEdit = providerId;
        }
        const provider = await getProviderById(providerIdEdit);
        console.log("provider data:", provider);
        console.log("providerEdit", providerIdEdit);
        setProviderData(provider);
        setProviderName(provider?.provider?.name);
        setProviderCreatedDate(provider?.provider?.createdDate);
      } catch (error) {
        console.error("Error fetching provider data:", error);
      }
    };

    if (providerIdEdit == 0) {
      providerKeyData();
    }
    if (providerIdEdit) {
      providerKeyData();
    }
  }, [providerIdEdit, providerId]);

  const convertToLocalTime = (utcDateTime) => {
    const date = new Date(utcDateTime);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    console.log("providerData updated:", providerData);

    const setData = () => {
      setName(providerData.provider.name);
      setEmail(providerData.provider.email);
      setWebsite(providerData.provider.website);
      setMobile(providerData.provider.mobile);
      setPhone(providerData.provider.phone);
      setDetailedName(providerData.provider.detailedName);
      setAddress(providerData.provider.address);
      setProviderTypeName(
        getProviderTypeName(providerData.provider.providerTypeId)
      );
      setProviderTypeId(providerData.provider.providerTypeId);
      setPackageId(providerData.provider.packageId);
      setPackageTypeName(
        getLicensePackageName(providerData.provider.packageId)
      );
      console.log(
        "provideridss",
        providerTypeId,
        providerData.provider.providerTypeId
      );
      setShowContentsCurator(
        providerData.provider.providerTypeId === 3 ||
        providerData.provider.providerTypeId === 1
      );
      let toLocalFrom = convertToLocalTime(providerData.provider.validFrom);
      let toLocalToEnd = convertToLocalTime(providerData.provider.validTo);
      setFromDateValue(toLocalFrom);
      setToEndDateValue(toLocalToEnd);
    };
    setData();
  }, [providerData]);

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length != length) {
      return false;
    }
    return true;
  };

  const verifyNameLength = (value, length) => {
    if (value.length < length) {
      return false;
    }
    return true;
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const getLicensePackageName = (packageId) => {
    console.log("providertypeid", packageId);
    switch (packageId) {
      case 1:
        return "Silver";
      case 2:
        return "Gold";
      case 3:
        return "Platinum";
      default:
        return "";
    }
  };

  const getProviderTypeName = (providerTypeId) => {
    console.log("providertypeid", providerTypeId);
    switch (providerTypeId) {
      case 1:
        return "ERP";
      case 2:
        return "Content Partner";
      case 3:
        return "Subscriber";
      case 4:
        return "Simplified Test";
      case 5:
        return "Aggregator";
      default:
        return "";
    }
  };
  const updateProviderMutation = useUpdateProvider();
  const handleSubmit = async () => {
    // providerData.provider.name= document.getElementById("Name").value;
    // providerData.provider.email= document.getElementById("Email").value;
    // providerData.provider.website= document.getElementById("Website").value;
    // providerData.provider.mobile= document.getElementById("Mobile").value;
    // providerData.provider.phone= document.getElementById("Phone").value;
    // providerData.provider.detailedName= document.getElementById("Details").value;
    // providerData.provider.address= document.getElementById("Address").value;
    providerData.provider.name = name;
    providerData.provider.validFrom = new Date(fromDateValue);
    providerData.provider.validTo = new Date(toEndDateValue);
    providerData.provider.email = email;
    providerData.provider.website = website;
    providerData.provider.mobile = mobile;
    providerData.provider.phone = phone;
    providerData.provider.detailedName = detailedName;
    providerData.provider.address = address;
    console.log("providertypeId", providerTypeId, providerTypeName, packageId);
    providerData.provider.providerTypeId = providerTypeId;
    //providerData.provider.providerTypeId = providerData.providerTypeId,
    providerData.provider.packageId = packageId;
    providerData.provider.createdDate = new Date();

    if (providerData.contentCuratorIds == null) {
      providerData.contentCuratorIds = [];
    }
    console.log(providerData, "providerpayload");

    try {
      const isNameValid = verifyNameLength(name, 3);
      const isEmailValid = verifyEmail(email);
      const isMobileValid = verifyLength(mobile, 10);
      if (isNameValid && isEmailValid && isMobileValid) {
        await updateProviderMutation.mutateAsync(providerData);
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="success"
            alertMessage={
              updateProviderMutation?.data ||
              "Provider details updated successfully"
            }
          />
        );
        setTimeout(() => {
          afterFinish();
        }, 2000);
      } else {
        if (!isNameValid) {
          setnameState("error");
        }
        if (!isEmailValid) {
          setemailState("error");
        }

        if (!isMobileValid) {
          setmobileState("error");
        }
      }
    } catch (error) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage={
            updateProviderMutation?.data || "Error while updating profile"
          }
        />
      );
    }
  };


  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Provider?.indexOf(x) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setFromDateValue(newStartDate);
  };
  const handleEndDateChange = (event) => {
    setToEndDateValue(event.target.value);
  };
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 10); // Format: YYYY-MM-DDTHH:MM
  };

  return (
    <Card style={{ width: "1200px", height: "500px", margin: "10px" }}>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <Person />
        </CardIcon>
        <h4 style={{ margintop: "5px", marginLeft: "10px", color: "black" }}>
          Edit Profile
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={nameState === "success"}
              error={nameState === "error"}
              labelText={
                <span>
                  Name<small>(required)</small>
                </span>
              }
              id="Name"
              inputProps={{
                name: "Name",
                value: name || "",
                onChange: (event) => {
                  if (!verifyNameLength(event.target.value, 3)) {
                    setnameState("error");
                  }
                  setName(event.target.value);
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              labelText="Details"
              id="Details"
              inputProps={{
                name: "details",
                value: detailedName || "",
                onChange: (event) => {
                  setDetailedName(event.target.value);
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={4}>
            <CustomInput
              labelText="Website"
              id="Website"
              inputProps={{
                name: "website",
                value: website || "",
                onChange: (event) => {
                  setWebsite(event.target.value);
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={emailState === "success"}
              error={emailState === "error"}
              labelText={
                <span>
                  Email<small>(required)</small>
                </span>
              }
              id="Email"
              inputProps={{
                name: "email",
                value: email || "",
                onChange: (event) => {
                  if (!verifyEmail(event.target.value)) {
                    setemailState("error");
                  } else {
                    setemailState("success");
                  }
                  setEmail(event.target.value);
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={mobileState === "success"}
              error={mobileState === "error"}
              labelText={
                <span>
                  Mobile<small>(required)</small>
                </span>
              }
              id="Mobile"
              inputProps={{
                name: "mobile",
                value: mobile || "",
                onChange: (event) => {
                  if (!verifyLength(event.target.value, 10)) {
                    setmobileState("error");
                  } else {
                    setmobileState("success");
                  }
                  setMobile(event.target.value);
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              labelText="Phone"
              id="Phone"
              inputProps={{
                name: "phone",
                value: phone || "",
                onChange: (event) => {
                  setPhone(event.target.value);
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
        </GridContainer>
        {/* <GridContainer>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  labelText="City"
                  id="city"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  labelText="Country"
                  id="country"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  labelText="Postal Code"
                  id="postalCode"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer> */}
        <GridContainer align="left">
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Address"
              id="Address"
              inputProps={{
                name: "address",
                value: address || "",
                onChange: (event) => {
                  setAddress(event.target.value);
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={2}>
            <CustomInput
              labelText="Package"
              id="Package"
              value={packageTypeName}
              inputProps={{
                name: "package",
                value: packageTypeName || "",
              }}
              disabled={true}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            {/* <CustomInput
              labelText="Provider Link"
              id="ProviderUrl"
              inputProps={{
                name: "providerUrl",
                value: providerData?.providerUrl || '',
              }}
              formControlProps={{
                fullWidth: true,
              }}
              disabled={true}
            /> */}
          </GridItem>
          <GridItem xs={12} sm={1} style={{ marginTop: "25px" }}>
            {/* <Tooltip title="Copy Url">
              <Button
                size="sm"
                justIcon
                round
                onClick={(e) => {
                  handleCopy(providerData?.providerUrl); // Copy text to clipboard
                }}
                style={{ size: "1px", backgroundColor: "#DD4B39" }}
              >
                <LinkIcon />
              </Button>
            </Tooltip> */}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={3}>
            <CustomInput
              labelText="Provider Type"
              id="ProviderType"
              value={providerTypeName}
              inputProps={{
                name: "providerType",
                value: providerTypeName || "",
              }}
              disabled={true}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
          {showContentsCurator &&
            providerData.contentCuratorNames &&
            providerData.contentCuratorNames.$values && (
              <GridItem xs={12} sm={4}>
                <div style={{ margintop: "10px" }}>
                  <small>Contents Curator</small>
                </div>
                <div style={{ maxHeight: "90px", overflowY: "auto" }}>
                  {console.log(
                    providerData.contentCuratorNames.$values,
                    "help"
                  )}

                  <List>
                    {providerData.contentCuratorNames.$values.map(
                      (item, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={item} />
                        </ListItem>
                      )
                    )}
                  </List>
                </div>
              </GridItem>
            )}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            {providerId == 0 && (
              <TextField
                type="date"
                name="fromDateValue"
                value={fromDateValue}
                onChange={handleStartDateChange}
                label="Valid From"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: getCurrentDateTime(),
                  max: toEndDateValue, // Disable dates greater than maxEndDate
                }}
                style={{ borderColor: "primary", marginBottom: 11 }}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            {providerId == 0 && (
              <TextField
                type="date"
                name="toEndDateValue"
                value={toEndDateValue}
                onChange={handleEndDateChange}
                label="Valid To"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: getCurrentDateTime(),
                }}
                style={{ borderColor: "primary", marginBottom: 11 }}
              />
            )}
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            align="right"
            marginbottom="5px"
            justify="flex-end"
          >
            {
              isPrivilege("HideUpdateProfile") == true ?
                ''
                : (
                  <Button
                    color="rose"
                    onClick={handleSubmit}
                    disabled={updateProviderMutation.isLoading}
                  >
                    {updateProviderMutation.isLoading
                      ? "Updating..."
                      : "Update Profile"}
                  </Button>
                )}
          </GridItem>
        </GridContainer>
      </CardBody>
      {/* <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar> */}
      {showAlert}
    </Card>
  );
};

export default EditProvider;
