import React, { useContext } from "react";
// @material-ui/icons
import { LoginContext } from "contexts/LoginContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { DeleteForever, WarningOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import Publish from "@material-ui/icons/Publish";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { TestContext } from "contexts/TestContext";
import PoolQuestionMappedGrid from "views/Components/PoolQuestionMappedGrid";
import PoolQuestionMappingGrid from "views/Components/PoolQuestionMappingGrid";
import PoolForm from "../PoolForms/PoolForm";

import { IconButton, Paper } from "@material-ui/core";

import { Slide } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CircularProgress } from "@mui/material";
import { configUrl } from "config.js";
import useDeletePool from "hooks/poolHooks/useDeletePool";
import useGetPoolsByTest from "hooks/poolHooks/useGetPoolsByTest";
import usePoolLockUnLock from "hooks/poolHooks/usePoolLockUnLock";
import useDecreasePoolSequence from "hooks/testsHooks/useDecreasePoolSequence";
import useIncreasePoolSequence from "hooks/testsHooks/useIncreasePoolSequence";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from '../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const styles1 = {
  ...styles,
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles1);

const TestPoolMapping = React.forwardRef((props, ref) => {

  const classes = useStyles();

  const { testId } = useContext(TestContext);
  console.log(testId)
  console.log(props, "propsss")
  const { data: poolsRaw, isLoading, refetch } = useGetPoolsByTest(testId);
  const decreaseSequence = useDecreasePoolSequence();
  const increaseSequence = useIncreasePoolSequence();

  console.log(poolsRaw, "poolsraw data");
  const lockUnlock = usePoolLockUnLock();
  const deletePool = useDeletePool();

  const [pools, setPools] = React.useState([]);

  const [testCode, setTestCode] = React.useState(props.st.testCode);
  const [testCodeState, setTestCodeState] = React.useState("");

  const [text, settext] = React.useState(props.st.text);
  const [textState, settextState] = React.useState("");

  const [objective, setObjective] = React.useState(props.st.objective);
  const [objectiveState, setObjectiveState] = React.useState("");

  const [questionImportModal, setQuestionImportModal] = React.useState(null);

  const [questionMapModal, setQuestionMapModal] = React.useState(null);

  const [questionMappedModal, setQuestionMappedModal] = React.useState(null);

  const [poolEditModal, setPoolEditModal] = React.useState(null);

  const [alert, setAlert] = React.useState(null);
  const { user, providerId, licensePackageObject } = useContext(LoginContext);
  const [flag, setFlag] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(null)


  React.useEffect(() => {
    if (poolsRaw) {
      if (poolsRaw.length == 1) {

        console.log('in here')

        setPools(poolsRaw.map(x => {
          x.showQuestions = true
          return x
        }))

        return
      }
      console.log('updatedData')
      setPools(poolsRaw);
    }
  }, [poolsRaw]);
  React.useEffect(() => {
    if (increaseSequence.isSuccess) {
      refetch();
    }
  }, [increaseSequence.isSuccess]);

  React.useEffect(() => {
    if (decreaseSequence.isSuccess) {
      refetch();
    }
  }, [decreaseSequence.isSuccess]);


  console.log(decreaseSequence, "incDec", increaseSequence)
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      testCode,
      testCodeState,
      text,
      textState,
      objective,
      objectiveState,
      type: "details",
    },
  }));
  const sendState = () => {
    return {
      testCode,
      testCodeState,
      text,
      textState,
      objective,
      objectiveState,
      type: "details",
    };
  };
  // function that returns true if value is email, false otherwise
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const isValidated = () => {
    return true;
  };
  const removePool = (id, testId) => {
    console.log(id, 'id4Delete')
    setAlert(null)
    deletePool.mutate({ id, testId });
  }
  const createPool = async (model) => {
    if (model.masteryScore == '') {
      model.masteryScore = 0;
    }
    if (model.passingScore == '') {
      model.passingScore = 0;
    }
    if (model.pointsPerQuestion == '') {
      model.pointsPerQuestion = 0;
    }
    if (model?.negativePointsPerQuestion == '') {
      model.negativePointsPerQuestion = 0;
    }
    if (model.poolId) {
      try {
        await updatePool(model, testId);
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="success"
            alertMessage="Updated successfully"
          />
        )
        return;
      } catch (error) {
        console.log("error", error);
      }
    }

    try {
      let response = await axios.post(`${configUrl.AssessmentServer}/api/pools/${testId}`, model);

      console.log(response.data);


      let poolId = response.data.poolId;

      let resp = await axios.post(
        `${configUrl.AssessmentServer}/api/TestPoolMappings`,
        {
          testId: testId,
          poolId: poolId,
          sequenceId: 0,
          status: 0,
          isLocked: false,
          lockedBy: "",
          lockedDate: new Date(),
          duration: null,
          points: 0,
          isMandatory: 0,
          createdBy: user,
          createdDate: new Date(),
          updatedBy: user,
          updatedDate: new Date(),
        }
      );

      console.log(resp.data);
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="success"
          alertMessage="Saved successfully"
        />
      )
      hideImportQuestionModal();

      refetch();
    } catch (error) {
      console.log("error", error.response.data.message);
      if (error.response && error.response.status === 409) {
        // Code or name already exists
        console.log(error.response.data.message);
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="error"
            alertMessage={error.response.data.message}
          />
        )
      } else {
        console.error("Error creating pool:", error);
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="error"
            alertMessage="Error creating pool"
          />
        )
      }
    }

  };

  const updatePool = async (model, testId) => {
    let response = await axios.put(
      `${configUrl.AssessmentServer}/api/pools/${model.poolId}/${testId}`,
      model
    );

    console.log(response.data);

    hidePoolEditModal();

    refetch()

    //await getPools();
  };

  const createQuestionImportModal = () => {
    let title = "Create Section";
    let packageLimitExceeded = false;
    if (poolsRaw) {
      console.log("sectionData", poolsRaw?.length);
      console.log("packageDetails", licensePackageObject);
      (
        (poolsRaw?.length + 1) > licensePackageObject?.TestSections
          ?
          packageLimitExceeded = true &&
          setShowAlert(
            <CustomAlert
              open={true}
              openFlag={() => setFlag(flag ? false : true)}
              severity="warning"
              alertMessage="Please upgrade to higher package to create more sections."
            />
          )
          : ""
      )

    }
    if (packageLimitExceeded == false) {
      setQuestionImportModal(
        <CustomModal
          modal={true}
          setModal={() => hideImportQuestionModal()}
          content={<PoolForm createPool={createPool} testId={testId} />}
          cancelButtonMessage="cancel"
          okButtonMessage="Create"
          showOk={false}
          onCancelClick={() => hideImportQuestionModal()}
          title={title}
          maxWidth="md"
          fullWidth={false}
        />
      );
    }
  };

  const hideImportQuestionModal = () => {
    setQuestionImportModal(null);
  };

  const createPoolEditModal = (pool) => {
    let title = "Create Section";

    setPoolEditModal(
      <CustomModal
        modal={true}
        setModal={() => hidePoolEditModal()}
        content={<PoolForm createPool={createPool} state={pool} testId={testId} />}
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hidePoolEditModal()}
        title={title}
        maxWidth="md"
        fullWidth={false}
      />
    );
  };

  const hidePoolEditModal = () => {
    setPoolEditModal(null);
  };

  const getPools = async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/TestPoolMappings/${testId}`
    );

    let poolData = response.data["$values"];

    let poolDetails = [];

    for (let i = 0; i < poolData.length; i++) {
      let poolResponse = await axios.get(
        `${configUrl.AssessmentServer}/api/Pools/${poolData[i].poolId}`
      );

      let language = { ...poolResponse.data.poolLanguages["$values"][0] };
      let p = { ...poolResponse.data };

      p.poolLanguages = [language];
      p.showQuestions = false;
      poolDetails.push(p);
    }

    console.log("poolDetails", poolDetails);

    setPools([...poolDetails.sort((a, b) => a.sequenceId - b.sequenceId)]);
  };

  const createQuestionUpdateModal = (pool, props) => {
    console.log("pool", pool);
    console.log("props123", props);
    let title = `Add Questions/Topics to Section: ${pool.poolLanguages[0].text}`;
    setQuestionMapModal(
      <CustomModal

        modal={true}
        setModal={() => hideQuestionMapModal()}
        content={
          <PoolQuestionMappingGrid
            poolId={pool.poolId}
            testId={testId}
            objective={props.allStates.details ? props.allStates.details.objective : null}
            targetAudience={props.allStates.details ? props.allStates.details.targetAudience : null}
            poolName={pool.text}
            questionsCount={pool ? pool?.assignedQuestionCount : null}
            questionType={pool?.questionType}
            state={pool}
            hideHeader={true}
            onCancelClick={() => hideQuestionMapModal()}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="oook"
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideQuestionMapModal()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
        height={650}
      />
    );
    ref
  };

  const createQuestionMappedModal = (pool) => {
    let title = `Questions mapped to "${pool.text}"`;

    setQuestionMappedModal(
      <CustomModal
        modal={true}
        setModal={() => hideQuestionMappedModal()}
        content={
          <PoolQuestionMappedGrid
            poolId={pool.poolId}
            poolName={pool.text}
            hideHeader={true}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="oook"
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideQuestionMappedModal()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };

  const showQuestion = (pool) => {
    let tempPools = pools.map((x) => {
      return { ...x, showQuestions: false };
    });

    let p = tempPools.find((x) => x.poolId == pool.poolId);
    p.showQuestions = true;
    setPools([...tempPools]);
  };

  const hideQuestion = (pool) => {
    let p = pools.find((x) => x.poolId == pool.poolId);
    p.showQuestions = false;
    setPools([...pools]);
  };
  const hideQuestionMappedModal = () => {
    setQuestionMappedModal(null);
  };

  const hideQuestionMapModal = () => {
    setQuestionMapModal(null);
    refetch();
  };
  const handleQuestionsData = (data) => {
    //setQuestionsData((prevData) => ({ ...prevData, data }));
  };
  const increaseSeq = (testId, poolId) => {
    console.log('increase1')
    increaseSequence.mutate({
      testId: testId,
      poolId: poolId,
    });
  }
  const decreaseSeq = (testId, poolId) => {
    console.log('decrease1')
    decreaseSequence.mutate({
      testId: testId,
      poolId: poolId,
    });
  }
  return (
    <GridContainer justify="center">
      {alert}
      <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
        <Button disabled={pools.length >= 10} color="primary" onClick={createQuestionImportModal}>
          Create Section
        </Button> <br />
        {
          pools.length >= 10 ?
            <small style={{ color: 'red', textAlign: 'right' }}>You can create only 10 sections for a test</small>
            : null
        }
      </GridItem>
      <GridItem xs={12} sm={12}>
        {
          isLoading == true || increaseSequence.isLoading || decreaseSequence.isLoading ?
            <CircularProgress style={{ marginInline: '41%' }} /> : null
        }
        <Paper
          style={{
            maxHeight: 400,
            overflow: "auto",
            boxShadow:
              "0px 0px 0px 0px rgb(0 0 0), 0px 0px 0px 0px rgb(0 0 0), 0px 0px 0px 0px rgb(0 0 0)",
          }}
        >
          {isLoading == false &&
            pools &&
            pools.map((x, i) => (
              console.log(pools, 'pools5'),
              <div key={i} style={{ margin: 5 }}>
                {console.log(x, 'poolDetails')}
                <Card style={{ marginTop: 5, marginBottom: 5 }}>
                  <CardBody
                    style={{
                      backgroundColor: x.isLocked ? "grey" : "black",
                      borderRadius: 10,
                      color: "white",
                      zIndex: 5,
                      paddingRight: 20,
                      paddingLeft: 20,
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  >
                    <GridContainer alignItems="center">
                      <GridItem xs={12} sm={12} md={2}>
                        <h6>{x.code}</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <h6>{x.poolLanguages[0].text}</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <small><b>Allow Questions</b>  - {x.questionCount} </small>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <small><b> Points</b> - {x.pointsPerQuestion}</small>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <small><b>Topics</b>  - {x.topics != null ? x?.topics.split(',').length : 'none'}</small>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} style={{ textAlign: "right" }}>
                        <span>
                          <IconButton
                            aria-label="up"
                            size="small"
                            style={{ color: "#de2668" }}
                            disabled={pools.length == 1 || deletePool.isLoading}
                            onClick={() => {
                              decreaseSeq(testId, x.poolId);
                              // decreaseSequence.mutate({
                              //   testId: testId,
                              //   poolId: x.poolId,
                              // });
                            }}
                          >
                            <ExpandLessIcon />
                          </IconButton>
                          <small >{x.sequenceId?.toString()?.padStart(2, '0')}</small>
                          <IconButton
                            aria-label="up"
                            size="small"
                            style={{ color: "#de2668" }}
                            disabled={pools.length == 1 || deletePool.isLoading}
                            onClick={() => {
                              increaseSeq(testId, x.poolId);
                              // increaseSequence.mutate({
                              //   testId: testId,
                              //   poolId: x.poolId,
                              // });

                            }}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </span>
                        <Button
                          size="sm"
                          justIcon
                          round
                          color="behance"
                          onClick={(e) => {
                            e.preventDefault();
                            createQuestionUpdateModal(x, props);

                          }}
                        >
                          <Publish size="1em" />
                        </Button>
                        <Button
                          size="sm"
                          justIcon
                          round
                          color="reddit"
                          onClick={(e) => {
                            e.preventDefault();
                            //props.addCategory("", nodes.id);
                            createPoolEditModal(x);
                          }}
                        >
                          <EditIcon />
                        </Button>
                        {/* {x.isLocked == false ? (
                          <Button
                            size="sm"
                            justIcon
                            round
                            color="dodgerblue"
                            onClick={(e) => {
                              e.preventDefault();
                              lockUnlock.mutate({ id: x.poolId, lock: true });
                            }}
                          >
                            <Lock />
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            justIcon
                            round
                            color="dodgerblue"
                            onClick={(e) => {
                              e.preventDefault();
                              lockUnlock.mutate({ id: x.poolId, lock: false });
                            }}
                          >
                            <LockOpen />
                          </Button>
                        )} */}
                        {x.showQuestions == false ? (
                          <Button
                            size="sm"
                            justIcon
                            round
                            color="google"
                            onClick={(e) => {
                              e.preventDefault();
                              //props.addCategory("", nodes.id);
                              showQuestion(x);
                            }}
                          >
                            <ExpandMoreIcon />
                          </Button>
                        ) : null}
                        {x.showQuestions == true ? (
                          <Button
                            size="sm"
                            justIcon
                            round
                            color="google"
                            onClick={(e) => {
                              e.preventDefault();
                              //props.addCategory("", nodes.id);
                              hideQuestion(x);
                            }}
                          >
                            <ExpandLessIcon />
                          </Button>
                        ) : null}
                        <Button
                          size="sm"
                          justIcon
                          round
                          color="reddit"
                          disabled={pools.length == 1 || deletePool.isLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            // to do
                            setAlert(
                              <SweetAlert
                                style={{ display: "block", marginTop: "-100px" }}
                                title={' '}
                                onConfirm={() => removePool(x.poolId, testId)}
                                showCancel={true}
                                cancelBtnText={'CANCEL'}
                                confirmBtnText={'OK'}
                                onCancel={() => setAlert(null)}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                                cancelBtnCssClass={classes.button + " " + classes.warning}
                              >
                                <div style={{ display: 'inline-flex' }}>
                                  <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                                  <h4> Are you sure you want to delete the section ?</h4>
                                </div>
                              </SweetAlert>
                            );
                          }}
                        >
                          < DeleteForever />
                        </Button>
                        {/* <span>
                          <IconButton
                            aria-label="up"
                            size="small"
                            style={{ color: "#de2668", position: 'absolute', marginBottom: '10px' }}
                            disabled={pools.length == 1 || deletePool.isLoading}
                            onClick={() => {
                              decreaseSeq(testId, x.poolId);
                              // decreaseSequence.mutate({
                              //   testId: testId,
                              //   poolId: x.poolId,
                              // });
                            }}
                          >
                            <ExpandLessIcon />
                          </IconButton>
                          <small style={{ position: 'absolute', margin: '17px 0px 0px 8px' }}>{x.sequenceId?.toString()?.padStart(2, '0')}</small>
                          <IconButton
                            aria-label="up"
                            size="small"
                            style={{ color: "#de2668", marginTop: '25px' }}
                            disabled={pools.length == 1 || deletePool.isLoading}
                            onClick={() => {
                              increaseSeq(testId, x.poolId);
                              // increaseSequence.mutate({
                              //   testId: testId,
                              //   poolId: x.poolId,
                              // });

                            }}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </span> */}
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
                {showAlert}
                {x.showQuestions == true ? (
                  <Slide
                    direction="down"
                    in={true}
                    mountOnEnter
                    unmountOnExit
                    timeout={400}
                  >
                    <div style={{ marginTop: -5 }}>
                      <PoolQuestionMappedGrid

                        poolId={x.poolId}
                        poolName={x.text}
                        hideHeader={true}
                        questionsFetch={refetch}
                      />
                    </div>
                  </Slide>
                ) : null}
              </div>
            ))}
        </Paper>
      </GridItem>
      {questionMapModal}
      {questionImportModal}
      {questionMappedModal}
      {poolEditModal}
    </GridContainer>
  );
});

TestPoolMapping.displayName = "Step3";

export default TestPoolMapping;
