import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";

export default function useGetRoles() {

    const { config, providerId, providerType } = useContext(LoginContext);

    return useQuery(
        "getRole",
        async () => {
            let response = await axios.get(`${config.api}/api/RoleManagement/getRole/${providerType}`);
            console.log(response)
            return response.data['$values'];
        },
        {
            staleTime: 1000 * 60 * 5,
        }
    );
}
