
import axios from "axios";
import { configUrl } from "config.js";

export default function AddDummyStudentsService(providerId) {
    return axios.post(`${configUrl.AssessmentServer}/api/User/AddDummyStudents/${providerId}`)
        .then((response) => {
            return {
                messege: 'Students added successfully',
                status: response.status,
            }
        })
        .catch((error) => {
            console.log(error.response.data, "prevError");
        });

};
