import { InputLabel, Snackbar, TextField } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import React, { useContext, useState } from "react";
import generateTestService from "services/TestService/generateTestService";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TestScheduleComponent(props) {
  const { state, afterFinish, scheduleDates, reloadGridFunction } = props;
  const { testPageState } = useContext(LoginContext);

  const [assignDateValue, setAssignDateValue] = useState(new Date());
  const [scheduleStartDateValue, setScheduleDateValue] = useState(null);
  const [scheduleEndDateValue, setScheduleEndDateValue] = useState(null);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");
  console.log(state, 'state data');
  console.log(scheduleStartDateValue, 'scheduleStartDateValue');
  console.log(new Date(), 'new Date');
  console.log(scheduleDates, 'scheduleDates');
  const convertToLocalTime = (utcDateTime) => {
    const date = new Date(utcDateTime);
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  const convertToLocalDate = (utcDateTime) => {
    const date = new Date(utcDateTime);
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  React.useEffect(() => {
    if (scheduleDates && scheduleDates.scheduleEndDateTime !== null) {
      const newendDateTime = convertToLocalTime(scheduleDates.scheduleEndDateTime);
      setScheduleEndDateValue(newendDateTime);
    }
    if (scheduleDates && scheduleDates.scheduleStartDateTime !== null) {
      const newstartDateTime = convertToLocalTime(scheduleDates.scheduleStartDateTime);
      setScheduleDateValue(newstartDateTime);
    }
    if (scheduleDates && scheduleDates.assignDateTime !== null) {
      const newassignedDateTime = convertToLocalDate(scheduleDates.assignDateTime);
      setAssignDateValue(newassignedDateTime);
    }
    if (scheduleDates.assignDateTime != null && scheduleDates.scheduleEndDateTime == null && scheduleDates.scheduleStartDateTime == null) {
      setValue("1");
    } else if (scheduleDates.assignDateTime != null && scheduleDates.scheduleEndDateTime != null && scheduleDates.scheduleStartDateTime == null) {
      setValue("1")
    } else if (scheduleDates.assignDateTime != null && scheduleDates.scheduleEndDateTime != null && scheduleDates.scheduleStartDateTime != null) {
      setValue("2");
    }
  }, [])

  const handleChange = (e) => {
    if (e.target.name == "assignDateValue") {
      const assignDate = e.target.value;
      setAssignDateValue(assignDate);
      const assignTime = new Date(assignDate);
      //assignTime.setHours(0, 0, 0, 0);
      const startTime = new Date(assignTime.getTime() + 72 * 60 * 60 * 1000);
      //startTime.setHours(23, 59, 0, 0);
      console.log("statesdata", e.target.value, assignTime, "barrir", startTime);
      setScheduleDateValue(startTime.toISOString().slice(0, 16));
    }
  };

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setScheduleDateValue(newStartDate);
    // setValidationError('');

    // Automatically set the assigned date to be 48 hours before the start date
    const startDateTime = new Date(newStartDate);

    const assignedDateTime = new Date(startDateTime.getTime() - 48 * 60 * 60 * 1000);
    console.log(assignedDateTime, 'assignedDateTimeset', newStartDate, 'startDateTime')
    setAssignDateValue(assignedDateTime.toISOString().slice(0, 10)); // Format as "YYYY-MM-DDTHH:mm"
  };

  const handleEndDateChange = (event) => {
    setScheduleEndDateValue(event.target.value);
    // setValidationError('');
  };



  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };
  const updateSchedule = async () => {
    let response;
    const startDateTime = new Date(scheduleStartDateValue);
    const endDateTime = new Date(scheduleEndDateValue);
    const assignedDateTime = new Date(assignDateValue);
    const currentDateTime = new Date();
    //BSS121 will work for past date selection 
    // if (startDateTime < currentDateTime || assignedDateTime < currentDateTime) {
    //   showSnackBar('Start date cannot be in the past.');
    //   return;
    // }
    if (assignedDateTime.setHours(0, 0, 0, 0) < currentDateTime.setHours(0, 0, 0, 0)) {
      showSnackBar('Choose a valid assigned date from today onwards.', "error");
      return;
    }
    if (startDateTime - assignedDateTime < 48 * 60 * 60 * 1000) {
      showSnackBar('Assigned date must be 48 hours before the start date.', "error");
      return;
    }
    if (startDateTime - currentDateTime < 48 * 60 * 60 * 1000) {
      showSnackBar('Start date must be at least 48 hours in the future.', "error");
      return;
    }
    if (endDateTime - startDateTime < 15 * 60 * 1000) {
      showSnackBar("End date must be at least 15 minutes after the start date.", "error")
      return;
    } else {
      setIsScheduling(true);
      const formatDateTime = (date) => {
        return date.toISOString().slice(0, 16);
      }

      const newstartDateTime = formatDateTime(startDateTime);
      const newendDateTime = formatDateTime(endDateTime);
      const newassignedDateTime = formatDateTime(assignedDateTime);

      response = await axios.put(`${configUrl.AssessmentServer}/api/Tests/updateSchedule/${state.testId}`, {
        scheduleStartDateTime: newstartDateTime,
        scheduleEndDateTime: newendDateTime,
        assignDateTime: newassignedDateTime
      })
      setIsScheduling(false);
    }
    if (response.status == 200 || response.status == 204) {
      showSnackBar("Test scheduled successfully")
    }
    setTimeout(() => {
      afterFinish()
      reloadGridFunction(testPageState.page, testPageState.pageSize);
    }, 2000);

  }
  const assignTest = async () => {
    setIsAssigning(true);
    const startDateTime = null //new Date(scheduleStartDateValue);
    const endDateTime = scheduleEndDateValue == null ? null : new Date(scheduleEndDateValue); //null
    const assignedDateTime = new Date();
    console.log(assignedDateTime, 'assignedTime', new Date())
    console.log(endDateTime, 'endDateTime', scheduleEndDateValue)


    let response = await generateTestService.generateTests(state?.testId, startDateTime, endDateTime, assignedDateTime);
    // let response = await axios.put(`${configUrl.AssessmentServer}/api/Tests/generateTests/${state.testId}`)
    setIsAssigning(false);
    if (response?.status == 200 || response?.status == 204) {
      showSnackBar("Test assigned successfully")
      setTimeout(() => {
        afterFinish()
        reloadGridFunction(testPageState.page, testPageState.pageSize);
      }, 2000);
    } else if (response?.status == 400 || response?.status == 404) {
      showSnackBar("Error occured", 'error');
    }
  }
  const [radioValue, setValue] = React.useState("2");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <GridContainer alignItems="flex-end">
        <GridItem sm={12} md={12} lg={12}>
          <fieldset style={{ border: 'none', boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', }}>
            <legend style={{ marginBottom: '0px !important' }}>
              <InputLabel shrink><h4><b>Schedule</b></h4></InputLabel>
            </legend>
            <GridContainer>
              <GridItem sm={12} md={6} lg={4}>
                <FormControl>
                  <RadioGroup aria-label="schedule" name="schedule1" value={radioValue} onChange={handleRadioChange}>
                    <FormControlLabel value="1" control={<Radio />} label="Now" />
                    <FormControlLabel value="2" control={<Radio />} label="Later" />
                  </RadioGroup>
                </FormControl>
              </GridItem>
              <GridItem sm={12} md={6} lg={8}>
                {
                  radioValue == '1' ? null :
                    <TextField
                      type="date"
                      name="assignDateValue"
                      value={assignDateValue}
                      onChange={handleChange}
                      label="Assign Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ borderColor: "primary", marginTop: 28, marginBottom: 11, marginLeft: 15 }}
                      disabled={radioValue == '1' ? true : false}
                    />
                }

              </GridItem>
            </GridContainer>

          </fieldset>
        </GridItem>
      </GridContainer>
      <GridContainer alignItems="flex-end">
        <GridItem sm={12} md={12} lg={12}>
          <fieldset style={{ border: 'none', boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', }}>
            <legend>
              <InputLabel shrink><h4><b>Test Window</b></h4></InputLabel>
            </legend>
            <GridContainer>
              <GridItem sm={12} md={6} lg={6}>
                {radioValue == '1' ? null :
                  <TextField
                    type="datetime-local"
                    name="scheduleStartDateValue"
                    value={scheduleStartDateValue}
                    onChange={handleStartDateChange}
                    label="Start DateTime"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ borderColor: "primary", marginBottom: 11 }}
                  // disabled={radioValue == '1' ? true : false}
                  />}
              </GridItem>
              <GridItem sm={12} md={6} lg={6}>
                <TextField
                  type="datetime-local"
                  name="scheduleEndDateValue"
                  value={scheduleEndDateValue}
                  onChange={handleEndDateChange}
                  label="End DateTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ borderColor: "primary", marginBottom: 11, float: 'inline-end' }}
                // disabled={radioValue == '1' ? true : false}

                />
              </GridItem>
            </GridContainer>

          </fieldset>
        </GridItem>

        <GridItem sm={12} md={12} lg={12}>
          {/* <Button color="primary" onClick={updateSchedule}>
            {isScheduling == true ? 'Scheduling Test...' : 'Schedule Test'}
          </Button> */}
          <small style={radioValue == '1' ? { float: 'left', color: 'white' } : { float: 'left', color: 'red' }}>Note :Start time will be 48 hours after assign date. </small>
          <Button color="primary" disabled={isAssigning == true || isScheduling == true || state.scheduleStatus == 5} style={{ marginTop: '15px', float: 'right' }} onClick={radioValue == '1' ? assignTest : updateSchedule}>
            {isAssigning == true || isScheduling == true ? 'Saving...' : 'Save'}
          </Button>
        </GridItem>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </GridContainer>
    </>
  );
}

export default TestScheduleComponent;
