import axios from "axios";
import { configUrl } from "config";

const providerResourceMapping = async (payload) => {
  return axios
    .post(`${configUrl.AssessmentServer}/api/ProviderResourceMapping`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error?.response?.data, "prevError");
      return {
        messege: error?.response?.data,
        status: error?.response?.status,
      };
    });
};
export default {
  providerResourceMapping: providerResourceMapping,
};
