
import axios from "axios";
import { configUrl } from "config.js";

export default function usernameExists(userName, providerId, userId) {
    let tempUserId = userId ?? 0;
    return axios.get(`${configUrl.AssessmentServer}/api/User/userNameExists/${userName}/${providerId}/${tempUserId}`)
        .then((response) => {
            return {
                message: response.data,
                status: response.status,
            };
        })
        .catch((error) => {
            console.log(error.response.data, "username error");
        });
};

