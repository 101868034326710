import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import React from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";

const TreeDropDown = React.memo(
  ({ data, onChange, className }) => {
    return (
      <>
        <DropdownTreeSelect
          data={data}
          onChange={onChange}
          className={className}
          // texts={{placeholder:"Select Category/Topics"}}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);

function DropDownSelect(props) {
  const { data: categories } = useGetCategories(
    props.isContentProvider == undefined ? false : props.isContentProvider
  );
  console.log(categories, "categories1234");
  const [selectedCategories, setSelectedCategories] = React.useState(null);

  const [categoryTree, setCategoryTree] = React.useState(null);

  const getTopicIds = (node, topics) => {
    /*  if(node.isCategory == false){
        topics.push(node.topicId)
        return
      } */

    const children = categories.filter((x) => x.parent == node.id);

    if (!children || children.length == 0) {
      if (node.isCategory == false) topics.push(node.topicId);
    }

    if (children && children.length > 0) {
      children.forEach((y) => {
        getTopicIds(y, topics);
      });
    }
  };

  const createHierarchy = (root) => {
    if (!root) return;

    root.label = root.name;
    root.value = root.id;
    root.checked = false;
    console.log(root, "this is Root");
    let children = [...categories].filter((x) => x.parent == root.id);
    console.log(children, "same as 1");
    if (props.showCategoriesOnly == true) {
      children = children.filter((x) => x.isCategory == true);
    }

    if (children && children.length > 0) {
      if (props.disableParentSelection == true) {
        root.disabled = true; // for disable parent selection nodes.
      }
      root.children = [];

      children.forEach((x) => {
        console.log(x, "child1");
        x.value = x.id;
        x.label = x.name;
        root.children.push(x);
        createHierarchy(x);
      });
    } else {
      if (props.disableParentSelection == true) {
        root.disabled = false; // for disable parent selection nodes.
      }
    }

    return;
  };

  const onChange = (currentNode, selectedNodes) => {
    console.log("selectedNodes", selectedNodes);

    let topicIds = [];

    selectedNodes.forEach((x) => {
      getTopicIds(x, topicIds);
    });

    if (props.showCategoriesOnly == true) {
      props.setTopics(selectedNodes.map((x) => x.id));
    } else {
      props.setTopics(topicIds);
    }

    setSelectedCategories(selectedNodes.map((x) => x.id).join());
  };

  React.useEffect(() => {
    if (categories) {
      console.log("Dropcategories", categories);
      let root = [...categories].filter(
        (x) => x.parent == null && x.isCategory == true
      );
      console.log("Droproot", root);
      createHierarchy(root);

      console.log("root", root);
      setCategoryTree(root);
    }
  }, [categories]);

  return (
    <div style={{ width: "100%" }}>
      <h6 style={{ textTransform: "none" }}>Select Category</h6>
      {categoryTree && (
        <TreeDropDown
          data={categoryTree}
          onChange={onChange}
          className="mdl-demo"
        />
      )}
    </div>
  );
}

export default DropDownSelect;
