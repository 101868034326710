import React, { useRef } from "react";

import Switch from "@material-ui/core/Switch";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import useCreateInterest from "hooks/interestsHooks/useCreateInterest";
import useDeleteInterest from "hooks/interestsHooks/useDeleteInterest";
import useGetAllInterests from "hooks/interestsHooks/useGetAllInterests";
import useIsInterestExists from "hooks/interestsHooks/useIsInterestExists";
import useUpdateInterest from "hooks/interestsHooks/useUpdateInterest";

import TextField from "@material-ui/core/TextField";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CustomModal from "components/CustomModal/CustomModal";
import Interests from "views/Forms/Interests";
// import { render } from 'react-dom';
// import { WithContext as ReactTags } from 'react-tag-input';
// import Alert from '@material-ui/lab/Alert';
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { LoginContext } from "contexts/LoginContext";
import "react-tagsinput/react-tagsinput.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

export default function InterestsListing(props) {
  const {
    data: interests,
    error: interestsFetchError,
    isLoading: interestsFetchLoading,
    refetch: refetchConfigurations,
  } = useGetAllInterests();
  const createInterst = useCreateInterest();
  const updateInterest = useUpdateInterest();
  const deleteInterest = useDeleteInterest();
  const isinterexist = useIsInterestExists();

  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [interestsEditForm, setInterestsEditForm] = React.useState(null);

  const [deleteModal, setDeleteModal] = React.useState(null);
  const [interest, setInterest] = React.useState({
    interestId: 0,
    providerId: 0,
    interests: "",
    description: "",
    parentInterestId: 0,
    createdBy: "",
    createdDate: new Date(),
    updatedBy: "",
    updatedDate: new Date(),
  });
  const [error1, setError1] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError1(false);
  };
  const showErrorSnackBar = () => {
    setError1(true);
  };
  const { licensePackageObject, userPrivileges } = React.useContext(
    LoginContext
  );
  console.log(interest);

  // const [tags, setTags] = React.useState([]);

  // const handleChange = value => {
  //   setTags(value);
  // }
  const ref = useRef(null);

  const classes = useStyles();

  const handleChange = (event) => {
    console.log(event.target.value.length);
    //  console.log(.length)
    //  let a=event.target.value.trim().length
    //  console.log(a.length)
    setInterest({ ...interest, [event.target.name]: event.target.value });
    console.log({ ...interest, [event.target.name]: event.target.value });

    console.log(interest);
    // console.log(interest.length)
  };
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.SystemConfiguration.indexOf(x) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  const OnSubmit = async () => {
    console.log(interest);
    console.log(
      "interestData",
      interests,
      interests?.length,
      licensePackageObject?.SystemConfiguration
    );
    if (interests) {
      if (interests?.length + 1 > licensePackageObject?.SystemConfiguration) {
        setErrorMessage(
          `Please upgrade to higher package for adding more than ${licensePackageObject?.SystemConfiguration} configurations.`
        );
        showErrorSnackBar();
        return;
      }
    }
    const found = rows.find(
      (element) =>
        element.interests.toLowerCase().trim() ===
        interest.interests.toLowerCase().trim()
    );
    console.log("found..", found);
    let errorText = "";
    if (interest.interests === "") {
      errorText = errorText + "Please enter interest";
    }
    if (found) {
      errorText =
        errorText + '"' + interest.interests + '"' + " interst already exist";
    }
    if (errorText != "") {
      // alert(errorText);
      setErrorMessage(errorText);
      showErrorSnackBar();
      return;
    } else {
      createInterst.mutate({ ...interest, description: interest.interests });
      setInterest({ ...interest, interests: "" });
    }
  };
  const showError = () => {
    setError(true);
  };
  const showDeleteConfirmation = (id) => {
    setDeleteModal(
      <CustomModal
        modal={true}
        setModal={() => hideDeleteCustomModal()}
        content={<h3>Are you sure you want to delete the interests?</h3>}
        cancelButtonMessage="Cancel"
        okButtonMessage={deleteInterest.isLoading ? "Deleting" : "Yes"}
        showOk={true}
        onCancelClick={() => hideDeleteCustomModal()}
        title="Delete Interest"
        maxWidth="sm"
        onOkClick={async () => {
          await deleteInterest.mutateAsync(id);
          hideDeleteCustomModal();
        }}
        showCancel={true}
        padding1={true}
      />
    );
  };

  const hideDeleteCustomModal = () => {
    setDeleteModal(null);
  };

  const onDelete = async (e, id) => {
    showDeleteConfirmation(id);
  };

  const onUpdate = (values) => {
    updateInterest.mutate(values);
  };

  const onInfo = (e, id) => {
    alert("info");
  };

  const handleRowSelection = (e) => {
    setSelection(e);
  };

  const onCellEditCommit = (p) => {};

  const onCellBlur = (e, p) => {};

  const handleEdit = (p, e) => {};

  React.useEffect(() => {
    let cols = [
      {
        field: "interests",
        headerName: "Interests",
        editable: false,
        width: 150,
      },
      {
        field: "actions",
        headerName: "Action",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            // fillButtons

            <>
              {isPrivilege("Active/InactiveSystemConfiguration") == true ? (
                <Switch
                  value={cellValues.row.isActive}
                  onChange={(e) => {
                    let val = {
                      target: {
                        name: "isActive",
                        value: !cellValues.row.isActive,
                      },
                    };
                    //handleChange(val);
                    cellValues.row.isActive = !cellValues.row.isActive;
                    onUpdate(cellValues);
                  }}
                  // console.log(cellValues.row.isActive)
                  checked={cellValues.row.isActive}
                />
              ) : (
                ""
              )}
            </>
          );
        },
      },
    ];

    setColumns(cols);
  }, []);

  React.useEffect(() => {
    if (createInterst.isSuccess == true) {
      refetchConfigurations();
    }
  }, [createInterst.isSuccess, refetchConfigurations]);

  React.useEffect(() => {
    if (interests) {
      setRows(
        interests["$values"].map((x, i) => {
          return { ...x, id: i };
        })
      );
    }
  }, [interests]);

  React.useEffect(() => {
    if (updateInterest.isError)
      if (interests) {
        setRows(
          interests["$values"].map((x, i) => {
            return { ...x, id: i };
          })
        );
      }
  }, [interests, updateInterest.isError]);
  const createInterestsEditForm = (row, title) => {
    setInterestsEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideInterestsEditForm()}
        content={
          <Interests
            icon={PersonAddIcon}
            user={row}
            afterFinish={() => hideInterestsEditForm()}
            userType={2}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideInterestsEditForm()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };

  const hideInterestsEditForm = () => {
    setInterestsEditForm(null);
  };

  const getRowClassName = (row) => {
    return null;
  };

  return (
    <>
      <Card>
        <CardBody style={{ marginTop: 20 }}>
          {/* <GridContainer spacing={2} > */}
          <Grid container spacing={2}>
            <GridItem>
              <TextField
                // {...params}
                style={{ marginTop: 5, width: 300 }}
                variant="outlined"
                // label="filterSelectedOptions"
                // placeholder="Favorit
                size="small"
                type="text"
                name="interests"
                value={interest.interests}
                onChange={handleChange}
              />
              {/* <span> </span> */}
            </GridItem>
            <GridItem>
              {isPrivilege("AddSystemConfiguration") == true ? (
                <Button
                  // style={{ height: 54 }}
                  color="rose"
                  onClick={OnSubmit}
                  style={{ textTransform: "none" }}
                  disabled={createInterst.isLoading == true}
                >
                  {createInterst.isLoading == true ? "Adding..." : "+ Add"}
                </Button>
              ) : (
                ""
              )}
            </GridItem>
            <GridItem></GridItem>
          </Grid>
          {/* </GridContainer> */}
          <br />
          <br />
          <GridContainer justifyContain="center">
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ height: 500, width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  {rows ? (
                    <div ref={ref} style={{ flexGrow: 1 }}>
                      <CustomDataGrid
                        columns={columns}
                        rows={rows}
                        autoPageSize={true}
                        onEditCellPropsChange={handleEdit}
                        onCellEditCommit={onCellEditCommit}
                        onSelectionModelChange={handleRowSelection}
                        getRowClassName={getRowClassName}
                        onCellBlur={onCellBlur}
                      />
                    </div>
                  ) : (
                    <div style={{ flexGrow: 1 }}>
                      <CircularProgress /> Loading interest...
                    </div>
                  )}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {updateInterest.isError == true && (
        <CustomAlert
          open={true}
          severity="error"
          alertMessage="This Interest is in use."
        />
      )}

      {createInterst.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="Interest Added successfully"
        />
      )}
      {updateInterest.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="Configuration Updated successfully"
        />
      )}
      {deleteInterest.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="Interest Deleted successfully"
        />
      )}
      <Snackbar
        open={error1}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      {interestsEditForm}
      {deleteModal}
    </>
  );
}
