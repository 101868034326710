import axios from "axios";
import { configUrl } from "config.js";
import { useMutation, useQueryClient } from "react-query";

export default function useDeletePool() {
  let queryClient = useQueryClient();
  return useMutation(
    async ({ id, testId }) => {
      await axios.delete(`${configUrl.AssessmentServer}/api/Pools/${id}/${testId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("pools");
      },
    }
  );
}
