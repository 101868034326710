import React from "react";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AssessmentPage from "views/Pages/AssessmentPage";
import CounselingPage from "views/Pages/CounselingPage";
import LearningFitbitHomePage from "views/Pages/LearningFitbitHomePage";

import { LoginContext } from "contexts/LoginContext";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import { configUrl } from "config.js";
import { QueryClient, QueryClientProvider } from "react-query";
import ForgotPassword from "views/Components/ForgotPassword";

export default function App() {
  const [user, setUser] = React.useState("");
  const [userId, setUserId] = React.useState(0);
  const [providerId, setProviderId] = React.useState(0);
  const [providerName, setProviderName] = React.useState('');
  const [providerType, setProviderType] = React.useState(0);
  const [userPrivileges, setUserPrivileges] = React.useState()
  const [contentProviderIds, setContentProviderIds] = React.useState(0);
  const [userType, setUserType] = React.useState(1);
  const [licensePackageObject, setLicensePackageObject] = React.useState();
  const [providerCreatedDate, setProviderCreatedDate] = React.useState('')
  const [testPageState, setTestPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  const [questionPageState, setQuestionPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  const [coursePageState, setCoursePageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  // const [answerModalTitle, setAnswerModalTitle] = React.useState("");
  const client = new QueryClient();
  let config = JSON.parse(`{"api":"${configUrl.AssessmentServer}"}`)
  console.log('local config', config)
  return (
    <BrowserRouter>
      <QueryClientProvider client={client}>
        <LoginContext.Provider
          value={{
            user,
            setUser,
            userId,
            setUserId,
            providerId,
            setProviderId,
            providerName,
            setProviderName,
            providerType,
            setProviderType,
            contentProviderIds,
            setContentProviderIds,
            config,
            userType,
            setUserType,
            // answerModalTitle, setAnswerModalTitle,
            userPrivileges, setUserPrivileges,
            licensePackageObject, setLicensePackageObject,
            providerCreatedDate, setProviderCreatedDate,
            testPageState, setTestPageState, questionPageState, setQuestionPageState,
            coursePageState, setCoursePageState
          }}
        >
          <Switch>
            <Route path="/rtl" component={RtlLayout} />
            <Route path="/auth" component={AuthLayout} />
            <Route path="/forgotPass/:userId/*" component={ForgotPassword} />
            <Route path="/admin" component={AdminLayout} />
            <Route path="/letsStart" component={LearningFitbitHomePage} />
            <Route path="/counseling" component={CounselingPage} />
            <Route path="/assessment" component={AssessmentPage} />
            <Redirect from="/" to="/letsStart" />
          </Switch>
        </LoginContext.Provider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
