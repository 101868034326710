import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import React, { useContext } from "react";

import CustomSelect from "components/CustomSelect/CustomSelect";

import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";

import Snackbar from "@material-ui/core/Snackbar";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PublicIcon from "@material-ui/icons/Public";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PreviewComponent from "components/PreviewComponent/PreviewComponent";

// import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ClearOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import Help from "@material-ui/icons/Help";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import CustomMultipleSelect from "components/CustomSelect/CustomMultipleSelect";
import { LoginContext } from "contexts/LoginContext";
import useGetCategoryService from "hooks/categoryHooks/useGetCategoryService.js";
import useGetAllObjective from "hooks/configurationsHooks/useGetAllObjective";
import useGetAllConfigurations from "hooks/configurationsHooks/useGetAllTargetAudience";
import usePublishQuestion from "hooks/questionHooks/usePublishQuestion.js";
import useBulkUpdateTopiQuestionMapping from "hooks/topicHooks/useBulkUpdateTopiQuestionMapping";
import useDeleteQuestions from "hooks/useDeleteQuestions";
import useUpdateQuestions from "hooks/useUpdateQuestions";
import "katex/dist/katex.min.css";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactHtmlParser from "react-html-parser";
import Latex from "react-latex-next";
import getAllQuestions from "services/QuestionService/getAllQuestions.js";
import QuestionForm from "views/Forms/QuestionForm/QuestionForm";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  ...styles,
  tooltip: {
    fontSize: "1em",
    backgroundColor: "white",
  },
}));

export default function QuestionBulkUpdate(props) {
  console.log(props, "props321");
  const classes = useStyles();
  const {
    questionPageState,
    setQuestionPageState,
    setTestPageState,
    setCoursePageState,
  } = useContext(LoginContext);
  React.useEffect(() => {
    setTestPageState({ pageSize: 10, page: 1 });
    setCoursePageState({ pageSize: 10, page: 1 });
  }, []);
  // const [pageState, setPageState] = React.useState({
  //   isLoading: false,
  //   data: [],
  //   total: 0,
  //   page: 1,
  //   pageSize: 10
  // })
  let difficultyLevel;
  let Status;
  let objective;
  let targetAudience;
  let code = null;
  let contentType = 0;
  let questionType = 0;

  let isActive = true;
  let configurationType = "Objective";
  let configurationTypeAudience = "TargetAudience";

  const publishQuestionCall = usePublishQuestion();
  const { data: objectiveResponse } = useGetAllObjective(
    configurationType,
    isActive,
    false
  );
  const { data: targetAudienceResponse } = useGetAllConfigurations(
    configurationTypeAudience,
    isActive,
    false
  );

  const {
    mutate: bulkUpdateTopicQuestionMapping,
    isSuccess: IsTopicUpdateSuccess,
    isLoading: bulkUpdateLoading,
  } = useBulkUpdateTopiQuestionMapping();

  const update = useUpdateQuestions();
  const deleteQuestion = useDeleteQuestions();
  const { user, userPrivileges } = useContext(LoginContext);
  const [columns, setColumns] = React.useState([]);
  const [select, setSelection] = React.useState([]);
  const [bulkOption, setBulkOption] = React.useState({});
  const [bulkOptions, setBulkOptions] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [allQuestionsData, setAllQuestionsData] = React.useState([]);
  const [reloadGrid, setReloadGrid] = React.useState(false);

  const [topicOptions, setTopicOptions] = React.useState([""]);
  const [objectiveOptions, setObjectiveOptions] = React.useState([""]);
  const [targetAudienceOptions, setTargetAudienceOptions] = React.useState([
    "",
  ]);
  const [bulkValue, setBulkValue] = React.useState("");

  const [open, setOpen] = React.useState(false);

  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");

  const [questionEditForm, setQuestionEditForm] = React.useState(null);
  const [contentFilter, setContentFilter] = React.useState("0");
  const [menu, setMenu] = React.useState(null);
  const { providerId } = React.useContext(LoginContext);
  console.log("LoggedInProviderId", providerId);
  // const [enableSearch, setEnableSearch] = React.useState(false);
  var enableSearch = false;
  const getMappedQuestions = (questionData) => {
    let questions = questionData?.reduce((acc, curr) => {
      let question = {};
      question["id"] = curr.questionId;
      question["code"] = curr.code;

      question["text"] =
        curr.text != null && curr?.text?.length !== 0 ? (
          curr.contentType === 1 && curr.contentType === 2 ? (
            curr.text?.trimStart()
          ) : curr.contentType === 3 && curr.text != null ? (
            <Latex>{curr.text?.trimStart()}</Latex>
          ) : curr.contentType === 4 && curr.text != null ? (
            ReactHtmlParser(curr.text?.trimStart())
          ) : (
            curr.text?.trimStart()
          )
        ) : null;
      question["image"] = curr.image;
      question["questionType"] = curr.questionType;
      question["providerId"] = curr.providerId;
      question["providerName"] = curr.providerName;
      question["status"] = curr.status;
      question["isLocked"] = curr.isLocked;
      question["lockedBy"] = curr.lockedBy;
      question["isActive"] = curr.isActive;
      question["difficultyLevel"] = curr.difficultyLevel;
      question["expiryDate"] = curr.expiryDate;
      question["totalCount"] = curr.totalCount;
      question["createdBy"] = curr.createdBy;
      question["createdDate"] = curr.createdDate;
      question["updatedBy"] = curr.updatedBy;
      question["updatedDate"] = curr.updatedDate;

      acc.push(question);

      return acc;
    }, []);

    return questions;
  };

  const fetchData = async (
    pageNumber,
    pageSize,
    difficultyLevel,
    Status,
    objective,
    targetAudience,
    code,
    contentType,
    questionType,
    contentFilter
  ) => {
    setQuestionPageState((old) => ({ ...old, isLoading: true }));
    let getQuestions = await getAllQuestions.getAllQuestions(
      pageNumber,
      pageSize,
      props.topicId,
      difficultyLevel,
      Status,
      objective,
      targetAudience,
      code,
      contentType,
      questionType,
      contentFilter
    );
    setAllQuestionsData(getQuestions);
    let filteredData = getMappedQuestions(getQuestions);
    setQuestionPageState((old) => ({
      ...old,
      isLoading: false,
      data: filteredData,
      total: filteredData?.length > 0 ? filteredData[0].totalCount : 0,
    }));
  };
  console.log(bulkValue, "bulkValues");

  const reloadGridFunction = (page, pageSize) => {
    console.log("reloadinfunction", reloadGrid);
    filterValues(page, pageSize, contentFilter);
  };

  const filterValues = (pageNumber, pageSize, contentFilter) => {
    console.log(bulkOption, "bulkoption.name");
    console.log(bulkValue, "bulkValue");
    console.log(contentFilter, "contentFilterVals1");
    if (enableSearch == false) {
      if (bulkOption.name == "Difficulty Level") {
        difficultyLevel = bulkValue;
      }
      if (bulkOption.name == "Status") {
        Status = bulkValue;
      }
      if (bulkOption.name == "Objective") {
        // if (bulkValue == JSON.stringify("none")) {
        //   objective = JSON.parse(bulkValue);
        // } else {
        // }
        objective = bulkValue;
      }
      if (bulkOption.name == "Target Audience") {
        // if (bulkValue == JSON.stringify("none")) {
        //   targetAudience = JSON.parse(bulkValue);
        // } else {
        // }
        targetAudience = bulkValue;
      }
      if (bulkOption.name == "Code") {
        code = bulkValue;
      }
      if (bulkOption.name == "Content Type") {
        contentType = bulkValue;
      }
      if (bulkOption.name == "Question Type") {
        questionType = bulkValue;
      }
    } else {
      difficultyLevel = 0;
      Status = 0;
      objective = null;
      targetAudience = null;
      code = null;
      contentType = 0;
      questionType = 0;
    }
    console.log(Status, "Status123");
    fetchData(
      pageNumber,
      pageSize,
      difficultyLevel,
      Status,
      objective,
      targetAudience,
      code,
      contentType,
      questionType,
      contentFilter
    );
  };
  React.useEffect(() => {
    console.log(" on page changes");
    if (questionPageState.page > 0) {
      filterValues(
        questionPageState.page,
        questionPageState.pageSize,
        contentFilter
      );
    }
  }, [
    questionPageState.page,
    questionPageState.pageSize,
    deleteQuestion.isSuccess,
    publishQuestionCall.isSuccess,
    reloadGrid,
  ]);

  const openMenu = Boolean(menu);

  const handleSearch = () => {
    // setEnableSearch(false);
    enableSearch = false;
    if (questionPageState.page > 1) {
      setQuestionPageState({ pageSize: 10, page: 1 });
    } else {
      filterValues(1, questionPageState.pageSize, contentFilter);
    }
  };
  const handleClear = () => {
    handleBulkOptionFieldChange(0);
    // setBulkValue(null);
    // setEnableSearch(true);
    enableSearch = true;
    if (questionPageState.page > 1) {
      setQuestionPageState({ pageSize: 10, page: 1 });
    } else {
      setQuestionPageState({ pageSize: 10, page: 1 });
      filterValues(1, 10, contentFilter);
    }
  };
  const handleClick = (event) => {
    setMenu(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenu(null);
  };

  const removeQuestion = (id) => {
    deleteQuestion.mutate(id);
  };
  const publishQuestion = (id) => {
    publishQuestionCall.mutate(id);
  };

  const createQuestionEditForm = async (id, qType) => {
    let getCategory = await useGetCategoryService.useGetCategoryService();
    console.log("categories", getCategory.length);
    if (getCategory?.length > 0) {
      let title = id == 0 ? "Create Question" : "Update Question";
      console.log(qType, id, "click type");

      setQuestionEditForm(
        <CustomModal
          modal={true}
          setModal={() => setQuestionEditForm(null)}
          content={
            <QuestionForm
              id={id}
              questionType={qType}
              reloadGridFunction={reloadGridFunction}
              afterFinish={hideQuestionEditForm}
            />
          }
          cancelButtonMessage="Cancel"
          okButtonMessage="Create"
          showOk={false}
          onCancelClick={() => setQuestionEditForm(null)}
          title={title}
          maxWidth="xl"
          fullWidth={true}
        />
      );
    } else {
      showSnackBar(
        "Please create category/topic(s) hierarchy and system configuration ",
        "error"
      );
      setAlert(null);
    }
  };

  const hideQuestionEditForm = () => {
    setQuestionEditForm(null);
    enableSearch = true;
    if (questionPageState.page > 1) {
      setQuestionPageState({ pageSize: 10, page: 1 });
    } else {
      filterValues(1, 10, contentFilter);
    }
  };

  React.useEffect(() => {
    //console.log(objectiveResponse, 'objectiveResponse')
    // if (objectiveResponse != undefined) {
    //   setObjectiveOptions(objectiveResponse);
    // }
    if (objectiveResponse && objectiveResponse.length > 0) {
      setObjectiveOptions(
        objectiveResponse.map((x) => {
          return {
            id: x.id,
            name: x.valueString,
          };
        })
      );
    }
  }, [objectiveResponse]);

  React.useEffect(() => {
    //console.log(targetAudienceResponse, 'targetAudienceResponse')
    // if (targetAudienceResponse != undefined) {
    //   setTargetAudienceOptions(targetAudienceResponse);
    // }
    if (targetAudienceResponse && targetAudienceResponse.length > 0) {
      setTargetAudienceOptions(
        targetAudienceResponse.map((x) => {
          return {
            id: x.id,
            name: x.valueString,
          };
        })
      );
    }
  }, [targetAudienceResponse]);

  const getCustomInput = (type) => {
    return (
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          fullWidth: true,
          onBlur: handleInputChange,
          type: type,
          autoComplete: "off",
        }}
      />
    );
  };

  const getCustomSelect = (options) => {
    if (options.length <= 0) return;
    return (
      <CustomSelect
        options={options.map((x) => ({ id: x.id, name: x.name }))}
        setvalue={(v) =>
          handleInputChange({ target: { value: v == "true" ? true : false } })
        }
      />
    );
  };

  const getCustomSelectForTopic = (options, showNotConf) => {
    if (options.length <= 0) return;
    return (
      <CustomSelect
        options={options.map((x) => ({ id: x.id, name: x.name }))}
        setvalue={(v) => handleInputChange({ target: { value: v } })}
        showNotConf={showNotConf === true ? true : false}
      />
    );
  };

  const getCustomSelectMultiple = (options) => {
    if (options.length <= 0) return;
    //console.log("options", options);
    return (
      <CustomMultipleSelect
        options={options ? options : ""}
        setvalue={(v) =>
          handleInputChange({ target: { value: JSON.stringify(v) } })
        }
        value={[]}
      />
    );
  };
  React.useEffect(() => {
    let cols = [
      {
        field: "id",
        headerName: "Id",
        editable: false,
        hide: true,
        width: 100,
      },
      {
        field: "hint",
        headerName: "hint",
        editable: false,
        hide: true,
        width: 100,
      },
      {
        field: "code",
        headerName: "Code",
        editable: false,
        width: 180,
        resizable: true,
      },
      {
        field: "text",
        headerName: "Question",
        editable: false,
        width: 250,
        renderCell: (cellValues) => {
          console.log("cellvalues1", cellValues);
          return (
            // || typeof(cellValues.row.text) == 'object'
            <span>
              {cellValues.row.text == null ||
              cellValues?.row?.text?.length === 0 ? (
                <Tooltip
                  title={
                    (
                      <img
                        style={{
                          border: "1px solid #E63673",
                          borderRadius: 5,
                          padding: 2,
                        }}
                        src={cellValues?.row?.image}
                      />
                    ) ?? "none"
                  }
                >
                  {" "}
                  <span style={{ fontStyle: "italic" }}>
                    "Image Based Question"
                  </span>
                </Tooltip>
              ) : (
                cellValues.row.text
              )}
            </span>
            // <span>{cellValues.row.text == null || cellValues?.row?.text?.length === 0 ? <span style={{ fontStyle: 'italic' }}>"Image Based Question"</span> : cellValues.row.text}</span>
          );
        },
      },
      {
        field: "providerName",
        headerName: "Provider Name",
        editable: false,
        width: 200,
        resizable: true,
      },
      {
        field: "questionType",
        headerName: "Types",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          //console.log("cellvalues1", cellValues)
          return (
            <span>
              {cellValues.row.questionType == "1"
                ? "MCQ"
                : cellValues.row.questionType == "2"
                ? "SCQ"
                : cellValues.row.questionType == "3"
                ? "True/False"
                : cellValues.row.questionType == "4"
                ? "NUMERICAL"
                : ""}
            </span>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        editable: false,
        width: 130,
        renderCell: (cellValues) => {
          return (
            <span>
              {cellValues.row.status == "1"
                ? "Draft"
                : cellValues.row.status == "2"
                ? "Ready"
                : cellValues.row.status == "3"
                ? "Published"
                : ""}
            </span>
          );
        },
      },
      {
        field: "difficultyLevel",
        headerName: "Difficulty Level",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          return (
            <span>
              {cellValues.row.difficultyLevel == "3"
                ? "Difficult"
                : cellValues.row.difficultyLevel == "1"
                ? "Easy"
                : "Medium"}
            </span>
          );
        },
      },
      {
        field: "isLocked",
        headerName: "isLocked",
        editable: false,
        hide: true,
        width: 130,
      },
      {
        field: "answer",
        headerName: "answer",
        editable: false,
        hide: true,
        width: 130,
      },
      {
        field: "actions",
        headerName: "Actions",
        editable: false,
        width: 190,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          //console.log(cellValues)
          return (
            <>
              {isPrivilege("PreviewQuestion") == true ? (
                <PreviewComponent
                  isShowPublishQuestion={isPrivilege("PublishQuestion") == true} //isPrivilege("PublishedCount") == true
                  questionName={cellValues?.row?.text}
                  questionId={cellValues.row.id}
                  multiple={false}
                  reloadGridFunction={reloadGridFunction}
                />
              ) : (
                ""
              )}
              {isPrivilege("UpdateQuestion") == true ? (
                <Tooltip title="Edit">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="reddit"
                    style={{ backgroundColor: "#DD4B39" }}
                    onClick={(e) => {
                      e.preventDefault();
                      createQuestionEditForm(cellValues.row.id);
                    }}
                    //disabled={cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : false}
                    disabled={
                      cellValues.row.providerId == providerId
                        ? cellValues.row.status == 3
                          ? true
                          : false
                        : true
                    }
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
              {isPrivilege("PublishQuestion") == true ? (
                <Tooltip title="Publish">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="reddit"
                    style={{ backgroundColor: "#DD4B39" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setAlert(
                        <SweetAlert
                          style={{ display: "block", marginTop: "-100px" }}
                          title={" "}
                          onConfirm={() => publishQuestion(cellValues.row.id)}
                          showCancel={true}
                          cancelBtnText={"CANCEL"}
                          confirmBtnText={"OK"}
                          onCancel={() => setAlert(null)}
                          confirmBtnCssClass={
                            classes.button + " " + classes.success
                          }
                          cancelBtnCssClass={
                            classes.button + " " + classes.warning
                          }
                        >
                          {/* <div style={{ display: 'inline-flex' }}>
                              <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                              <h4> Are you sure you want to Publish the selected question? Once published, no further edits will be allowed. Please review your content and ensure it's ready for public access.
                                Note: You can't make changes after publishing. Double-check before confirming</h4>
                            </div> */}

                          <div>
                            <div
                              style={{ paddingInline: "7%", color: "black" }}
                            >
                              <h4 style={{ fontWeight: "500", color: "brown" }}>
                                Warning
                              </h4>
                              <ul
                                style={{
                                  listStyleType: "none",
                                  padding: 0,
                                  textAlign: "left",
                                }}
                              >
                                <li>
                                  No further edits will be allowed in the
                                  selected question(s) after Published.
                                  <br />
                                </li>
                                <li>
                                  {" "}
                                  Please review and ensure that it's Ready for
                                  public access. <br />
                                </li>
                              </ul>
                            </div>
                            <br />
                            <small>
                              {" "}
                              <ul
                                style={{
                                  listStyleType: "none",
                                  textAlign: "left",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                <li>
                                  Are you sure you want to Publish the selected
                                  question(s)?{" "}
                                </li>
                              </ul>
                            </small>
                          </div>
                        </SweetAlert>
                      );
                    }}
                    disabled={cellValues.row.status == 2 ? false : true}
                  >
                    <PublicIcon />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
              {isPrivilege("DeleteQuestion") == true ? (
                <Tooltip title="Delete">
                  <Button
                    size="sm"
                    justIcon
                    round
                    color="reddit"
                    onClick={(e) => {
                      e.preventDefault();
                      setAlert(
                        <SweetAlert
                          style={{ display: "block", marginTop: "-100px" }}
                          title={" "}
                          onConfirm={() => removeQuestion(cellValues.row.id)}
                          showCancel={true}
                          cancelBtnText={"CANCEL"}
                          confirmBtnText={"OK"}
                          onCancel={() => setAlert(null)}
                          confirmBtnCssClass={
                            classes.button + " " + classes.success
                          }
                          cancelBtnCssClass={
                            classes.button + " " + classes.warning
                          }
                        >
                          <div style={{ display: "inline-flex" }}>
                            <WarningOutlined
                              style={{
                                marginTop: "10px",
                                marginRight: "5px",
                                color: "brown",
                              }}
                            />
                            <h4>
                              {" "}
                              Are you sure you want to delete this question?
                            </h4>
                          </div>
                        </SweetAlert>
                      );
                    }}
                    disabled={
                      cellValues.row.providerId == providerId
                        ? cellValues.row.status == 3
                          ? true
                          : false
                        : true
                    }
                  >
                    <DeleteForeverIcon />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
            </>
          );
        },
      },
    ];

    setColumns([...cols]);

    setBulkOptions([
      {
        id: 0,
        name: "Select",
        inputType: "text",
        key: "select",
        // updateMethod: updateQuestion,
        component: getCustomSelectForTopic([
          {
            id: 0,
            name: "Select",
          },
        ]),
      },
      {
        id: 1,
        name: "Difficulty Level",
        inputType: "text",
        key: "difficultyLevel",
        updateMethod: updateQuestion,
        component: getCustomSelectForTopic([
          {
            id: 0,
            name: "none",
          },
          {
            id: 1,
            name: "Easy",
          },
          {
            id: 2,
            name: "Medium",
          },
          {
            id: 3,
            name: "Difficult",
          },
        ]),
      },
      // {
      //   id: 2,
      //   name: "Recommended Points",
      //   inputType: "number",
      //   key: "points",
      //   updateMethod: updateQuestion,
      //   component: getCustomInput("number"),
      // },
      {
        id: 3,
        name: "Status",
        inputType: "text",
        key: "status",
        updateMethod: updateQuestion,
        component: getCustomSelectForTopic([
          {
            id: 0,
            name: "none",
          },
          {
            id: 1,
            name: "Draft",
          },
          {
            id: 2,
            name: "Ready",
          },
          // {
          //   id: 3,
          //   name: "Publish",
          // },
          ...(isPrivilege("PublishQuestion")
            ? [{ id: 3, name: "Publish" }]
            : []),
        ]),
      },
      {
        id: 8,
        name: "Objective",
        inputType: "text",
        key: "objective",
        updateMethod: updateQuestion,
        // component: getCustomSelectMultiple(objectiveOptions),          //multiple select
        component: getCustomSelectForTopic(objectiveOptions, true),
      },
      {
        id: 9,
        name: "Target Audience",
        inputType: "text",
        key: "targetAudience",
        updateMethod: updateQuestion,
        // component: getCustomSelectMultiple(targetAudienceOptions),     // multiple select
        component: getCustomSelectForTopic(targetAudienceOptions, true),
      },
      {
        id: 10,
        name: "Content Type",
        inputType: "text",
        key: "contenttype",
        updateMethod: updateQuestion,
        component: getCustomSelectForTopic([
          {
            id: 0,
            name: "none",
          },
          {
            id: 1,
            name: "Text Based",
          },
          {
            id: 3,
            name: "Latex Based",
          },
        ]),
      },
      {
        id: 11,
        name: "Question Type",
        inputType: "text",
        key: "questiontype",
        updateMethod: updateQuestion,
        component: getCustomSelectForTopic([
          {
            id: 0,
            name: "none",
          },
          {
            id: 1,
            name: "MCQ",
          },
          {
            id: 2,
            name: "SCQ",
          },
          {
            id: 3,
            name: "True/False",
          },
          {
            id: 4,
            name: "Numerical",
          },
        ]),
      },
      {
        id: 12,
        name: "Code",
        inputType: "text",
        key: "code",
        updateMethod: updateQuestion,
        component: getCustomInput("text"),
      },
    ]);
  }, [objectiveOptions, targetAudienceOptions]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };

  const handleRowSelection = (e) => {
    setSelection(e);
  };

  const updateQuestion = async (selected, oData, option, value) => {
    // let candidates = getAllSelectedOrgData(selected, oData);

    // candidates.forEach((x) => {
    //   x[option["key"]] = value;

    //   if (option["key"] == "isLocked") {
    //     x["lockedBy"] = user;
    //     x["lockedDate"] = new Date();
    //   }
    // });
    console.log(
      option?.key,
      "key",
      value,
      "valuesFrUpdate",
      value == "none",
      "vals123"
    );
    const valueArray = []; //@Pranav Created array for objective and target audience due single select dropdown, remove it when we shift to multiselect dropdown
    if (option?.key == "objective" || option?.key == "targetAudience") {
      valueArray.push(value);
    }

    console.log(valueArray, "valueArray123");

    let stringifyValue =
      valueArray.length > 0 ? JSON.stringify(valueArray) : value;

    if (value == "none") {
      stringifyValue = JSON.parse(value);
    }
    await update.mutateAsync({ selected, option, stringifyValue });
    //setSelection([])
    // fetchData(questionPageState.page, questionPageState.pageSize);
    enableSearch = true;
    if (questionPageState.page > 1) {
      setQuestionPageState({ pageSize: 10, page: 1 });
      setSelection([]);
    } else {
      filterValues(1, 10, contentFilter);
      setSelection([]);
    }
  };

  const updateTopicQuestion = async (selected, oData, option, value) => {
    bulkUpdateTopicQuestionMapping({
      topicId: value,
      questionIds: selected,
    });
  };

  const updateLanguage = async (selected, oData, option, value) => {
    let candidates = getAllSelectedOrgData(selected, oData);

    candidates.forEach((x) => {
      x.questionLanguages["$values"][0][option["key"]] = value;
    });

    update.mutate(candidates);
  };

  React.useEffect(() => {
    console.log(update, "up123");
    if (update.isSuccess == true) {
      if (update.data === "") {
        showSnackBar(
          "You are not allowed to change the status of Published questions.",
          "warning"
        );
      } else if (typeof update.data === "string") {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={""}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >
            <b style={{ marginBottom: "10px" }}> {update.data}</b> <br />
            <br />
            <small>
              <span style={{ float: "left", fontWeight: 500 }}>Note:</span>{" "}
              <ul style={{ textAlign: "left" }}>
                <li>
                  If the question not updated successfully, it means that the
                  'Ready'or 'Publish' test criteria have not been met.
                </li>
                <li>
                  You are not allowed to change the status of Published
                  questions.
                </li>
              </ul>
            </small>
          </SweetAlert>
        );
        // showSnackBar(
        //   <>
        //     <div>
        //       <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
        //         <li>{update.data} <br /></li>
        //       </ul>
        //     </div>
        //     <small><span style={{ float: 'left', fontWeight: 500 }}>Note:</span> <ul style={{ textAlign: 'left' }}>
        //       <li>You are not allowed to change the status of Published questions.</li>
        //     </ul>
        //     </small>
        //   </>
        //   , "success");
      } else if (typeof update.data === "object") {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={""}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >
            <b style={{ marginBottom: "10px" }}>
              {" "}
              Question(s) updated successfully
            </b>{" "}
            <br />
            <br />
            <div style={{ paddingInline: "30%" }}>
              <ul
                style={{ listStyleType: "none", padding: 0, textAlign: "left" }}
              >
                <li>
                  Total questions: {update.data.totalCount} <br />
                </li>
                <li>
                  Publish questions: {update.data.publishedCount} <br />
                </li>
                <li>
                  Ready questions: {update.data.readyCount} <br />
                </li>
                <li>
                  Draft questions: {update.data.draftCount} <br />
                </li>
              </ul>
            </div>
            <br />
            <small>
              <span style={{ float: "left", fontWeight: 500 }}>Note:</span>{" "}
              <ul style={{ textAlign: "left" }}>
                <li>
                  If the question(s) status is not set to 'Ready'or 'Publish',
                  it means that the 'Ready'or 'Publish' question(s) criteria
                  have not been met.
                </li>
                <li>
                  You are not allowed to change the status of Published
                  question(s).
                </li>
              </ul>
            </small>
          </SweetAlert>
        );
        // showSnackBar("Total questions: " + update.data.totalCount + ", Ready questions: " + update.data.readyCount + ", Draft questions: " + update.data.draftCount);
      } else {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={""}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >
            <b style={{ marginBottom: "10px" }}> {update.data}</b> <br />
            <br />
            <small>
              <span style={{ float: "left", fontWeight: 500 }}>Note:</span>{" "}
              <ul style={{ textAlign: "left" }}>
                <li>
                  If the question not updated successfully, it means that the
                  'Ready'or 'Publish' test criteria have not been met.
                </li>
                <li>
                  You are not allowed to change the status of Published
                  questions.
                </li>
              </ul>
            </small>
          </SweetAlert>
        );
        // showSnackBar(update.data, "warning");
      }
    }
    if (update.isError == true) {
      showSnackBar("Questions not updated", "error");
    }
  }, [update.isSuccess, update.isError]);

  const handleBulkOptionFieldChange = (v) => {
    // setBulkValue("");
    setBulkOption(bulkOptions.find((x) => x.id == v));
  };

  const getAllSelectedOrgData = (selected, oData) => {
    let d = [];
    console.log(oData, "oData");
    selected.forEach((x) => {
      let r = oData.find((y) => y.questionId == x);
      console.log(r, "updateQuestion5");
      if (r) {
        d.push(r);
      }
    });

    return d;
  };

  const handleUpload = async () => {
    console.log(bulkOption, "25pp", bulkValue, "and", select);
    if (!bulkOption.updateMethod) {
      showSnackBar("Please select field to update", "warning");
      return;
    }
    if (bulkValue == "none") {
      showSnackBar("Cannot update not configured", "warning");
      return;
    }
    if (bulkOption.name == "Code") {
      showSnackBar("Cannot update question code ", "error");
      return;
    }
    if (
      (bulkOption.name == "Objective" ||
        bulkOption.name == "Target Audience") &&
      bulkValue == JSON.stringify("none")
    ) {
      showSnackBar("Cannot update not configured ", "warning");
      return;
    }
    if (bulkOption.name == "Content Type") {
      showSnackBar("Cannot update question content type ", "error");
      return;
    }
    if (bulkOption.name == "Question Type") {
      showSnackBar("Cannot update question type ", "error");
      return;
    }
    if (bulkValue == "") {
      showSnackBar("Please select values to update", "warning");
      return;
    }
    console.log("reached25");
    if (select.length <= 0) {
      showSnackBar("Please select questions to update", "warning");
      return;
    }

    await bulkOption.updateMethod(
      select,
      allQuestionsData,
      bulkOption,
      bulkValue
    ); // questionData.org,
  };

  React.useEffect(() => {
    if (deleteQuestion.isSuccess == true) {
      showSnackBar("Question deleted successfully");
      setAlert(null);
    }
  }, [deleteQuestion.isSuccess]);

  React.useEffect(() => {
    if (deleteQuestion.isError == true) {
      showSnackBar(deleteQuestion?.error?.response?.data, "error");
      setAlert(null);
    }
  }, [deleteQuestion.isError]);

  React.useEffect(() => {
    if (IsTopicUpdateSuccess == true) {
      showSnackBar("Question deleted successfully");
    }
  }, [IsTopicUpdateSuccess]);

  const handleEdit = (p, e) => {
    console.log(p);
  };

  const onCellEditCommit = (p) => {
    let row = questionPageState?.data?.questions.find((x) => x.id == p.id);
    if (row[p.field] == p.value) {
      return;
    }
  };

  const handleInputChange = (e) => {
    setBulkValue(e.target.value);
  };

  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };

  React.useEffect(() => {
    console.log(publishQuestionCall, "publishQuestionSuccess");
    if (publishQuestionCall.isSuccess == true) {
      showSnackBar("Test Question published successfully");
      setAlert(null);
      // refetch();
    }
  }, [publishQuestionCall.isSuccess]);

  React.useEffect(() => {
    console.log(publishQuestionCall, "publishQuestionError");
    if (publishQuestionCall.isError == true) {
      showSnackBar(publishQuestionCall?.error?.response?.data, "error");
      setAlert(null);
    }
  }, [publishQuestionCall.isError]);

  const checkPublish = async () => {
    if (bulkOption.name == "Status" && bulkValue == 1) {
      showSnackBar("Draft question cannot be published ", "error");
      return;
    }
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={" "}
        onConfirm={() => {
          handleUpload(), setAlert(null);
        }}
        showCancel={true}
        cancelBtnText={"CANCEL"}
        confirmBtnText={"OK"}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.warning}
      >
        <div>
          {/* <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} /> */}
          {/* <h4> Are you sure you want to Publish the selected question? Once published, no further edits will be allowed. Please review your content and ensure it's ready for public access.
            Note: You can't make changes after publishing. Double-check before confirming</h4> */}
          <div style={{ paddingInline: "7%", color: "black" }}>
            <h4 style={{ fontWeight: "500", color: "brown" }}>Warning</h4>
            <ul
              style={{ listStyleType: "none", padding: 0, textAlign: "left" }}
            >
              <li>
                No further edits will be allowed in the selected question(s)
                after Published.
                <br />
              </li>
              <li>
                {" "}
                Please review and ensure that it's Ready for public access.{" "}
                <br />
              </li>
            </ul>
          </div>
          <br />
          <small>
            {" "}
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                color: "black",
                fontWeight: "500",
              }}
            >
              <li>
                Are you sure you want to Publish the selected question(s)?{" "}
              </li>
            </ul>
          </small>
        </div>
      </SweetAlert>
    );
  };

  const getRowClassName = (row) => {
    if (row.isLocked == true) {
      return "gray";
    }
    return null;
  };

  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Question.indexOf(x) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  const handleAlignment = (event, content) => {
    console.log(content, "contentFilterVals2");
    setContentFilter(content);
    filterValues(questionPageState.page, questionPageState.pageSize, content);
  };
  return (
    <Card>
      {alert}
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <Help />
        </CardIcon>
      </CardHeader>
      <CardBody>
        {props && props.hideHeader == true ? null : null}

        {deleteQuestion.isLoading && (
          <h4 style={{ color: "warning" }}>Deleting question...</h4>
        )}
        <GridContainer>
          <GridItem xs={12} sm={12} md={2}>
            <CustomSelect
              label="Select field"
              options={bulkOptions}
              setvalue={handleBulkOptionFieldChange}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            {bulkOption && bulkOption.component ? (
              bulkOption.component
            ) : (
              <CustomInput
                id="filename"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  fullWidth: true,
                  onChange: handleInputChange,
                  type: "text",
                  value: bulkValue,
                  autoComplete: "off",
                }}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Button
              color="primary"
              onClick={handleClear}
              style={{
                marginTop: 20,
                marginRight: 14,
                padding: 12,
                textTransform: "none",
              }}
              // disabled={questionPageState.isLoading == true}
            >
              <ClearOutlined style={{ marginRight: "0px" }} />
            </Button>
            <Button
              color="primary"
              onClick={handleSearch}
              style={{
                marginTop: 20,
                marginRight: 14,
                padding: 12,
                textTransform: "none",
              }}
              disabled={questionPageState.isLoading == true}
            >
              <SearchOutlined style={{ marginRight: "0px" }} />
            </Button>
            {isPrivilege("UpdateQuestion") == true ? (
              <Button
                color="primary"
                onClick={
                  bulkOption.name == "Status" && bulkValue === 3
                    ? checkPublish
                    : handleUpload
                }
                style={{ marginTop: 20, textTransform: "none" }}
                disabled={update.isLoading == true || bulkUpdateLoading == true}
              >
                {update.isLoading == true || bulkUpdateLoading == true
                  ? "Updating..."
                  : "Update"}
              </Button>
            ) : (
              ""
            )}
          </GridItem>

          {/* <GridItem xs={12} sm={12} md={2} style={{ textAlign: "right" }}>
          </GridItem> */}
          <GridItem xs={12} sm={12} md={4} style={{ textAlign: "right" }}>
            <ToggleButtonGroup
              value={contentFilter}
              color="secondary"
              size="small"
              exclusive
              onChange={handleAlignment}
              aria-label="content"
              style={{ marginRight: 10, marginTop: 20 }}
            >
              <ToggleButton value={"0"} aria-label="All content">
                All
              </ToggleButton>
              <ToggleButton value={"1"} aria-label="Owned content">
                Owned
              </ToggleButton>
              <ToggleButton value={"2"} aria-label="Subscribed content">
                Subscribed
              </ToggleButton>
            </ToggleButtonGroup>
            {isPrivilege("CreateQuestion") == true ? (
              <Button
                color="primary"
                onClick={() => {
                  createQuestionEditForm(0);
                }}
                style={{ textTransform: "none" }}
              >
                + New
              </Button>
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>
        {questionPageState.isLoading == false &&
        questionPageState.data &&
        questionPageState.data.length > 0 &&
        columns.length > 0 ? (
          <div style={{ height: 650, width: "100%" }}>
            {isPrivilege("ViewQuestion") == true ? (
              <CustomDataGrid
                rows={questionPageState.data}
                rowCount={questionPageState.total}
                loading={questionPageState.isLoading}
                rowsPerPageOptions={[10, 30, 50, 70, 100]}
                page={questionPageState.page - 1}
                pageSize={questionPageState.pageSize}
                paginationMode="server"
                onPageChange={(newPage) =>
                  setQuestionPageState((old) => ({ ...old, page: newPage + 1 }))
                }
                onPageSizeChange={(newPageSize) =>
                  setQuestionPageState((old) => ({
                    ...old,
                    pageSize: newPageSize,
                  }))
                }
                columns={columns}
                onEditCellPropsChange={handleEdit}
                onCellEditCommit={onCellEditCommit}
                onSelectionModelChange={handleRowSelection}
                //isRowSelectable={(params: GridRowParams) => (params.row.status == 3 ? false : true)}
                //isRowSelectable={(params: GridRowParams) => params.row.providerId == providerId ? true : false}
                checkboxSelection
                getRowClassName={getRowClassName}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          <div style={{ height: 645, width: "100%" }}>
            <CustomDataGrid
              rows={[]}
              loading={questionPageState.isLoading}
              columns={columns}
              checkboxSelection
              getRowClassName={getRowClassName}
            />
          </div>
        )}
      </CardBody>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {questionEditForm}
    </Card>
  );
}
