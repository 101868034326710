import React, { useContext, useEffect } from "react";
// @material-ui/icons


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomMultipleSelect from "components/CustomSelect/CustomMultipleSelect";
import DropDownSelect from "components/DropDownSelect/DropDownSelect";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { LoginContext } from "contexts/LoginContext";
import useGetTestsCount from "hooks/testsHooks/useGetTestsCount";
import getObjectiveService from "services/ConfigObjective/getObjectiveService";
import getTargetAudience from "services/TargetAudience/getTargetAudience";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const TopicwiseSelectTopics = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { data: testsCount } = useGetTestsCount();
  const { licensePackageObject } = useContext(LoginContext);


  const [getObjectiveData, setObjectiveData] = React.useState([]);
  const [getTargetAudienceData, setTargetAudienceData] = React.useState([]);

  const [objective, setObjective] = React.useState(null);
  const [targetAudience, setTargetAudience] = React.useState(null);

  const [topicIds, setTopicIds] = React.useState([]);
  const [packageExceded, SetPackageExceded] = React.useState(false);


  const getObjectsndTargetAudience = async () => {
    try {
      let getOjbectives = await getObjectiveService.getObjective(true, true);
      setObjectiveData(getOjbectives['$values']);
    } catch (error) {
      console.log("error fetching objecitve", error);
    }
    try {
      let getTargetAudiences = await getTargetAudience.getTargetAudience(true, true);
      setTargetAudienceData(getTargetAudiences['$values']);
    } catch (error) {
      console.log("error fetching target audience", error);
    }
  }

  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  React.useEffect(() => {
    getObjectsndTargetAudience();
  }, []);

  useEffect(() => {
    if ((testsCount + topicIds?.length + 1) > licensePackageObject?.Tests) {
      console.log(testsCount, topicIds?.length, "lwnths");
      SetPackageExceded(true);
    }
  }, [topicIds]);

  console.log("topicIds", topicIds);
  const isValidated = () => {
    if (packageExceded == true) {
      return false;
    }

    const targetAudienceIsEmpty = targetAudience === null || targetAudience === "[]";
    const objectiveIsEmpty = objective === null || objective === "[]";

    if (!targetAudienceIsEmpty && objectiveIsEmpty) {
      return false;
    }

    if (topicIds.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      topicIds,
      packageExceded,
      objective,
      targetAudience
    },
  }));
  const sendState = () => {
    return {
      type: "topics",
      topicIds,
      packageExceded,
      objective,
      targetAudience
    };
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={11}>
          <small style={{ color: 'red' }}>Note: Category is mandatory and only child nodes are selectable.</small>

        </GridItem>
      </GridContainer>
      <GridContainer justify="left" style={{ marginLeft: '30px' }}>
        <GridItem xs={12} sm={6} md={4}>
          <CustomMultipleSelect
            label="Objectives"
            selectLabel="Assp"
            options={getObjectiveData ? getObjectiveData : ''}
            setvalue={(v) => {
              setObjective(JSON.stringify(v));
            }}
            id="objective"
            name="objective"
            value={
              objective && objective
                ? isJSON(objective) == true
                  ? JSON.parse(objective)
                  : []
                : []
            }
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CustomMultipleSelect
            label="TargetAudience"
            selectLabel="Assp"
            options={getTargetAudienceData ? getTargetAudienceData : ''}
            setvalue={(v) => {
              //console.log('slected field', v)
              setTargetAudience(JSON.stringify(v));
            }}
            id="targetAudience"
            name="targetAudience"
            value={
              targetAudience && targetAudience
                ? isJSON(targetAudience) == true
                  ? JSON.parse(targetAudience)
                  : []
                : []
            }
          />
        </GridItem>

      </GridContainer>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={11}>
          <DropDownSelect setTopics={setTopicIds} isContentProvider={true} showCategoriesOnly={false} disableParentSelection={true} />
        </GridItem>
      </GridContainer>

    </div>
  );
});

TopicwiseSelectTopics.displayName = "Step1";

export default TopicwiseSelectTopics;
