//import { Grid, Paper, Typography } from '@mui/material';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Box, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import testService from "services/TestService/testService";

const TestDetailsComponent = (props) => {
  const { state, afterFinish } = props;
  //const[testCode,setTestCode] = useState(state.testCode);
  //const data=testService.getTestDetails(2);
  //console.log("Test data",data);
  const [testDetails, setTestDetails] = useState(null);
  const [open, setOpen] = useState(false);





  useEffect(() => {
    testService.getTestDetails(state)
      .then(testDetails => {
        console.log("data: ", state);
        setTestDetails(testDetails);
        console.log(testDetails);
        console.log(testDetails.testData);

      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  if (!testDetails) {
    return (
      <Grid container spacing={3} style={{ minHeight: '800px', minWidth: '930px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <TableContainer style={{ display: 'flex', justifyContent: 'center', Align: 'center' }}>

          <h3>Loading...</h3>

        </TableContainer>
      </Grid>
    )
  }

  let questioncount = 0;
  let questionCanAttempt = 0;

  for (let i = 0; i < testDetails.poolData.$values.length; i++) {
    const qCounts = testDetails.poolData.$values[i].questionCount
    questioncount = questioncount + qCounts;

    let questionAttempts = testDetails.poolData.$values[i].maxQuestionForScore
    if (!questionAttempts) {
      questionAttempts = qCounts;
    }
    questionCanAttempt = questionCanAttempt + questionAttempts;


  }

  function formatDate(dateString) {
    const utcdate = new Date(dateString);
    const localDate = new Date(utcdate.getTime() - (utcdate.getTimezoneOffset() * 60 * 1000));
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };


    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const formattedDate = formatter.format(new Date(localDate));

    const [date, time] = formattedDate.split(', ');
    const [day, month, year] = date.split(' ');

    const capitalizedTime = time.replace(/am|pm/, (match) => match.toUpperCase());

    return `${day}-${month}-${year} ${capitalizedTime}`;
  }



  const handleToggle = () => {
    setOpen(!open);
  };
  return (


    <Grid container spacing={3} style={{ width: '900px', Height: '700px', minWidth: '700px', margin: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>



      <TableContainer style={{ padding: '10px', height: '100%', width: '100%' }}>
        <Table  >
          <TableBody>
            <TableRow>
              <TableCell ><strong>Test Code</strong></TableCell>
              <TableCell>{testDetails.testData && testDetails.testData.testCode || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Test Name</strong></TableCell>
              <TableCell>{testDetails.testData && testDetails.testData.testName || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Objective/Target Audience</strong></TableCell>
              {/* <TableCell> {(testDetails.testData && testDetails.testData.objective) || (testDetails.testData && testDetails.testData.targetAudience) ?
                (testDetails.testData && testDetails.testData.objective || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) + ' / ' + (testDetails.testData && testDetails.testData.targetAudience || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) :
                <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>
              }
              </TableCell> */}
              <TableCell>
                {(testDetails.testData && testDetails.testData.objective !== null) ?
                  ((testDetails.testData && testDetails.testData.objective) || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) + ' / ' :
                  <span style={{ fontStyle: 'italic', color: 'grey' }}>none / </span>
                }
                {(testDetails.testData && testDetails.testData.targetAudience !== null) ?
                  ((testDetails.testData && testDetails.testData.targetAudience) || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) :
                  <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>
                }
              </TableCell>

            </TableRow>
            <TableRow>
              <TableCell><strong>Passing Score </strong></TableCell>
              <TableCell>{testDetails.testData && testDetails.testData.passingScore || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Questions</strong></TableCell>
              <TableCell> {questioncount} Questions | {questionCanAttempt} Questions can be Attempted </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Points</strong></TableCell>
              {/* <TableCell> {(testDetails.testData && testDetails.testData.pointsPerQuestion) || (testDetails.testData && testDetails.testData.negativePointsPerQuestion) ?
                (testDetails.testData && testDetails.testData.pointsPerQuestion || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) + '/' + (testDetails.testData && testDetails.testData.negativePointsPerQuestion || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) :
                <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>
              }
              </TableCell> */}
              <TableCell>
                {testDetails.testData && testDetails.testData.pointsPerQuestion !== null ?
                  (testDetails.testData.pointsPerQuestion || 0) : 0}/
                {testDetails.testData && testDetails.testData.negativePointsPerQuestion !== null ?
                  (testDetails.testData.negativePointsPerQuestion || 0) : 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Total Marks</strong></TableCell>
              <TableCell> {testDetails.testData && testDetails.testData.totalScore || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Attempts Allowed</strong></TableCell>
              <TableCell>{testDetails.testData && testDetails.testData.attemptsAllowed || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Duration</strong></TableCell>
              <TableCell> {testDetails.testData && testDetails.testData.duration ? testDetails.testData.duration + " Mins" : <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>}  </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Scheduled Date</strong></TableCell>
              <TableCell> {testDetails.providerData && testDetails.providerData.scheduleDate != null ? formatDate(testDetails.providerData.scheduleDate) : <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Test Window</strong></TableCell>
              <TableCell> {(testDetails.providerData && testDetails.providerData.startDate) || (testDetails.providerData && testDetails.providerData.endDate) ?
                (testDetails.providerData && (testDetails.providerData.startDate ? formatDate(testDetails.providerData.startDate) : (formatDate(testDetails.providerData.scheduleDate))) || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) + ' / ' + (testDetails.providerData && formatDate(testDetails.providerData.endDate) || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) :
                <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>
              }
              </TableCell>
            </TableRow>



            <TableRow>
              <TableCell ><strong>Sections</strong>
                <IconButton aria-label="expand row" size="small" onClick={handleToggle}>
                  {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell>{testDetails.poolData.$values.length}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1, maxWidth: "400px" }}>
                    {/* ----add ,maxWidth:"400px" to box for not blink in colnm values ------*/}
                    <Table size="small" aria-label="additional-data" >
                      {/* <TableRow>
                          <TableCell>Section Name</TableCell>
                          <TableCell>#Q.Allow</TableCell>
                          <TableCell align="right">#Q.for Score</TableCell>
                          <TableCell align="right">Points</TableCell>
                          <TableCell align="right">Mandatory to Pass</TableCell>
                        </TableRow> */}
                      <TableHead>
                        <TableRow sx={{ bgcolor: "rgba(248, 235, 242, 0.2)" }}>
                          <TableCell align="left" style={{ whiteSpace: 'nowrap' }}><strong>Section Name</strong></TableCell>
                          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}><strong>Questions Allow</strong></TableCell>
                          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}><strong>Questions for Score</strong></TableCell>
                          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}><strong>Points Per Question</strong></TableCell>
                          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}><strong>Is Mandatory</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {testDetails.poolData.$values.map((section) => (
                          <TableRow key={section.$id}>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>{section.poolName}</TableCell>
                            <TableCell align="center" >{section.questionCount}</TableCell>
                            <TableCell align="center">{section.maxQuestionForScore ? section.maxQuestionForScore : section.questionCount}</TableCell>
                            {/* {(section.pointsPerQuestion == 0 && section.negativePointsPerQuestion == 0)
                              ?
                              <TableCell align='center'>
                                {(testDetails.testData && testDetails.testData.pointsPerQuestion) || (testDetails.testData && testDetails.testData.negativePointsPerQuestion) ?
                                  (testDetails.testData && testDetails.testData.pointsPerQuestion || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) + '/' + (testDetails.testData && testDetails.testData.negativePointsPerQuestion || <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>) :
                                  <span style={{ fontStyle: 'italic', color: 'grey' }}>none</span>
                                }
                              </TableCell>
                              :
                              <TableCell align="center">{section.pointsPerQuestion}/{section.negativePointsPerQuestion}</TableCell>
                            } */}

                            {(section.pointsPerQuestion === 0 && section.negativePointsPerQuestion === 0)
                              ? <TableCell align='center'>
                                {testDetails.testData && testDetails.testData.pointsPerQuestion !== null ?
                                  (testDetails.testData.pointsPerQuestion || 0) : 0}/
                                {testDetails.testData && testDetails.testData.negativePointsPerQuestion !== null ?
                                  (testDetails.testData.negativePointsPerQuestion || 0) : 0}
                              </TableCell>
                              : <TableCell align="center">
                                {section.pointsPerQuestion || 0}/{section.negativePointsPerQuestion || 0}
                              </TableCell>
                            }


                            <TableCell align="center">{section.isMandatoryToPass ? "Yes" : "No"}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>

                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell><strong>Show Result</strong></TableCell>
              <TableCell>{testDetails.testData && testDetails.testData.showResult == 2 ? "Yes" : "No"}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell><strong>Show Timer</strong></TableCell>
              <TableCell> {testDetails.testData && testDetails.testData.showTimer == 2 ? "Yes" : "No"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Total assigned students</strong></TableCell>
              <TableCell> {testDetails.studentsCount} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Test attempted students</strong></TableCell>
              <TableCell> {testDetails.attemptedStudentCount} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Yet to attempt students</strong></TableCell>
              <TableCell> {testDetails.nonAttemptedStudentCount} </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

    </Grid>


  )
}

export default TestDetailsComponent


