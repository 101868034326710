import * as React from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import useGetTopFiveStudentAnalytics from 'hooks/analyticHooks/useGetTopFiveStudentAnalytics';


export default function ChartTable({ lOid }) {

  const [getanalyticsData, setAnalyticsData] = React.useState();
  const getGraphData = async () => {
    const TopFiveStudentAnalytics = await useGetTopFiveStudentAnalytics(lOid ?? null)
    console.log(TopFiveStudentAnalytics, 'anyData')
    setAnalyticsData(TopFiveStudentAnalytics);

  }
  React.useEffect(() => {
    getGraphData();
  }, [])
  console.log(getanalyticsData, 'anyData')
  // const FormatDuration = (durationInMillis) => {
  //   // Check if durationInMillis is a valid number
  //   if (typeof durationInMillis !== 'number') {
  //     return <div><small>Invalid duration</small></div>;
  //   }
  //   // Convert milliseconds to minutes
  //   const durationInMinutes = durationInMillis / (1000 * 60);
  //   const dur = seconds/60.(seconds-((seconds/60)*60))

  //   // Use fixed-point notation with 2 decimal places
  //   const formattedDuration = durationInMinutes.toFixed(2);

  //   return formattedDuration
  // }
  function FormatDuration(durationInMillis) {
    // Convert milliseconds to seconds
    let seconds = Math.floor(durationInMillis / 1000);

    // Calculate minutes and remaining seconds
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;

    // Format the time
    let formattedTime = `${minutes} min ${seconds} sec`;

    return formattedTime;
  }

  return (
    <div style={{ margin: '30px 0px 30px 0px' }}>
      {/* <p>Top Five Students Tor This Test</p> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Score</TableCell>
              <TableCell align="center">Duration <small>(MM:SS)</small></TableCell>
              <TableCell align="center">Attempt Rate <small>(%)</small></TableCell>
              <TableCell align="center">Accuracy Rate <small>(%)</small></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {getanalyticsData && getanalyticsData["$values"].map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.score}</TableCell>
                <TableCell align="center">{FormatDuration(row.duration)}</TableCell>
                <TableCell align="center">{row.attemptRate}</TableCell>
                <TableCell align="center">{row.accuracyRate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

  );
}