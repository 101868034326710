import React, { useContext } from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";

import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreTwoTone";
import usePoolQuestionLockUnLock from "hooks/poolQuestionHooks/usePoolQuestionLockUnLock";
import useDecreaseQuestionSequence from "hooks/useDecreaseQuestionSequence";
import useGetQuestionsByPoolId from "hooks/useGetQuestionByPoolId";
import useIncreaseQuestionSequence from "hooks/useIncreaseQuestionSequence";

import { DeleteForever } from "@material-ui/icons";
import GradeIcon from "@material-ui/icons/Grade";
import { Tooltip } from "@mui/material";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { LoginContext } from "contexts/LoginContext";
import useDeletePoolQuestion from "hooks/poolQuestionHooks/useDeletePoolQuestion";
import useSetMandatory from "hooks/poolQuestionHooks/useSetMandatory";
import "katex/dist/katex.min.css";
import ReactHtmlParser from "react-html-parser";
import Latex from "react-latex-next";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PoolQuestionMappedGrid(props) {
  const { isLoading, data, refetch } = useGetQuestionsByPoolId(props.poolId);

  console.log(data, "poolInfo");
  const lockUnlock = usePoolQuestionLockUnLock();
  const mandatory = useSetMandatory();
  const decreaseSequence = useDecreaseQuestionSequence();

  const increaseSequence = useIncreaseQuestionSequence();

  const deleteQuestion = useDeletePoolQuestion();

  const [columns, setColumns] = React.useState([]);
  const [select, setSelection] = React.useState([]);
  const { user, userPrivileges } = useContext(LoginContext);

  const handleRowSelection = (e) => {
    setSelection(e);
  };
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Test.indexOf(x) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  React.useEffect(() => {
    console.log("counterhere");
    if (deleteQuestion.isSuccess) {
      props.questionsFetch();
    }
  }, [deleteQuestion.isSuccess, props.questionsFetch]);

  React.useEffect(() => {
    let cols = [
      {
        field: "id",
        headerName: "Id",
        editable: false,
        width: 100,
        hide: true,
      },
      {
        field: "code",
        headerName: "Code",
        editable: false,
        width: 180,
      },
      {
        field: "text",
        headerName: "Questions",
        editable: false,
        width: 420,
        renderCell: (cellValues) => {
          return (
            console.log(cellValues, "cellVal123"),
            (
              <span>
                {cellValues.row.text == null || cellValues.row.text == "" ? (
                  <Tooltip
                    title={
                      (
                        <img
                          style={{
                            border: "1px solid #E63673",
                            borderRadius: 5,
                            padding: 2,
                          }}
                          src={cellValues?.row?.image}
                        />
                      ) ?? "none"
                    }
                  >
                    <span style={{ fontStyle: "italic" }}>
                      "Image Based Question"
                    </span>
                  </Tooltip>
                ) : cellValues.row.contentType == 1 ||
                  cellValues.row.contentType == 2 ? (
                  cellValues.row.text?.trimStart()
                ) : cellValues.row.contentType == 3 ? (
                  <Latex>{cellValues.row.text?.trimStart()}</Latex>
                ) : cellValues.row.contentType == 4 ? (
                  ReactHtmlParser(cellValues.row.text?.trimStart())
                ) : (
                  cellValues.row.text?.trimStart()
                )}
              </span>
            )
          );
        },
      },
      {
        field: "isLocked",
        headerName: "isLocked",
        hide: true,
      },
      {
        field: "isMandatory",
        headerName: "isLocked",
        hide: true,
      },
      {
        field: "questionType",
        headerName: "Type",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          console.log("cellvalues", cellValues);
          return (
            <span>
              {cellValues.row.questionType == "1"
                ? "MCQ"
                : cellValues.row.questionType == "2"
                ? "SCQ"
                : cellValues.row.questionType == "3"
                ? "True/False"
                : cellValues.row.questionType == "4"
                ? "NUMERICAL"
                : ""}
            </span>
          );
        },
      },
      {
        field: "sequence",
        headerName: "Sequence",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          return (
            <>
              <IconButton
                aria-label="up"
                size="small"
                style={{ color: "blue" }}
                onClick={() => {
                  let qId = cellValues.row.id;
                  decreaseSequence.mutate({
                    poolId: props.poolId,
                    questionId: qId,
                  });
                }}
              >
                <ExpandLessIcon />
              </IconButton>
              {cellValues.row.sequence}
              <IconButton
                aria-label="up"
                size="small"
                style={{ color: "blue" }}
                onClick={() => {
                  let qId = cellValues.row.id;
                  increaseSequence.mutate({
                    poolId: props.poolId,
                    questionId: qId,
                  });
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            <>
              {/* {cellValues.row.isLocked == false ? (
                <Tooltip title="Lock">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="dodgerblue"
                    onClick={(e) => {
                      e.preventDefault();
                      lockUnlock.mutate({
                        id: props.poolId,
                        questionId: cellValues.row.id,
                        lock: true,
                      });
                    }}
                  >
                    <Lock />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Unlock">
                  <Button
                    size="sm"
                    justIcon
                    round
                    color="white"
                    onClick={(e) => {
                      e.preventDefault();
                      lockUnlock.mutate({
                        id: props.poolId,
                        questionId: cellValues.row.id,
                        lock: false,
                      });
                    }}
                  >
                    <LockOpen />
                  </Button>
                </Tooltip>
              )} */}
              {isPrivilege("MandatoryQuestion") == true ? (
                <Button
                  size="sm"
                  justIcon
                  round
                  color="white"
                  onClick={(e) => {
                    e.preventDefault();
                    mandatory.mutate({
                      id: props.poolId,
                      questionId: cellValues.row.id,
                      mandatory: cellValues.row.isMandatory == 0 ? 1 : 0,
                    });
                  }}
                >
                  <GradeIcon
                    style={{
                      color:
                        cellValues.row.isMandatory == 0 ? "black" : "green",
                    }}
                  />
                </Button>
              ) : (
                ""
              )}
              <Tooltip title="Delete">
                <Button
                  size="sm"
                  justIcon
                  round
                  color="reddit"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteQuestion.mutate({
                      poolId: props.poolId,
                      questionId: cellValues.row.id,
                    });
                  }}
                >
                  <DeleteForever />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
    ];

    setColumns(cols);
  }, []);

  const getRowClassName = (row) => {
    if (row.isLocked == true) {
      return "gray";
    }

    return null;
  };

  const handleEdit = (p, e) => {};

  const onCellEditCommit = (p) => {
    let row = data.find((x) => (x.id = p.id));
    if (row[p.field] == p.value) {
      return;
    }
  };

  return (
    <div>
      {isLoading == false && data && data.length > 0 && columns.length > 0 ? (
        /*  <CustomTable
            header={Object.keys(data[0])}
            rows={data.filter((_, i) => i > 0).map((x) => Object.values(x))}
          /> */

        <div style={{ height: 300, width: "100%" }}>
          <div style={{ display: "flex", height: "90%" }}>
            <div style={{ flexGrow: 1 }}>
              <CustomDataGrid
                columns={columns}
                rows={data}
                autoPageSize={true}
                onEditCellPropsChange={handleEdit}
                onCellEditCommit={onCellEditCommit}
                onSelectionModelChange={handleRowSelection}
                getRowClassName={getRowClassName}
              />
            </div>
          </div>
          {deleteQuestion.isSuccess && (
            <CustomAlert
              open={true}
              severity="success"
              alertMessage="Question deleted"
            />
          )}
        </div>
      ) : null}
    </div>
  );
}
