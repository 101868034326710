import { IconButton, InputAdornment, Snackbar } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { LoginContext } from "contexts/LoginContext";
import useGetRoles from "hooks/PrevilegesHooks/useGetRoles";
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import useGetAllState from "hooks/configurationsHooks/useGetAllState";
import useCreateApplicationUser from "hooks/userHooks/useCreateApplicationUser";
import useUpdateApplicationUser from "hooks/userHooks/useUpdateApplicationUser";
import React, { useContext, useEffect, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import usernameExists from "services/ApplicationUser/usernameExists";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TreeDropDown = React.memo(
  ({ data, onChange, className }) => {
    return (
      <>
        <DropdownTreeSelect
          data={data}
          onChange={onChange}
          className={className}
          texts={{ placeholder: "Select Category" }}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);

function User(props) {
  const [user, setUser] = React.useState(props.user);
  const [rows, setRows] = React.useState([]);
  const { providerType, providerId } = useContext(LoginContext);

  const { data: categories } = useGetCategories();
  console.log("props", props);

  const updateUser = useUpdateApplicationUser();
  const createUser = useCreateApplicationUser();


  const [options1, setOptins1] = React.useState([]);
  const [prevOptions, setPrevOptions] = React.useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [firstNameState, setFirstNameState] = useState("Success");
  const [lastNameState, setLastNameState] = useState("Success");
  const [emailState, setEmailState] = useState("Success");
  const [passwordState, setPasswordState] = useState("Success");
  const [mobileState, setMobileState] = useState("Success");
  const [userNameState, setUserNameState] = useState("Success");



  const [prevOptionsId, setprevOptionsId] = React.useState(props.roleId);
  const [showAlert, setShowAlert] = React.useState(null)
  const [flag, setFlag] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");

  console.log(props, 'itsProps')
  console.log(prevOptionsId, 'propsID')
  console.log(options1);
  const { data: statedata } = useGetAllState("state", true);

  console.log(statedata);
  const { data: getRoles } = useGetRoles()
  console.log(getRoles, 'roles123')

  React.useEffect(() => {
    if (statedata) {
      setRows(
        statedata.map((x, i) => {
          console.log(x);
          return {
            ...x,
            id: i,
            name: x.valueString,
            id: x.id,
            value: x.valueString,
          };
        })
      );
    }
  }, [statedata]);
  React.useEffect(() => {
    if (getRoles != undefined) {
      let roles = [];
      getRoles.map((role) => (
        console.log(role, providerType, 'rolescheck'),
        roles.push(role)
        // role.id < 5 ?
        //   roles.push(role) : null
      ))
      console.log(roles, 'roles125')
      setPrevOptions(roles)
    }
  }, [getRoles])
  const [userExists, setUserExists] = React.useState(false);

  const [categoryTree, setCategoryTree] = React.useState(null);
  const createHierarchy = (root) => {
    if (!root) return;

    root.label = root.name;
    root.value = root.id;

    let children = [...categories]
      .map((x) => {
        return { ...x };
      })
      .filter((x) => x.parent == root.id && x.isCategory == true);

    if (children && children.length > 0) {
      root.children = [];
      let cats = [];

      if (props.user && props.user.topics) {
        cats = props.user.topics.split(",");
      }

      children.forEach((x) => {
        x.children = [];
        if (x.isCategory == true) {
          if (cats && cats.length > 0) {
            if (cats.includes(x.id)) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          }

          x.value = x.id;
          x.label = x.name;
          root.children.push(x);
          createHierarchy(x);
        }
      });
    }

    return;
  };

  React.useEffect(() => {
    setFirstName(props?.user?.firstName);
    setLastName(props?.user?.lastName);
    setMiddleName(props?.user?.middleName);
    setMobile(props?.user?.mobile);
    setEmail(props?.user?.email);
    setAddress(props?.user?.address);
    setUserName(props?.user?.userName);
    setPassword(props?.user?.passCode);

  }, [props.user]);

  React.useEffect(() => {
    if (categories) {
      let root = [...categories]
        .map((x) => {
          return { ...x };
        })
        .find((x) => x.parent == null);

      if (props.user && props.user.topics) {
        const cats = props.user.topics.split(",");
        if (cats.includes(root.id)) {
          root.checked = true;
        }
      }

      createHierarchy(root);
      setCategoryTree(root);
    }
  }, [categories]);

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyMobileLength = (value, length) => /^\d+$/.test(value) && value.length == length;

  const verifyPassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;
    return regex.test(password);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    //addding blank for now will add validations later
    if (!value) {
      value = " ";
    }
    if (name == 'userName') {
      setUser({ ...user, [name]: value.trim() });
    }
    setUser({ ...user, [name]: value });

    if (name == 'userName') {
      setUserName(value);
    }
    if (name == 'address') {
      setAddress(value);
    }
    if (name == 'middleName') {
      setMiddleName(value);
    }
  };
  const handleChangeDropdown = (v) => {
    setprevOptionsId(v);
    console.log(v, 'prevOptionsId1')
    console.log(prevOptionsId, 'prevOptionsId')
  }

  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };
  React.useEffect(() => {
    if (updateUser.isSuccess) {
      showSnackBar('User updated Successfully', "success");
      setTimeout(() => {
        props.afterFinish();
        props?.refetch();
      }, 2000);
    }
  }, [updateUser.isSuccess]);

  React.useEffect(() => {
    if (updateUser.isError) {
      showSnackBar('User update failed', "error");
      setTimeout(() => {
        props.afterFinish();
        props?.refetch();
      }, 2000);
    }
  }, [updateUser.isError])

  React.useEffect(() => {
    if (createUser.isError) {
      showSnackBar('User creation failed', "error");
      setTimeout(() => {
        props.afterFinish();
        props?.refetch();
      }, 2000);
    }
  }, [createUser.isError])

  React.useEffect(() => {
    if (createUser.isSuccess) {
      showSnackBar('User created Successfully', "success");
      setTimeout(() => {
        props.afterFinish();
        props?.refetch();
      }, 2000);
    }
  }, [createUser.isSuccess]);


  useEffect(() => {
    const checkIfUserNameExists = async (userName) => {
      if (!userName) {
        return;
      }
      try {
        const isuserExist = await usernameExists(userName, providerId, user?.userId);
        console.log("ifexist", isuserExist);
        if (isuserExist?.message == true) {
          setUserExists(true);
          setUserNameState("error");
        } else {
          console.log("not found user");
          setUserExists(false);
          setUserNameState("success");
        }
      } catch (error) {
        console.log("error checking userexists");
      }
    };

    checkIfUserNameExists(userName);
  }, [userName]);

  const onChange = (currentNode, selectedNodes) => {
    let selectedCats = selectedNodes.map((x) => x.id).join();
    setUser({ ...user, ["topics"]: selectedCats });
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = () => {
    // let userDataObj = {};
    // userDataObj.users = user;
    // userDataObj.prevSelectedId = prevOptionsId;

    if (firstNameState === "error" || userName === "error" || lastNameState === "error" || mobileState == "error" || passwordState == "error" || emailState == "error" || userExists == true) {
      showSnackBar('Please complete the required fields.', "warning");
      console.log("check all fields please");
      return;
    }

    const userData = {
      firstName,
      lastName,
      middleName,
      mobile,
      email,
      address,
      userName,
      passCode: password,
    };


    let userDataObj = {};
    userDataObj.users = user;

    userDataObj.users.firstName = firstName;
    userDataObj.users.lastName = lastName;
    userDataObj.users.middleName = middleName;
    userDataObj.users.mobile = mobile;
    userDataObj.users.email = email;
    userDataObj.users.address = address;
    userDataObj.users.userName = userName;
    userDataObj.users.passCode = password;


    if ((prevOptionsId && prevOptionsId > 0)) {
      userDataObj.prevSelectedId = prevOptionsId;
    } else {
      showSnackBar('Please select the role.', "warning");
      console.log("No role selected");
      return;
    }
    console.log(user, userData, userDataObj, 'userId');

    if (user.userId) {
      console.log(userDataObj, 'inHere')
      updateUser.mutate(userDataObj)
    } else {
      console.log('inHere1')
      createUser.mutate(userDataObj)
    };
    //getQ();
  }

  console.log(user, 'updatedUsers')
  return (
    <div>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <props.icon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {user && (
            <form autoComplete="off">
              <GridContainer justifyContent="center">

                {/* <GridItem xs={12} sm={12} md={8} style={{ marginTop: 36 }}>
                  {categoryTree && props.userType && props.userType == 2 && (
                    <TreeDropDown
                      data={categoryTree}
                      onChange={onChange}
                      className="mdl-demo"
                    />
                  )}
                </GridItem> */}
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    success={firstNameState === "success"}
                    error={firstNameState === "error"}
                    labelText={<span>First Name</span>}
                    id="firstName"
                    // type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    inputProps={{
                      // type: "datetime-local",
                      name: "firstName",
                      //value: user.firstName,
                      value: firstName,
                      onChange: (event) => {
                        if (!verifyLength(event.target.value, 3)) {
                          setFirstNameState("error");
                        } else {
                          setFirstNameState("success");
                        }
                        setFirstName(event.target.value);
                      }
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText={<span>Middle Name</span>}
                    id="middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "middleName",
                      onChange: handleChange,
                      // value: user.middleName,
                      value: middleName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    success={lastNameState === "success"}
                    error={lastNameState === "error"}
                    labelText={<span>Last Name<small>(required)</small></span>}
                    id="lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    inputProps={{
                      name: "lastName",
                      // value: user.lastName,
                      value: lastName,
                      onChange: (event) => {
                        if (!verifyLength(event.target.value, 3)) {
                          setLastNameState("error");
                        } else {
                          setLastNameState("success");
                        }
                        setLastName(event.target.value);
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    success={mobileState === "success"}
                    error={mobileState === "error"}
                    labelText={<span>Mobile<small>(required)</small></span>}
                    id="mobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    inputProps={{
                      name: "mobile",
                      // value: user.mobile,
                      value: mobile,

                      onChange: (event) => {
                        if (!verifyMobileLength(event.target.value, 10)) {
                          setMobileState("error");
                        } else {
                          setMobileState("success");
                        }
                        setMobile(event.target.value);
                      },
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    success={emailState === "success"}
                    error={emailState === "error"}
                    labelText={<span>Email<small>(required)</small></span>}
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    inputProps={{
                      name: "email",
                      // value: user.email,
                      value: email,
                      type: "email",
                      onChange: (event) => {
                        if (!verifyEmail(event.target.value)) {
                          setEmailState("error");
                        } else {
                          setEmailState("success");
                        }
                        setEmail(event.target.value);
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={<span>Address</span>}
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "address",
                      onChange: handleChange,
                      // value: user.address,
                      value: user.address,

                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    name="roles"
                    label="Roles *"
                    options={prevOptions}
                    setvalue={(v) =>
                      setprevOptionsId(v)
                    }
                    value={prevOptionsId}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    success={userNameState === "success"}
                    error={userNameState === "error" || userExists}
                    labelText={<span>User Name</span>}
                    id="userName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    helperText={userExists == true ? "User Name Exists" : ""}
                    inputProps={{
                      name: "userName",
                      onChange: (event) => {
                        if (!verifyEmail(event.target.value)) {
                          setUserNameState("error");
                        } else {
                          setUserNameState("success");
                        }
                        setUserName(event.target.value);
                      },
                      // onBlur: (v) => checkIfUserNameExists(v),
                      // value: user.userName,
                      value: userName,

                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    success={passwordState === "success"}
                    error={passwordState === "error"}
                    labelText={<span>Password<small>(required)</small></span>}
                    id="passCode"
                    required={true}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "passCode",
                      value: password,
                      type: showPassword ? "text" : "password",
                      onChange: (event) => {
                        if (!verifyPassword(event.target.value)) {
                          setPasswordState("error");
                        } else {
                          setPasswordState("success");
                        }
                        setPassword(event.target.value);
                      },
                      endAdornment: (
                        <InputAdornment position="end" >
                          <IconButton onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={passwordState ? "Password must include special characters,uppercase and lowercase letters,number and meet required length." : ''}

                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    color="primary"
                    onClick={props.afterFinish}
                    style={{ marginTop: 20, marginRight: 20, textTransform: 'none' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: 20, textTransform: 'none' }}
                    disabled={
                      updateUser.isLoading ||
                      createUser.isLoading ||
                      (userName == "" || userName == null) ||
                      (firstName == "" || firstName == null) ||
                      (lastName == "" || lastName == null) ||
                      (email == "" || email == null) ||
                      (password == "" || password == null)
                    }
                  >
                    {props?.user?.userId
                      ? updateUser.isLoading
                        ? "Updating..."
                        : "Update"
                      : createUser?.isLoading ? "Adding..." : "Add"}
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          )}
        </CardBody>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Card>

    </div >
  );
}

export default User;
