import axios from "axios";
import { LoginContext } from "contexts/LoginContext";
import CryptoJS from 'crypto-js';
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useUpdateStudentDetails() {

  const { config } = useContext(LoginContext);
  const key = 'pj23vs7nycq18uew';
  let secretKey = CryptoJS.enc.Utf8.parse(key);

  const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }
  const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
  }
  const queryClient = useQueryClient();
  return useMutation(
    async (student) => {
      // if (!user.address) userPayload.address = " ";
      // if (!user.middleName) userPayload.middleName = " ";

      // if (!user.passCode) userPayload.passCode = "test";
      console.log(student, 'propsfromstudent');
      student.studetnInfo.pinCode = student.studetnInfo.pinCode == "" ? null : student.studetnInfo.pinCode;
      student.studetnInfo.passCode = encryptPassword(student.studetnInfo.passCode, secretKey)

      await axios.put(`${config.api}/api/User/updateStudentDetails`, student);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("students");
      },
    }
  );
}
