import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { configUrl } from "config";
import { LoginContext } from "contexts/LoginContext";
import { useContext, useEffect, useRef, useState } from "react";
import providerResourceMapping from "services/ProviderServices/providerResourceMapping";
const useStyles = makeStyles((theme) => ({
  checkboxAndInput: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    marginRight: theme.spacing(2),
  },
  input: {
    width: "80px",
  },
}));

var styles = {
  // ...dashboardStyle,
  // ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const AggregatorResorceListing = ({ providerId, afterFinish }) => {
  const classes = useStyles(styles);
  let ContentFilter = 0;
  const { user } = useContext(LoginContext);
  const [testSeries, setTestSeries] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [studentCountState, setStudentCountState] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState({});
  const pageSize = 10; // Number of items per page
  const observer = useRef();

  // Fetch existing mappings when the form opens
  useEffect(() => {
    const fetchExistingMappings = async () => {
      try {
        const response = await axios.get(
          `${configUrl.AssessmentServer}/api/ProviderResourceMapping/${providerId}`
        );
        const mappings = response.data.$values || [];

        // Map the response to match the structure for selectedSeries
        const formattedMappings = mappings.reduce((acc, item) => {
          acc[item.testSeriesId] = {
            selected: true,
            testsCount: item.testSeriesStudentsCount || 0,
          };
          return acc;
        }, {});

        setSelectedSeries(formattedMappings);
      } catch (error) {
        console.error("Error fetching provider resource mapping:", error);
      }
    };

    fetchExistingMappings();
  }, [providerId]);

  // Fetch Test Series Data
  const fetchTestSeries = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${configUrl.AssessmentServer}/api/Courses?pageNumber=${pageNumber}&pageSize=${pageSize}&contentFilter=${ContentFilter}`
      );
      const data = response.data.$values || [];
      if (data.length > 0) {
        setTestSeries((prevSeries) => [...prevSeries, ...data]);
        setHasMore(data.length >= pageSize); // If data length is less than pageSize, no more data
      } else {
        setHasMore(false); // No more data
      }
    } catch (error) {
      console.error("Error fetching test series:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle Checkbox Change
  const handleCheckboxChange = (id) => {
    setSelectedSeries((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], selected: !prevState[id]?.selected },
    }));
  };

  // Handle Input Change
  const handleInputChange = (id, count) => {
    setSelectedSeries((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], testsCount: count },
    }));
  };

  // Handle Submit Button Click
  const handleSubmit = async () => {
    const selected = Object.entries(selectedSeries).reduce(
      (result, [id, data]) => {
        if (data.selected) {
          result.push({
            id: 0,
            providerId: providerId,
            testSeriesId: id, //testSeriesId: 0,
            testSeriesImage: null,
            testSeriesStudentsCount: parseInt(data.testsCount),
            testId: 0,
            testStudentsCount: 0,
            createdBy: user,
            createdDate: new Date().toISOString(),
            updatedBy: user, // Replace with actual updater data
            updatedDate: new Date().toISOString(),
          });
        }
        return result;
      },
      []
    );
    console.log(selected);
    const postResourceResp = await providerResourceMapping.providerResourceMapping(
      selected
    );
    if (postResourceResp.status == 200 || postResourceResp.status == 201) {
      afterFinish();
    }
    console.log(postResourceResp, "postResp");
  };

  // Load more test series when scrolling to the bottom
  useEffect(() => {
    fetchTestSeries(page);
  }, [page]);

  const lastTestSeriesRef = useRef();

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    observer.current = new IntersectionObserver(handleObserver, options);
    if (lastTestSeriesRef.current) {
      observer.current.observe(lastTestSeriesRef.current);
    }
  }, [loading, hasMore]);
  const verifyLength = (value, length) => {
    if (value <= length) {
      return false;
    }
    return true;
  };
  return (
    <Card style={{ width: "800px", margin: "10px" }}>
      <h3>Available student to assign : {`${100}`}</h3>
      <CardBody>
        <GridContainer>
          <Grid container spacing={2}>
            {testSeries.map((series, index) => (
              <Grid
                item
                xs={12}
                key={series.id}
                ref={index === testSeries.length - 1 ? lastTestSeriesRef : null}
              >
                <GridContainer>
                  <GridItem xs={12} sm={8}>
                    <Box className={classes.checkboxAndInput}>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={!!selectedSeries[series.id]?.selected}
                              onChange={() => handleCheckboxChange(series.id)}
                              checkedIcon={
                                <CheckBoxOutlinedIcon
                                  className={classes.checkedIcon}
                                />
                              }
                              icon={
                                <CheckBoxOutlineBlankOutlinedIcon
                                  className={classes.uncheckedIcon}
                                />
                              }
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          label={series.text}
                          className={{ label: classes.label }}
                        />
                      </div>
                    </Box>
                  </GridItem>

                  {/* <TextField
                  className={classes.input}
                  type="number"
                  placeholder="Tests Count"
                  min="0"
                  value={selectedSeries[series.id]?.testsCount || ""}
                  onChange={(e) => handleInputChange(series.id, e.target.value)}
                  disabled={!selectedSeries[series.id]?.selected}
                /> */}
                  <GridItem xs={12} sm={4}>
                    <CustomInput
                      success={studentCountState === "success"}
                      error={studentCountState === "error"}
                      labelText={
                        <span>
                          Assign Student Count<small>(required)</small>
                        </span>
                      }
                      id="assignStudentCount"
                      inputProps={{
                        name: "assignStudentCount",
                        type: "number",
                        min: 0,
                        value: selectedSeries[series.id]?.testsCount || "",
                        onChange: (event) => {
                          if (!verifyLength(event.target.value, 0)) {
                            setStudentCountState("error");
                          } else {
                            setStudentCountState("success");
                          }
                          handleInputChange(series.id, event.target.value);
                        },
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      disabled={!selectedSeries[series.id]?.selected}
                    />
                  </GridItem>
                </GridContainer>
                {/* <Divider /> */}
              </Grid>
            ))}
          </Grid>
          {loading && <p>Loading...</p>}
          {/* {!hasMore && <p>No more test series available</p>}
        <button onClick={handleSubmit}>Submit</button> */}
          {testSeries.length == 0 && <p>No TestSeries available</p>}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={8}></GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            align="right"
            marginbottom="5px"
            justify="flex-end"
          >
            <Button
              color="rose"
              onClick={handleSubmit}
              disabled={loading || testSeries.length == 0}
            >
              Save
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default AggregatorResorceListing;
