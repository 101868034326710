import React from "react";
// @material-ui/icons

import CodeIcon from "@material-ui/icons/Code";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import getObjectiveService from "services/ConfigObjective/getObjectiveService";
import getTargetAudience from "services/TargetAudience/getTargetAudience";
import isCodeandTextExist from "services/TestService/isCodeandTextExist";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornmentDisabled: {
    color: "#b2b8be !important",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const TestDetails = React.forwardRef((props, ref) => {

  const getObjectsndTargetAudience = async () => {
    try {
      let getOjbectives = await getObjectiveService.getObjective(true, true);
      setObjectiveData(getOjbectives['$values']);
      let getTargetAudiences = await getTargetAudience.getTargetAudience(true, true);
      setTargetAudienceData(getTargetAudiences['$values']);
    } catch (error) {
      console.log("error fetching objecitve and target", error);
    }

  }
  React.useEffect(() => {
    getObjectsndTargetAudience();
  }, []);

  const [getObjectiveData, setObjectiveData] = React.useState([]);
  const [getTargetAudienceData, setTargetAudienceData] = React.useState([]);

  const classes = useStyles();

  const [testCode, setTestCode] = React.useState(props.st.testCode);
  const [testCodeState, setTestCodeState] = React.useState("");

  const [text, settext] = React.useState(props.st.text);
  const [textState, settextState] = React.useState("");

  const [testId, setTestId] = React.useState(props?.st?.testId);
  const [objective, setObjective] = React.useState(isJSON(props.st.objective) ? JSON.parse(props.st.objective) : props.st.objective);
  const [targetAudience, setTargetAudience] = React.useState(isJSON(props.st.targetAudience) ? JSON.parse(props.st.targetAudience) : props.st.targetAudience);
  console.log(targetAudience, 'targetAudience')

  const [description, setDescription] = React.useState(props.st.description);
  const [welcomeMessage, setWelcomeMessage] = React.useState(
    props.st.welcomeMessage ?? 'Welcome');
  const [concludeMessage, setConcludeMessage] = React.useState(
    props.st.concludeMessage ?? 'Thank You!'
  );

  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  console.log(props, 'codeforNewandEdit')
  // TODO
  let isTestCodeExist;
  let isTestTextExist;
  const isTestCodeExists = async () => {
    isTestCodeExist = await isCodeandTextExist.dupulicateCodeCheck(0, testCode);
    isTestTextExist = await isCodeandTextExist.dupulicateTextCheck(0, text);

    if (testId) {
      isTestCodeExist = await isCodeandTextExist.dupulicateCodeCheck(testId, testCode);
      isTestTextExist = await isCodeandTextExist.dupulicateTextCheck(testId, text);
    }
    console.log(isTestCodeExist, 'isCodeExist', 'validornot ', isTestCodeExist == false)

    if (isTestCodeExist == true) {
      setTestCodeState("error");
      console.log('got error')
    } else {
      console.log('not error')
    }
    if (isTestTextExist == true) {
      settextState("error");
      console.log('got error')
    } else {
      console.log('not error')
    }

  }
  React.useEffect(() => {
    if (props?.st?.testId) {
      setTestId(props?.st?.testId);
    }
  }, [props]);
  React.useEffect(() => {
    if (props.st.testCode == undefined) {
      isTestCodeExists();
    }
    if (props?.st?.testId) {
      isTestCodeExists();
    }
    if (!verifyLength(testCode, 1)) {
      setTestCodeState("error");
    } else {
      setTestCodeState("success");
    }

    if (!verifyLength(text, 1)) {
      settextState("error");
    } else {
      settextState("success");
    }
  }, [testCode, text]);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      testCode,
      testCodeState,
      text,
      textState,
      objective,
      targetAudience,
      type: "details",
      description,
      welcomeMessage,
      concludeMessage,
    },

  }));
  const sendState = () => {
    return {
      testCode,
      testCodeState,
      text,
      textState,
      objective,
      targetAudience,
      type: "details",
      description,
      welcomeMessage,
      concludeMessage,
    }
  };
  // function that returns true if value is email, false otherwise
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (!value) return false;
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const isValidated = () => {
    if (
      testCodeState === "success" &&
      textState === "success"
    ) {
      return true;
    } else {
      if (testCodeState !== "success") {
        setTestCodeState("error");
      }
      if (textState !== "success") {
        settextState("error");
      }
    }
    return false;
  };


  return (
    <GridContainer justify="center">

      <GridItem xs={12} sm={12} md={5}>
        <CustomInput
          success={props.st.testCode != undefined || props?.st?.testId ? testCodeState === "" : testCodeState === "success"}
          error={testCodeState === "error"}
          labelProps={{
            shrink: testCode,
          }}
          labelText={
            <span>
              Test Code <small>(required)</small>
            </span>
          }
          id="testCode"
          formControlProps={{
            fullWidth: true,
          }}
          //disabled={props.st.testCode != undefined || props?.st?.testId}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                setTestCodeState("error");
              } else {
                setTestCodeState("success");
              }
              if (event.target.value?.length <= 40) {
                setTestCode(event.target.value.trim());
              }
            },
            maxLength: 20,
            value: testCode,
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <CodeIcon className={props.st.testCode != undefined || props?.st?.testId ? classes.inputAdornmentDisabled : classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
        <small style={{ color: 'red' }}>
          {
            testCodeState == 'error' && testCode ?
              'Test code already present' : ''
          }
        </small>
      </GridItem>
      <GridItem xs={12} sm={12} md={5}>
        <CustomInput
          success={props.st.testCode != undefined || props?.st?.testId ? textState === "" : textState === "success"}
          error={textState === "error"}
          labelText={
            <span>
              Test Name <small>(required)</small>
            </span>
          }
          labelProps={{
            shrink: text,
          }}
          id="testText"
          formControlProps={{
            fullWidth: true,
          }}
          //disabled={props.st.testCode != undefined || props?.st?.testId}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                settextState("error");
              } else {
                settextState("success");
              }
              settext(event.target.value.trimStart());
            },
            value: text,
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <PermIdentityIcon className={props.st.testCode != undefined || props?.st?.testId ? classes.inputAdornmentDisabled : classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
        <small style={{ color: 'red' }}>
          {
            textState == 'error' && text ?
              'Test name already present' : ''
          }
        </small>
      </GridItem>

      <GridItem xs={12} sm={12} md={3} style={{ marginTop: '54px' }}>
        <CustomSelect
          label="Objective"
          selectLabel="Assp"
          options={getObjectiveData && getObjectiveData.map((x) => ({
            id: x.id,
            name: x.valueString
          }))}
          showNone={false}
          showNotConf={true}
          setvalue={(v) => {
            setObjective(v)
          }}
          id="objective"
          name="objective"
          value={objective}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={3} style={{ marginTop: '54px' }}>
        <CustomSelect
          label=" Target Audience"
          selectLabel="Assp"
          options={getTargetAudienceData && getTargetAudienceData.map((x) => ({
            id: x.id,
            name: x.valueString
          }))}
          showNone={false}
          showNotConf={true}
          setvalue={(v) => {
            setTargetAudience(v)
          }}
          id="targetAudience"
          name="targetAudience"
          value={targetAudience}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={4}>
        <CustomInput
          success={true}
          labelText={<span>Description</span>}
          labelProps={{
            shrink: description,
          }}
          id="email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              setDescription(event.target.value);
            },
            value: description,
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <FormatAlignJustifyIcon
                  className={classes.inputAdornmentIcon}
                />
              </InputAdornment>
            ),
            rows: 3,
            multiline: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={5}>
        <CustomInput
          success={welcomeMessage}
          //error={!welcomeMessage}
          labelText={
            <span>
              Welcome Message
            </span>
          }
          labelProps={{
            shrink: welcomeMessage,
          }}
          id="email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              setWelcomeMessage(event.target.value);
            },
            value: welcomeMessage,
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <FormatAlignJustifyIcon
                  className={classes.inputAdornmentIcon}
                />
              </InputAdornment>
            ),
            rows: 3,
            multiline: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={5}>
        <CustomInput
          success={concludeMessage}
          //error={!concludeMessage}
          labelText={<span>Conclude Message</span>}
          labelProps={{
            shrink: concludeMessage,
          }}
          id="email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              setConcludeMessage(event.target.value);
            },
            value: concludeMessage,
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <FormatAlignJustifyIcon
                  className={classes.inputAdornmentIcon}
                />
              </InputAdornment>
            ),
            rows: 3,
            multiline: true,
          }}
        />
      </GridItem>
    </GridContainer>
  );
});

TestDetails.displayName = "Step1";

export default TestDetails;
