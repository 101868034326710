import axios from "axios";
import { configUrl } from "config";
import { useMutation, useQueryClient } from "react-query";

const updateUserSubscription = async ({ userId, courseId, subscriptionStatus }) => {
    const response = await axios.post(`${configUrl.AssessmentServer}/api/CourseUserMapping/userSubscription/${userId}/${courseId}/${subscriptionStatus}`);
    return response.data;
};

const useUpdateUserSubscription = () => {
    const queryClient = useQueryClient();

    return useMutation(updateUserSubscription, {
        onSuccess: () => {
            queryClient.invalidateQueries("courseUserMapping");
        },
    });
};

export default useUpdateUserSubscription;
