import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
// import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress, FormControlLabel } from "@material-ui/core";
import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import useGetAllCity from "hooks/configurationsHooks/useGetAllCity";
import useGetAllClass from "hooks/configurationsHooks/useGetAllClass";
import useGetAllEduBoard from "hooks/configurationsHooks/useGetAllEduBoard";
import useGetAllNativeLanguage from "hooks/configurationsHooks/useGetAllNativeLanguage";
import useGetAllState from "hooks/configurationsHooks/useGetAllState";
import useCreateUser from "hooks/userHooks/useCreateUser";
import useGetUserByUserName from "hooks/userHooks/useGetUserByUserName";
import useUpdateStudentDetails from "hooks/userHooks/useUpdateStudentDetails";
import React from "react";
// import useGetAllCity from "hooks/configurationsHooks/useGetAllCity";
import { InputLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MuiAlert from "@material-ui/lab/Alert";
import Selectstyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import useGetAllEducation from "hooks/configurationsHooks/useGetAllEducation";
import useGetAllProfession from "hooks/configurationsHooks/useGetAllProfession";
import useGetAllInterests from "hooks/interestsHooks/useGetAllInterests";
import useCreateUserRegiste from "hooks/userHooks/useCreateUserRegiste";
import useGetAllUsers from "hooks/userHooks/useGetAllUsers";
import useGetStudentById from "hooks/userHooks/useGetStudentById";
import SweetAlert from "react-bootstrap-sweetalert";
import getStudentDetailsByEmail from "services/ApplicationUser/getStudentDetailsByEmail";

// import InputAdornment from "@material-ui/core/InputAdornment";
const styles = {
  ...Selectstyles,
  ...sweetAlertStyle
};
const useStyles = makeStyles(styles);

// import Button from '@material-ui/core/Button';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function StudentRegister(props) {
  const classes = useStyles();
  // const [user, setUser] = React.useState(props.user);
  const [allusers, setAllUsers] = React.useState(props.user);
  console.log("user..", allusers)
  const { data: categories } = useGetCategories();
  const [Interest, setInterest] = React.useState([]);
  console.log(Interest)
  const [interestList, setInterestInfo] = React.useState([]);
  // console.log(inter)
  const [error1, setError1] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('')
  const [states, setStats] = React.useState([]);
  const [citys, setCitys] = React.useState([]);
  const [languages, setLanguage] = React.useState([]);
  const [educationbord, setEducattionBord] = React.useState([]);
  const [clas, setClas] = React.useState([]);
  const [professions, setProfessions] = React.useState([]);
  const [alleducation, setAllEducation] = React.useState([]);
  const [stateValueInt, setStateValueInt] = React.useState('');
  const [flag, setFlag] = React.useState(false);
  const [gender, setGender] = React.useState(0);

  const { data: statedata } = useGetAllState(props.state, true);
  const { data: getcity } = useGetAllCity(props.city, true)
  console.log(getcity)
  getcity
  const { data: getlanguage } = useGetAllNativeLanguage(props.language, true)
  const { data: geteduboard } = useGetAllEduBoard(props.EduBoard, true)
  const { data: getclass } = useGetAllClass(props.class, true)
  const { data: getprofession } = useGetAllProfession(props.profession, true)
  const { data: getalleducation } = useGetAllEducation(props.Education, true)
  console.log(getprofession)
  console.log(stateValueInt)
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError1(false)
  };


  const showErrorSnackBar = () => {
    setError1(true);
  }
  console.log("props", props);

  const updateStudentDetails = useUpdateStudentDetails();
  console.log(updateStudentDetails, 'updateStudentDetails')
  const createUser = useCreateUser();
  const createstudenreg = useCreateUserRegiste();
  console.log(createUser)
  console.log(createstudenreg)
  const {
    data: userDetails,
    isLoading,
    mutate: getUserDetails,
    // error: userFetchError,
  } = useGetUserByUserName();
  const {
    data: interests,
    error: interestsFetchError,
    isLoading: interestsFetchLoading,
  } = useGetAllInterests();
  const {
    data: users,
    error: userFetchError,
    isLoading: userFetchLoading,
  } = useGetAllUsers();
  console.log(users)
  // TODO
  const { data: getStudentDetail, isLoading: studentFetchLoading } = useGetStudentById(props.row)
  console.log(studentFetchLoading, "loading");

  function formatDate(d) {
    if (d != null) {
      const date = new Date(d);
      const futureDate = date.getDate();
      date.setDate(futureDate);
      const defaultValue = date.toLocaleDateString('en-CA');
      return defaultValue;
    }
  }
  React.useEffect(async () => {
    if (getStudentDetail) {

      await getStudentDetail
      let st = getStudentDetail.studetnInfo
      // let c=
      setStudentInfo({ ...st, fatherEmail: st.fatherEmail == null ? "" : st.fatherEmail, fatherMobile: st.fatherMobile == null ? "" : st.fatherMobile, dob: formatDate(st.dob), confirm: st.passCode })
      setEducationInfo(getStudentDetail.educationInfo)
      // let interest= await getStudentDetail.intrestList["$values"]
      console.log(getStudentDetail.intrestList["$values"])
      setInterestInfo(getStudentDetail.intrestList["$values"]);
      let sib = getStudentDetail.siblingDetailsList["$values"][0]
      if (sib != undefined)
        setSiblingDetails({ ...sib, dob: formatDate(sib.dob) });

      // setSiblingDetails(getStudentDetail)
    }

  }, [getStudentDetail]);
  React.useEffect(() => {
    if (stateValueInt) {
      console.log(stateValueInt)
    }
  }, [stateValueInt])

  React.useEffect(() => {
    if (statedata) {
      setStats(
        statedata.filter(o => o?.isActive)
          .map((x, i) => {
            // console.log(x)
            if (x?.isDefault) {
              setStudentInfo(studentinfo.state = x?.valueInt)
            }
            return { ...x };
          })
      );
    }
  }, [statedata]);

  React.useEffect(() => {
    if (getcity) {
      setCitys(
        getcity.filter(o => o?.isActive)
          .map((x, i) => {
            if (x?.isDefault) {
              setStudentInfo(studentinfo.city = x?.valueInt)
            }
            return { ...x };
          })
      );
    }
  }, [getcity]);
  React.useEffect(() => {
    console.log(getlanguage, "getlanguage Error")
    if (getlanguage) {
      setLanguage(
        getlanguage?.filter(o => o?.isActive)
          .map((x, i) => {
            if (x?.isDefault) {
              console.log(x?.valueInt, "lang Error", eductioninfo)
              // setEducationInfo({ ...eductioninfo.language = x?.valueInt })
              setEducationInfo(prevState => ({ ...prevState, language: x?.valueInt }));
            }
            return { ...x };
          })
      );
    }
  }, [getlanguage]);
  React.useEffect(() => {
    if (geteduboard) {
      setEducattionBord(
        geteduboard.filter(o => o?.isActive)
          .map((x, i) => {
            // console.log(x)
            if (x?.isDefault) {
              setEducationInfo(eductioninfo.board = x?.valueInt)
            }
            return { ...x };
          })
      );
    }
  }, [geteduboard]);
  React.useEffect(() => {
    if (getclass) {
      setClas(
        getclass.filter(o => o?.isActive)
          .map((x, i) => {
            // console.log("x...",x)
            if (x?.isDefault) {
              console.log("x...", x?.valueInt)
              setEducationInfo({ ...eductioninfo, class: x?.valueInt })
            }
            return { ...x };
          })
      );
    }
  }, [getclass]);
  React.useEffect(() => {
    if (getprofession) {
      setProfessions(
        getprofession.filter(o => o?.isActive)
          .map((x, i) => {
            // console.log(x)
            if (x?.isDefault) {
              setStudentInfo({ ...studentinfo, "fatherProfession": x?.valueInt })
            }
            return { ...x };
          })
      );
    }
  }, [getprofession]);

  React.useEffect(() => {
    if (getalleducation) {
      setAllEducation(
        getalleducation.filter(o => o?.isActive)
          .map((x, i) => {
            // console.log(x)
            if (x?.isDefault) {
              setSiblingDetails({ ...siblingDetails, education: x?.valueInt })
            }
            return { ...x };
          })
      );
    }
  }, [getalleducation]);

  React.useEffect(() => {
    if (users) {
      setAllUsers(
        users["$values"]
          // .filter((x) => x.userTypeId == 4)
          .map((x, i) => {
            return { ...x, id: i };
          })
      );
    }
  }, [users]);
  React.useEffect(() => {
    if (interests) {
      setInterest(
        interests["$values"]
          .map((x, i) => {
            return { ...x, id: i };
          })
      );
    }
  }, [interests]);
  const [studentinfo, setStudentInfo] = React.useState({
    userId: 0,
    providerId: 0,
    gender: 0,
    userName: "",
    passCode: "",
    confirm: "",
    fullName: "",
    fatherName: "",
    fatherMobile: "",
    fatherEmail: "",
    fatherProfession: 0,
    address: "",
    state: null,
    city: null,
    pinCode: "",
    mobile: "",
    email: "",
    dob: "",
    profilePicture: "",
    isActive: true,
    createdBy: "",
    createdDate: new Date(),
    updatedBy: "",
    updatedDate: new Date(),
    topics: "",
    userTypeId: 0,
  })
  // props.func(studentinfo.state=1)
  console.log(studentinfo, 'studentInfo234')
  const [eductioninfo, setEducationInfo] = React.useState({
    educationId: 0,
    userId: 0,
    language: 0,
    board: 0,
    class: 0,
    createdBy: "",
    createdDate: new Date(),
    updatedBy: "",
    updatedDate: new Date()
  },
  );
  const [siblingDetails, setSiblingDetails] = React.useState(
    {
      siblingId: 0,
      userId: 0,
      siblingName: "",
      schoolCollege: "",
      moblie: "",
      email: "",
      dob: "",
      education: 0,
      createdBy: "",
      createdDate: new Date(),
      updatedBy: "",
      updatedDate: new Date()
    })
  let templateTypes = [
    { id: +91, name: "india(+91)" },
    { id: 672, name: "Antarctica(672)" },
    { id: 1, name: "Canada(1)" },
    { id: 86, name: "China(86)" },
    { id: 57, name: "Colombia(57)" }
  ];
  const [countryId, setCoutryId] = React.useState('');
  console.log(countryId.countryId)
  const [mobilenoExists, setMobilenoExists] = React.useState(false);
  const [emailExists, setEmailExists] = React.useState(false);
  const [emailValidate, setEmailValidate] = React.useState(false);
  const [mobilelegth, setMobilelength] = React.useState(false);
  const [isStudentEixst, setIsStudentExist] = React.useState(false);
  const [intr, setIntr] = React.useState([]);
  // const handleClickShowPassword = () => {
  //   setStudentInfo({ ...studentinfo, showPassword: !studentinfo.showPassword });
  // };

  const [passwordType, setPasswordType] = React.useState("password");
  const [passwordTypeConf, setPasswordTypeConf] = React.useState("password");
  const [showAlert, setShowAlert] = React.useState(null)
  const [alert, setAlert] = React.useState(null);

  // const [passwordCo, setPasswordType] = React.useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    else {
      setPasswordType("password")
      return;
    }
  }
  const togglePasswordConf = () => {
    if (passwordTypeConf === "password") {
      setPasswordTypeConf("text")
      return;
    }
    else {
      setPasswordTypeConf("password")
      return;
    }
  }

  console.log(studentinfo)
  const handleeducationChange = (e) => {
    console.log(e.target.value)
    console.log(e)

    const { name, value } = e.target;
    setEducationInfo({ ...eductioninfo, [name]: value })
  }
  // const [age, setAge] = React.useState('');

  // const handleChanged = (event) => {
  //   console.log(event.target.value)
  //   setAge(event.target.value);
  // };
  const handlesiblingchange = (e) => {
    console.log(e.target.value)
    const { name, value } = e.target;
    setSiblingDetails({ ...siblingDetails, [name]: value })
  }

  // const [selected, setSelected] = React.useState([]);

  console.log(interestList)
  let d = []
  Interest.map((a) => {
    d.push(a.interestId)
  })
  const isAllSelected =
    d.length > 0 && interestList.length === d.length;
  const onhandlecheckChange = (event) => {
    const value = event.target.value;
    let c = []
    Interest.map((a) => {
      c.push(a.interestId)
    })
    console.log(value);
    if (event.target.checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (value === "all") {
        setInterestInfo(interestList.length === c.length ? [] : c);
        return;
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setInterestInfo([...interestList, parseInt(value)]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (value === "all") {
        setInterestInfo([]);
      } else {

        setInterestInfo(interestList.filter(el => el !== parseInt(value)));

      }
    }

  };

  React.useEffect(() => {
    if (updateStudentDetails.isSuccess) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/testhome"}
          severity="success"
          alertMessage={'User updated Successfully'}
        />
      )
      // setLoading(false);
      setTimeout(() => {
        props.afterFinish();
      }, 2000);
      // setTimeout(() => {props.afterFinish(); 2000});

    }
  }, [updateStudentDetails.isSuccess]);

  React.useEffect(() => {
    if (createstudenreg.isSuccess) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="success"
          alertMessage={'Student registered successfully'}
        />
      )
      setTimeout(() => {
        props.afterFinish();
      }, 2000);
    }
    // setTimeout(() => {props.afterFinish(); 2000});
  }, [createstudenreg.isSuccess]);

  React.useEffect(() => {
    if (createstudenreg.isError) {
      console.log(createstudenreg?.error?.response?.data, 'error vals')
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage={createstudenreg?.error?.response?.data ?? 'Please fill all imformation...'}
        />
      )
    }
  }, [createstudenreg.isError]);

  React.useEffect(() => {
    if (updateStudentDetails.isError) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage={'User update failed'}
        />
      )
    }
  }, [updateStudentDetails.isError]);

  console.log(studentinfo.mobile)
  let mo = allusers.find(e => e.mobile === studentinfo.mobile)
  console.log(mo)
  let ema = allusers.find(e => e.email === studentinfo.email)

  // let molength = studentinfo.mobile;
  // console.log(molength)
  const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  // let a=emailRegex.test(studentinfo.fatherEmail)

  setEmailValidate
  React.useEffect(() => {
    // console.log()

    if (emailRegex.test(studentinfo.fatherEmail)) {
      setEmailValidate(false);
      return;
    }
    else {
      setEmailValidate(true);
      return;
    }
  }, [emailRegex]);
  React.useEffect(() => {
    // console.log()

    if (emailRegex.test(studentinfo.fatherEmail)) {
      setEmailValidate(false);
      return;
    }
    else {
      setEmailValidate(true);
      return;
    }
  }, [emailRegex]);
  React.useEffect(() => {
    console.log()
    if (mo) {
      if (mo.userId == studentinfo.userId) {
        setMobilenoExists(false);
      }
      else {
        setMobilenoExists(true);
      }
      return;
    }
    else {
      setMobilenoExists(false);
      return;
    }



  }, [mo]);
  React.useEffect(() => {
    console.log()

    if (ema) {
      if (ema.userId == studentinfo.userId) {
        setEmailExists(false);
      }
      else {
        setEmailExists(true);
      }
      return;
    }
    else {
      setEmailExists(false);
      return;
    }
  }, [ema]);
  React.useEffect(() => {
    if (error1) {
      // setErrorMessage()
      showErrorSnackBar()
    }
  }, [error1]);
  // React.useEffect(() => {
  //   // if(validationStudent)
  //   // {
  //     // setErrorMessage()
  //     // showErrorSnackBar()
  //     setValidationStudent(validationStudent)

  // }, [validationStudent]);
  const [validationStudent, setValidationStudent] = React.useState(
    //   {
    //   fullName: '',
    //   mobile:"",
    //   email:"",
    //   passCode:"",
    //   confirm:"",
    //   fatherName: "",
    //   fatherMobile: "",
    //   fatherEmail: "",
    //   siblingName: "",
    //   siblingMobile:"",
    //   siblingEmail:"",

    //   // lName: '',
    //   // email: '',
    //   // password: '',
    //   // confirmPassword: '',
    // }
  );
  const [invalidDOB, setInvalidDOB] = React.useState('')
  // let res = /^[a-zA-Z\s]*$/;
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  var reco = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  let res = /^[a-zA-Z\s]*$/;
  const strongRegex = new RegExp(/^[0-9a-zA-Z-]*$/);
  // const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  const postalCodeRegex = new RegExp(/^.{1,5}$/);
  const mobileRegex = new RegExp(/^.{10,12}$/);
  const siblingRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  var IndNum = /^[6-9]\d{9}$/gi;
  let regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  const validPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])/;
  const regexExpMO = /^[6-9]\d{9}$/gi;
  const regexExpMOFA = /^[6-9]\d{9}$/gi;
  const regexExpMOSI = /^[6-9]\d{9}$/gi;

  const isUserExist = async (email) => {
    let studentResp = await getStudentDetailsByEmail.getStudentDetailByEmail(email)

    return studentResp;
  }
  const hideAlert = async () => {
    setAlert(null);
  };


  const verifyEmail = async (e) => {
    console.log(studentinfo.email, 'emaillength')
    if (studentinfo.email.length > 0 && props.row == null) {
      console.log('logged')
      let studentInfo = await isUserExist(studentinfo.email);

      console.log(typeof (studentInfo) == 'object', studentInfo, 'vlas')
      if (studentInfo.status == 400) {
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="warning"
            alertMessage={studentInfo?.messege}
          />
        )
      } else {
        console.log('not registred')
      }
      if (studentInfo.status == 200) {
        setIsStudentExist(true)
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title=""
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >
            This user is already registered in the application, relevant information will be populated automatically.
            <br />
            Please confirm the user is same and add.
          </SweetAlert>
        );
        console.log(studentInfo?.data?.studetnInfo?.passCode, 'studentInfo1')
        studentinfo.fullName = studentInfo?.data?.studetnInfo?.fullName;
        studentinfo.mobile = studentInfo?.data?.studetnInfo?.mobile;
        studentinfo.gender = studentInfo?.data?.studetnInfo?.gender;
        studentinfo.dob = formatDate(studentInfo?.data?.studetnInfo?.dob);
        studentinfo.address = studentInfo?.data?.studetnInfo?.address;
        // studentinfo.city = studentInfo?.data?.studetnInfo?.city;
        // studentinfo.state = studentInfo?.data?.studetnInfo?.state;
        studentinfo.pinCode = studentInfo?.data?.studetnInfo?.pinCode;
        studentinfo.passCode = studentInfo?.data?.studetnInfo?.passCode;
        studentinfo.confirm = studentInfo?.data?.studetnInfo?.passCode;
        // Parent info
        studentinfo.fatherName = studentInfo?.data?.studetnInfo?.fatherName;
        studentinfo.fatherMobile = studentInfo?.data?.studetnInfo?.fatherMobile;
        studentinfo.fatherEmail = studentInfo?.data?.studetnInfo?.fatherEmail;
        // studentinfo.fatherProfession = studentInfo?.data?.studetnInfo?.fatherProfession;

        // education info
        // eductioninfo.board = studentInfo?.data?.educationInfo?.board;
        // eductioninfo.class = studentInfo?.data?.educationInfo?.class;
        // eductioninfo.language = studentInfo?.data?.educationInfo?.language;
        // eductioninfo.educationId = studentInfo?.data?.educationInfo?.educationId;
        // intrest info
        console.log(studentInfo?.data?.intrestList, 'intrests123')
        // if (studentInfo?.data?.intrestList) {
        //   setInterestInfo(studentInfo?.data?.intrestList["$values"]);
        // }
        // 

      } else {
        setIsStudentExist(false)
        studentinfo.fullName = "";
        studentinfo.mobile = "";
        studentinfo.dob = "";
        studentInfo.gender = null;
        studentinfo.address = "";
        studentinfo.city = null;
        studentinfo.state = null;
        studentinfo.pinCode = null;
        studentinfo.passCode = "";
        studentinfo.confirm = "";
        // Parent info
        studentinfo.fatherName = "";
        studentinfo.fatherMobile = "";
        studentinfo.fatherEmail = "";
        studentinfo.fatherProfession = 0;

        // education info
        eductioninfo.board = null;
        eductioninfo.class = null;
        eductioninfo.language = null;
        eductioninfo.educationId = 0;
        // intrest info
        setInterestInfo([]);
      }
    }
  }

  const handleChange = async (e) => {
    e.preventDefault();
    console.log(e.target.value)
    console.log(e, "errorp")
    // alert('is onChange AAs')
    // setPasswordInput(evnt.target.value);
    const { name, value } = e.target;
    let errors = validationStudent;

    if (name == 'dob') {
      // Get today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const selectedDateTime = new Date(value);
      if (selectedDateTime > today) {
        setInvalidDOB(true);
        // If date is in the future, clear the input field
        // setShowAlert(
        //   <CustomAlert
        //     open={true}
        //     openFlag={() => setFlag(flag ? false : true)}
        //     severity="error"
        //     alertMessage={'Future date is not valid'}
        //   />
        // )
        // return;
      } else {
        setInvalidDOB(false);
      }
    }

    // switch (name) {
    //   case 'fullName': 
    //     errors.fullName = res.test(value)
    //         ? 'Please Enter Full Name And Only Charecter.'
    //         : '';
    //     break;
    //   case 'email': 
    //     errors.email = 
    //     validEmailRegex.test(value)
    //         ? ''
    //         : 'Email is not valid!';
    //     break;
    //   case 'mobile': 
    //     errors.mobile = 
    //     IndNum.test(value)
    //         ? ''
    //         : 'Email is not valid!';
    //     break;
    //   case 'passCode': 
    //     errors.passCode = 
    //     re.test(value)
    //         ? 'Password must be 8 characters long!.'
    //         : '';
    //     break;
    //   // case 'confirm': 
    //   //   errors.confirm = 
    //   //   reco.test(value)
    //   //       ? 'Confirm password must be 8 characters long!.'
    //   //       : '';
    //   //   break;
    //   // case 'confirm'!=='password': 
    //   //   errors.confirm = 
    //   //     value.length > 8
    //   //       ? 'Please Cheack Password And Confirm Password!.'
    //   //       : '';
    //   //   break;
    //   // case 'password'!=='confirm': 
    //   //   errors.confirm = 
    //   //     value.length > 8
    //   //       ? 'Please Cheack Password And Confirm Password!.'
    //   //       : '';
    //   //   break;
    //   case 'fatherName': 
    //       if(value!='')
    //       {
    //         errors.fatherName= 

    //         res.test(value)
    //       ? 'Please Enter Full Name And Only Charecter.!'
    //       : '';
    //       }
    //   break;
    //   case 'fatherMoblie':
    //       if(value!='')
    //       { 
    //       errors.fatherMobile= 
    //       IndNum.test(value)
    //         ? 'Please Enter 10 Digite Mobile No.!'
    //         : '';
    //       }
    //    break;
    //   case 'fatherEmail': 
    //     if(value!='')
    //     { 
    //     errors.fatherEmail= 
    //      validEmailRegex.test(value)
    //         ? 'Please Enter  Valide Email Address In Student.!'
    //         : '';
    //     }
    //   break;
    //   default:
    //     break;
    // }
    // setValidationStudent({errors, [name]: value},
    // ()=> {
    //   console.log(errors)
    // })
    // this.setState();
    setStudentInfo({ ...studentinfo, [name]: value })

    // setCoutryId({ ...countryId, [name]: value })
    // if (studentinfo <= 10) {
    //   // setMobilelength(true);
    //   setErrorMessage("Please entet 10 digit")
    //   showErrorSnackBar()
    //   return;
    // }
  };

  // React.useState(() => {
  //   //handleChange(gender);
  //   setStudentInfo({ ...studentinfo, [gender]: gender })
  // }, [gender]);

  React.useEffect(() => {
    if (errorMessage) {
      setErrorMessage(errorMessage)
    }
  }, [errorMessage])
  React.useEffect(() => {
    if (validationStudent) {
      setValidationStudent(validationStudent)
    }
  }, [validationStudent])
  const validateForm = (errors) => {
    let valid = true;
    // setErrorMessage(errors)
    // showErrorSnackBar()
    Object.values(errors).forEach(
      (val) => {
        val.length > 0 && (valid = false);

      }
    );

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDateTime = new Date(studentinfo.dob);
    if (selectedDateTime > today) {
      valid = false
    }
    return valid;
  }

  const handleSubmit = () => {

    let errorText = ""
    let errorsStudent = {
      fullName: '',
      mobile: "",
      email: "",
      gender: "",
      passCode: "",
      confirm: "",
      fatherName: "",
      fatherMobile: "",
      fatherEmail: "",
      siblingName: "",
      siblingMobile: "",
      siblingEmail: "",
    };
    if (!studentinfo.userId) {
      // let errorText = ""



      if ((!res.test(studentinfo.fullName))) {
        errorsStudent.fullName = "Please Enter Full Name And Only Charecter.";
      }
      if ((!studentinfo.fullName)) {
        errorsStudent.fullName = "Please Enter Full Name.";
      }
      if ((!studentinfo.mobile)) {
        errorsStudent.mobile = "Please Enter Mobile no.";
      }
      if ((!studentinfo.gender || studentinfo.gender === 0)) {
        errorsStudent.gender = "Please select the gender.";
      }
      if ((!regexExpMO.test(studentinfo.mobile))) {
        errorsStudent.mobile = "Please Enter 10 Digites Mobile No.";
      }
      if ((!validEmailRegex.test(studentinfo.email))) {
        errorsStudent.email = "Please Enter  Valid Email Address.";
      }
      if ((!validPasswordRegex.test(studentinfo.passCode))) {
        errorsStudent.passCode = "Password must include special characters,uppercase and lowercase letters,number and meet required length.";
      }
      if ((!studentinfo.passCode)) {
        errorsStudent.passCode = "Please Enter Password.";
      }
      if ((!studentinfo.confirm)) {
        errorsStudent.confirm = "Please Enter Confirm Password.";
      }
      if (studentinfo.passCode !== studentinfo.confirm) {
        errorsStudent.confirm = "Please Cheack Password And Confirm Password.";
      }
      if (studentinfo?.fatherName?.length > 0) {
        if ((!res.test(studentinfo.fatherName))) {
          errorsStudent.fatherName = "Please Enter Full Name And Only Charecter.";
        }

      }
      if (studentinfo.fatherMobile?.length > 0) {
        //  const regexExpMOSI = /^[6-9]\d{9}$/gi;

        if ((!regexExpMOFA.test(studentinfo.fatherMobile))) {
          errorsStudent.fatherMobile = "Please Enter 10 Digites Mobile No.";
        }


      }
      if (studentinfo.fatherEmail?.length > 0) {

        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((!emailRex.test(studentinfo.fatherEmail))) {
          errorsStudent.fatherEmail = "Please Enter Valid Email Address.";
        }


      }
      if (siblingDetails.moblie?.length > 0) {
        //  const regexExpMOSI = /^[6-9]\d{9}$/gi;

        if (siblingDetails.moblie.length != 10) {
          if ((!regexExpMOSI.test(siblingDetails.moblie))) {
            errorsStudent.siblingMobile = "Please Enter 10 Digites Mobile No.";
          }
        }

      }
      if (siblingDetails.siblingName?.length > 0) {
        if ((!res.test(siblingDetails.siblingName))) {
          errorsStudent.siblingName = "Please Enter Full Name And Only Charecter.";
        }

      }

      if (siblingDetails.email?.length > 0) {
        if ((!validEmailRegex.test(siblingDetails.email))) {
          errorsStudent.siblingEmail = "Please Enter  Valid Email Address.";
        }

      }
      if (emailExists) {
        return errorsStudent.email = "This Email Id Aleady Exist.";
      }
      setValidationStudent(errorsStudent)







      if (validateForm(errorsStudent)) {
        console.log("valid")
        let reg = { ...studentinfo, passCode: studentinfo.confirm, userName: studentinfo.email }
        console.log(reg)
        let siblingDetailsList = []
        let sib = siblingDetails.siblingName == '' ? siblingDetailsList = [] : siblingDetailsList.push(siblingDetails)
        console.log(sib)
        console.log(siblingDetailsList)
        let regesster = { studetnInfo: reg, educationInfo: eductioninfo, intrestList: interestList, siblingDetailsList: siblingDetailsList }
        console.log(regesster)
        createstudenreg.mutate(regesster)
      }
      else {
        console.log("invalid", errorsStudent)
        console.log("invalid")
        setErrorMessage("Please fill Mandatory fields!.")
        showErrorSnackBar();
        // if(emailExists)
        // {
        //   setErrorMessage("Email is Exists!.")
        //   showErrorSnackBar(); 
        // }
      }

    }
    else {

      if ((!res.test(studentinfo.fullName))) {
        errorsStudent.fullName = "Please Enter Full Name And Only Charecter.";
      }
      if ((!studentinfo.fullName)) {
        errorsStudent.fullName = "Please Enter Full Name.";
      }
      if ((!studentinfo.mobile)) {
        errorsStudent.mobile = "Please Enter Mobile no.";
      }
      if ((!studentinfo.gender || studentinfo.gender === 0)) {
        errorsStudent.gender = "Please Enter Mobile no.";
      }
      if (studentinfo.mobile.length != 10) {
        if ((!regexExpMO.test(studentinfo.mobile))) {
          errorsStudent.mobile = "Please Enter 10 Digites Mobile No.";
        }
      }
      if ((!validEmailRegex.test(studentinfo.email))) {
        errorsStudent.email = "Please Enter  Valid Email Address.";
      }
      if ((!studentinfo.passCode)) {
        errorsStudent.passCode = "Please Enter Password.";
      }
      if ((!studentinfo.confirm)) {
        errorsStudent.confirm = "Please Enter Confirm Password.";
      }
      if (studentinfo.passCode !== studentinfo.confirm) {
        errorsStudent.confirm = "Please Cheack Password And Confirm Password.";
      }
      // if(studentinfo.fatherName.length>0)
      // {
      //   if((!res.test(studentinfo.fatherName)))
      //   {
      //     errorsStudent.fatherName="Please Enter Full Name And Only Charecter.";
      //   }

      // }
      if (studentinfo.fatherMobile?.length > 0) {
        //  const regexExpMOSI = /^[6-9]\d{9}$/gi;

        if ((!regexExpMOSI.test(studentinfo.fatherMobile))) {
          errorsStudent.fatherMobile = "Please Enter 10 Digites Mobile No.";
        }

      }
      if (studentinfo.fatherEmail?.length > 0) {

        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((!emailRex.test(studentinfo.fatherEmail))) {
          errorsStudent.fatherEmail = "Please Enter Valid Email Address.";
        }


      }
      if (siblingDetails.moblie?.length > 0) {
        //  const regexExpMOSI = /^[6-9]\d{9}$/gi;
        if (siblingDetails.moblie?.length != 10) {
          if ((!regexExpMOSI.test(siblingDetails.moblie))) {
            errorsStudent.siblingMobile = "Please Enter 10 Digites Mobile No.";
          }
        }

      }
      if (siblingDetails.siblingName?.length > 0) {
        if ((!res.test(siblingDetails.siblingName))) {
          errorsStudent.siblingName = "Please Enter Full Name And Only Charecter.";
        }

      }

      if (siblingDetails.email?.length > 0) {
        if ((!validEmailRegex.test(siblingDetails.email))) {
          errorsStudent.siblingEmail = "Please Enter  Valid Email Address In Student.";
        }

      }
      setValidationStudent(errorsStudent)
      if (validateForm(errorsStudent)) {
        console.log("invalid")

        let reg = { ...studentinfo, passCode: studentinfo.confirm, userName: studentinfo.email }
        console.log(reg)
        let siblingDetailsList = [];
        let sib = siblingDetails.siblingName == '' ? siblingDetailsList = [] : siblingDetailsList.push(siblingDetails)
        console.log(sib)
        if (siblingDetails.siblingName == '') {
          siblingDetailsList = [];
        }
        else {
          siblingDetailsList.push(siblingDetails)
        }
        console.log(siblingDetailsList)
        let regesster = { studetnInfo: reg, educationInfo: eductioninfo, intrestList: interestList, siblingDetailsList: siblingDetailsList }
        console.log(regesster)
        updateStudentDetails.mutate(regesster)
      }
      else {

        console.log("valid")
        // setErrorMessage(errorsStudent)
        // showErrorSnackBar()
        setErrorMessage("Please fill Mandatory fields!.")
        showErrorSnackBar();


      }
    }
  }

  return (
    <div>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <props.icon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <form autoComplete="off">
            <GridContainer justifyContent="center">
              {studentFetchLoading && props.status == "Edit" ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                  <CircularProgress size="2rem" />
                </div>
                :
                <>
                  <fieldset style={{ border: 'none', boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', margin: '20px 31px', padding: 30 }}>
                    <legend>
                      <InputLabel shrink><h3><b>Student Information</b></h3></InputLabel>
                    </legend>

                    <GridContainer>
                      <GridItem xs={12} sm={6} md={4}>
                        {/* {!studentinfo.userId? */}
                        <CustomInput
                          labelText={<span>Email</span>}
                          id="email"
                          success={(studentinfo.email) ? (!emailExists) || (validEmailRegex.test(studentinfo.email)) : ""}
                          error={(studentinfo.email) !== "" ? (emailExists) || (!validEmailRegex.test(studentinfo.email)) : ""}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          //  disabled
                          required={true}

                          helperText={(emailExists) ? "This Email Address Already Exist!." : (!validEmailRegex.test(studentinfo.email)) ? "Please enter a valid email address." : ""}
                          // type="email"
                          inputProps={{
                            name: "email",
                            onChange: handleChange,
                            onBlur: verifyEmail,
                            value: studentinfo.email,
                            // endAdornment: (
                            //   <InputAdornment position="end" className={classes.inputAdornment}>
                            //     <IconButton
                            //       onClick={() => verifyEmail(studentinfo.email)}
                            //     >
                            //       <VerifiedUser style={{ color: 'green' }} />
                            //     </IconButton>
                            //   </InputAdornment>
                            // ),
                          }}
                        //  noValidate
                        />

                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          success={studentinfo.fullName || (res.test(studentinfo.fullName))}
                          error={studentinfo.fullName ? (!res.test(studentinfo.fullName)) : ""}
                          helperText={(!res.test(studentinfo.fullName)) ? "Please Enter Full Name And Only Charecter!." : ""}
                          labelText={<span>Full Name</span>}
                          // id="userName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          required={true}
                          disabled={isStudentEixst}
                          inputProps={{
                            name: "fullName",
                            onChange: handleChange,

                            value: studentinfo.fullName,
                          }}
                          noValidate
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          labelText={<span>Mobile</span>}
                          style={{ height: 20 }}
                          id="mobile"
                          success={(studentinfo.mobile) ? (!mobilenoExists) || (!regexExpMO.test(studentinfo.mobile)) : ""}
                          error={(studentinfo.mobile) !== "" ? (mobilenoExists) || (!regexExpMO.test(studentinfo.mobile)) : ""}
                          // error={}
                          disabled={isStudentEixst}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          required={true}
                          helperText={(mobilenoExists) ? "This Mobile No. Already Exist!." : (!regexExpMO.test(studentinfo.mobile)) ? "Please enter a 10-digit mobile mumber." : ""}
                          inputProps={{
                            type: "number",
                            // +91
                            // ,
                            name: "mobile",
                            onChange: handleChange,
                            value: studentinfo.mobile,
                            // startAdornment: (
                            //   <InputAdornment position="start"

                            //   >


                            //   </InputAdornment>
                            // ),

                          }}
                        // noValidate
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={2}>

                        <FormControl className={classes.selectFormControl}>
                          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            Select Gender*
                          </InputLabel>
                          <Select
                            //success={(studentinfo.gender) !== 0 && (studentinfo.gender) !== "" && (studentInfo.gender) !== null}
                            //error={(studentinfo.gender) === 0}

                            label="select Gender"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // disabled={isStudentEixst}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            style={{ width: 180 }}
                            value={studentinfo.gender}
                            name="gender"
                            onChange={handleChange}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={1}
                            > Male </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={2}>Female</MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={3}>Others</MenuItem>

                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        {console.log(invalidDOB == false, 'validationgDob')}
                        <CustomInput
                          labelText="Date Of Birth"
                          id="dob"
                          // type="date"
                          success={invalidDOB == false}
                          error={invalidDOB == true}
                          disabled={isStudentEixst}
                          labelProps={{
                            shrink: true,
                          }}
                          InputLabelProps={{
                            shrink: false
                          }}
                          formControlProps={{
                            fullWidth: true,

                          }}
                          lableProps="DOB"
                          // type="date"
                          required={true}
                          // error={userExists}
                          // helperText={userExists == true ? "Please enter dob." : ""}
                          helperText={invalidDOB == true ? "The future date is not valid.Please enter a valid date." : ""}
                          inputProps={{
                            type: "date",
                            name: "dob",
                            onChange: handleChange,
                            value: studentinfo.dob,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText={<span>Address</span>}
                          id="address"
                          success={studentinfo.address}
                          // error={!studentinfo.address}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          // required={true}
                          // error={userExists}
                          // helperText={userExists == true ? "Please enter address" : ""}
                          //disabled={isStudentEixst}
                          inputProps={{
                            name: "address",
                            onChange: handleChange,
                            value: studentinfo.address,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>

                        <FormControl className={classes.selectFormControl}>
                          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            Select City
                          </InputLabel>
                          <Select
                            label="select State"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // disabled={isStudentEixst}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            style={{ width: 360 }}
                            value={studentinfo.city}
                            name="city"
                            onChange={handleChange}
                          >{
                              citys.map((x, i) => {

                                console.log(x);
                                return (<MenuItem
                                  // key={i}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={x.valueInt}>{x.valueString}</MenuItem>
                                )
                              })
                            }

                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <FormControl className={classes.selectFormControl}>
                          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            Select State
                          </InputLabel>
                          <Select
                            label="select State"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // disabled={isStudentEixst}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            style={{ width: 360 }}
                            value={studentinfo.state}
                            name="state"
                            onChange={handleChange}
                          >{
                              states.map((x, i) => {

                                console.log(x);
                                return (<MenuItem
                                  // key={i}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={x.valueInt}>{x.valueString}</MenuItem>
                                )
                              })
                            }

                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          labelText={<span>PinCode</span>}
                          id="email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          //disabled={isStudentEixst}
                          // required={true}
                          inputProps={{
                            type: "number",
                            name: "pinCode",
                            onChange: handleChange,
                            value: studentinfo.pinCode,

                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          labelText={<span>Password</span>}
                          id="password"

                          success={studentinfo.passCode && (validPasswordRegex.test(studentinfo.passCode))}
                          error={studentinfo.passCode && (!validPasswordRegex.test(studentinfo.passCode))}
                          formControlProps={{
                            fullWidth: true,
                            // password:true,
                            // type: "password",
                          }}
                          helperText={studentinfo.passCode && (!validPasswordRegex.test(studentinfo.passCode)) ? "Password must include special characters,uppercase and lowercase letters,number and meet required length." : ""}
                          required={true}
                          // type={}
                          disabled={isStudentEixst}
                          inputProps={{
                            // type: "password",
                            type: passwordType,
                            name: "passCode",
                            onChange: handleChange,
                            value: studentinfo.passCode,
                            endAdornment: (
                              <InputAdornment position="end" className={classes.inputAdornment}>
                                <IconButton
                                  onClick={togglePassword}
                                >
                                  {passwordType === "password" ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          noValidate
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          labelText={<span>Confirm Password</span>}
                          id="password"
                          success={studentinfo.confirm || (studentinfo.confirm == studentinfo.passCode)}
                          error={studentinfo.confirm ? (studentinfo.confirm != studentinfo.passCode) : ""}
                          disabled={isStudentEixst}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          required={true}
                          helperText={(studentinfo.confirm != studentinfo.passCode) ? "The confirmed password does not match the original." : ""}
                          inputProps={{
                            type: passwordTypeConf,
                            name: "confirm",
                            onChange: handleChange,
                            value: studentinfo.confirm,
                            endAdornment: (
                              <InputAdornment position="end" className={classes.inputAdornment}>
                                <IconButton
                                  onClick={togglePasswordConf}
                                >
                                  {passwordTypeConf === "password" ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            // type: "email",
                          }}
                          noValidate
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                      </GridItem>
                    </GridContainer>

                    {/* </Card> */}
                  </fieldset>

                  <fieldset style={{ border: 'none', boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', margin: '20px 34px', padding: 30 }}>
                    <legend>
                      <InputLabel shrink><h3><b>Education Information </b></h3></InputLabel>
                    </legend>
                    <GridContainer>
                      {/* <GridItem xs={12} sm={6} md={4}>
                    <InputLabel shrink><h3><b>Education Information:</b></h3></InputLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                  </GridItem> */}

                      <GridItem xs={12} sm={6} md={4}>
                        <FormControl className={classes.selectFormControl}>
                          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            Select Language *
                          </InputLabel>
                          <Select
                            label="select State"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // disabled={isStudentEixst}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            style={{ width: 360 }}
                            value={eductioninfo?.language}
                            name="language"
                            onChange={handleeducationChange}
                          >{
                              languages?.map((x, i) => {

                                console.log(x);
                                return (<MenuItem
                                  key={i}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={x.valueInt}>
                                  {x.valueString}
                                </MenuItem>
                                )
                              })
                            }

                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <FormControl className={classes.selectFormControl}>
                          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            Select Education Board
                          </InputLabel>
                          <Select
                            label="select State"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // disabled={isStudentEixst}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            style={{ width: 360 }}
                            value={eductioninfo?.board}
                            name="board"
                            onChange={handleeducationChange}
                          >{
                              educationbord?.map((x, i) => {

                                console.log(x);
                                return (<MenuItem
                                  // key={i}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={x.valueInt}>{x.valueString}</MenuItem>
                                )
                              })
                            }

                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <FormControl className={classes.selectFormControl}>
                          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            Select Class
                          </InputLabel>
                          <Select
                            label="select State"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // disabled={isStudentEixst}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            style={{ width: 360 }}
                            value={eductioninfo?.class}
                            name="class"
                            onChange={handleeducationChange}
                          >{
                              clas?.map((x, i) => {

                                console.log(x);
                                return (<MenuItem
                                  // key={i}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={x.valueInt}>{x.valueString}</MenuItem>
                                )
                              })
                            }

                          </Select>
                        </FormControl>

                      </GridItem>
                    </GridContainer>
                  </fieldset>
                  <Card style={{ margin: '20px 35px 20px 35px', padding: 30 }}>

                    <InputLabel
                      // style={{ marginTop: -25
                      //   , marginLeft: 15 }}
                      shrink><h3 style={{ position: 'absolute', marginTop: '-5.7%' }}><b>Interest </b></h3></InputLabel>

                    <GridItem xs={12} >
                      <GridContainer>
                        <FormGroup row  >
                          <FormControlLabel
                            style={{ marginTop: 2, }}
                            // disabled={isStudentEixst}
                            control={<Checkbox
                              type="checkbox"
                              // onChange={allcheacks}
                              // value={x.interestId}
                              value="all" onChange={onhandlecheckChange} checked={isAllSelected}
                            />}
                            label="All Selects"
                          />
                        </FormGroup>
                        {Interest.map((x, i) => {
                          if (x.isActive == true) {
                            return (<>

                              <FormGroup row key={x.interestId} >
                                <FormControlLabel
                                  style={{ marginTop: 2, }}
                                  // disabled={isStudentEixst}
                                  control={<Checkbox
                                    type="checkbox"
                                    value={x.interestId}
                                    onChange={onhandlecheckChange}
                                    checked={interestList.includes(x.interestId)}

                                  // checked={cheackall}
                                  // onChange={onhandlecheckChange}
                                  // value={x.interestId}
                                  />}
                                  label={x.interests}
                                />
                              </FormGroup>
                            </>
                            );
                          }
                        }
                        )
                        }
                      </GridContainer>
                    </GridItem>

                  </Card>

                  <fieldset style={{ border: 'none', boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', margin: 20, padding: 30 }}>
                    <legend>
                      <InputLabel shrink><h3><b>Parent Details </b></h3></InputLabel>
                    </legend>
                    <GridContainer>
                      {/* <GridItem xs={12} sm={6} md={4}>
                    <InputLabel shrink><h3><b>Parent Details:</b></h3></InputLabel>
                  </GridItem> <GridItem xs={12} sm={6} md={4}>
                  </GridItem> <GridItem xs={12} sm={6} md={4}>
                  </GridItem> */}
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          success={studentinfo.fatherName || (res.test(studentinfo.fatherName))}
                          error={studentinfo.fatherName ? (!res.test(studentinfo.fatherName)) : ""}
                          //disabled={isStudentEixst}
                          labelText={<span>Parent Name</span>}

                          helperText={(!res.test(studentinfo.fatherName)) ? "Please Enter Full Name And Only Charecter!." : ""}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "fatherName",
                            onChange: handleChange,

                            value: studentinfo.fatherName,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          success={studentinfo.fatherMobile || (IndNum.test(studentinfo.fatherMobile))}
                          error={studentinfo.fatherMobile ? (!IndNum.test(studentinfo.fatherMobile)) : ""}
                          //disabled={isStudentEixst}
                          labelText={<span>Parent Mobile</span>}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          helperText={(!IndNum.test(studentinfo.fatherMobile)) ? "Please Enter 10 Digits Mobile No." : ""}
                          inputProps={{
                            type: "number",
                            name: "fatherMobile",
                            onChange: handleChange,
                            value: studentinfo.fatherMobile,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          success={studentinfo.fatherEmail || (emailRegex.test(studentinfo.fatherEmail))}
                          error={studentinfo.fatherEmail ? (!emailRegex.test(studentinfo.fatherEmail)) : ""}
                          //disabled={isStudentEixst}
                          labelText={<span>Parent Email</span>}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          helperText={(!emailRegex.test(studentinfo.fatherEmail)) ? "Please Enter Valid Email Address." : ""}
                          inputProps={{
                            name: "fatherEmail",
                            onChange: handleChange,
                            value: studentinfo.fatherEmail,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <FormControl className={classes.selectFormControl}>
                          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                            Select Parent Profession
                          </InputLabel>
                          <Select
                            label="select State"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // disabled={isStudentEixst}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            style={{ width: 360 }}
                            value={studentinfo.fatherProfession}
                            name="fatherProfession"
                            onChange={handleChange}
                          >{
                              professions.map((x, i) => {
                                console.log(x);
                                return (<MenuItem
                                  // key={i}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={x.valueInt}>{x.valueString}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                      </GridItem> <GridItem xs={12} sm={6} md={4}>
                      </GridItem>
                    </GridContainer>
                  </fieldset>
                </>
              }
              {/* <fieldset style={{border:'none',boxShadow:'0 1px 4px 0 rgba(0, 0, 0, 0.14)',margin:20,padding:30}}>
                <legend>
                <InputLabel shrink><h3><b>Sibling Details </b></h3></InputLabel>
                </legend>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <CustomInput
                      success={siblingDetails.siblingName||(res.test(siblingDetails.siblingName))}
                      error={siblingDetails.siblingName?(!res.test(siblingDetails.siblingName)):""}
                      labelText={<span>Sibling Name</span>}
                      id="userName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      helperText={(!res.test(siblingDetails.siblingName))?"Please Enter Full Name And Only Charecter!.":""}
                      inputProps={{
                        name: "siblingName",
                        onChange: handlesiblingchange,
                        value: siblingDetails.siblingName,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <CustomInput
                      // success={siblingDetails.schoolCollege}
                      // error={!siblingDetails.schoolCollege}
                      labelText={<span>School & College Name</span>}
                      id="userName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      // required={true}
                      inputProps={{
                        name: "schoolCollege",
                        onChange: handlesiblingchange,
                        value: siblingDetails.schoolCollege,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4}>
                    <CustomInput
                      success={siblingDetails.moblie||(regexExpMOSI.test(siblingDetails.moblie))}
                      error={siblingDetails.moblie?(!regexExpMOSI.test(siblingDetails.moblie)):""}
                      labelText={<span>Moblie</span>}
                      id="userName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      // required={true}
                      helperText={(!regexExpMOSI.test(siblingDetails.moblie))?"Please Enter 10 Digits Mobile No.!.":""}
                      inputProps={{
                        type:"number",
                        name: "moblie",
                        onChange: handlesiblingchange,

                        value: siblingDetails.moblie,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <CustomInput
                      success={siblingDetails.email||(emailRegex.test(siblingDetails.email))}
                      error={siblingDetails.email?(!emailRegex.test(siblingDetails.email)):""}
                      labelText={<span>Email</span>}
                      helperText={(!emailRegex.test(siblingDetails.email))?"Please Enter Valid Email Address":""}
                      // id="userName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      // required={true}

                      inputProps={{
                        name: "email",
                        onChange: handlesiblingchange,

                        value: siblingDetails.email,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <CustomInput
                      labelText="Date Of Birth"
                     
                      // success={studentinfo.dob}
                      // error={!studentinfo.dob}
                      labelProps={{
                        shrink: true,
                      }}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      formControlProps={{
                        fullWidth: true,

                      }}
                      lableProps="DOB"
                      // required={true}
                      // error={userExists}
                      // helperText={userExists == true ? "Please enter dob." : ""}
                      inputProps={{
                        shrink: false,
                        type: "date",
                        name: "dob",
                        onChange: handlesiblingchange,
                        value: siblingDetails.dob,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                   
                    <FormControl className={classes.selectFormControl}>
                      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                        Select Sibling Education
                      </InputLabel>
                      <Select
                        label="select State"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        style={{ width: 360 }}
                        value={siblingDetails.education}
                        name="education"
                        onChange={handlesiblingchange}
                      >{
                          alleducation.map((x, i) => {

                            console.log(x);
                            return (<MenuItem
                              // key={i}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={x.valueInt}>{x.valueString}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                  </GridItem> <GridItem xs={12} sm={6} md={4}>
                  </GridItem>
                </GridContainer>
              </fieldset> */}

              <GridItem xs={12} sm={6} md={4}></GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{ textAlign: "right" }}
              >
                <Button
                  color="rose"
                  onClick={props.afterFinish}
                  style={{ marginTop: 20, marginRight: 20, textTransform: 'none' }}
                >
                  Cancel
                </Button>
                <Button
                  color="rose"
                  onClick={handleSubmit}
                  style={{ marginTop: 20, textTransform: 'none' }}
                  disabled={
                    updateStudentDetails.isLoading ||
                    createstudenreg.isLoading ||
                    emailExists ||
                    mobilenoExists ||
                    !studentinfo.fullName ||
                    !studentinfo.mobile ||
                    !studentinfo.email ||
                    studentinfo.gender === 0 ||
                    studentinfo.gender === "" ||
                    !studentinfo.dob ||
                    !studentinfo.passCode ||
                    !studentinfo.passCode ||
                    !eductioninfo?.language
                    // !user.userName
                  }
                >
                  {/* {!studentinfo.userId ? "Add" : " Update"} */}
                  {studentinfo.userId
                    ? updateStudentDetails.isLoading
                      ? "Updating..."
                      : "Update"
                    : createstudenreg.isLoading ? "Adding..." : "Add"}

                </Button>
              </GridItem>
            </GridContainer>

            <Snackbar
              open={error1}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert onClose={handleClose} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
            {mobilelegth && <CustomAlert
              open={true}
              severity="error"
              alertMessage="Please Enter 10 Digit no..."
            />
            }
            {showAlert}
            {alert}

          </form>
        </CardBody>
      </Card>

    </div>
  );
}

export default StudentRegister;
