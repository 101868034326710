import axios from "axios";
import { configUrl } from "config";
import { useMutation } from "react-query";

const useUpdateSubscriptionStatus = () => {
    return useMutation(
        async ({ testId, courseId, subscriptionStatus }) => {
            const response = await axios.post(`${configUrl.AssessmentServer}/api/CourseTestMappings/testSubscription/${testId}/${courseId}/${subscriptionStatus}`);
            return response.data;
        },
        {
            onError: (error) => {
                console.error("Error updating subscription status:", error);
            }
        }
    );
};

export default useUpdateSubscriptionStatus;
