import React from "react";
// @material-ui/icons

import CodeIcon from "@material-ui/icons/Code";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import DropdownTreeSelect from "react-dropdown-tree-select";
import getObjectiveService from "services/ConfigObjective/getObjectiveService";
import getTargetAudience from "services/TargetAudience/getTargetAudience";
import isCodeandTextExist from "services/TestSeriesService/isCodeandTextExist";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornmentDisabled: {
    color: "#b2b8be !important",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const TreeDropDown = React.memo(
  ({ data, onChange, className }) => {
    console.log(JSON.stringify(data), 'DropdownTreeData')
    return (
      <>
        <DropdownTreeSelect
          data={data}
          onChange={onChange}
          className={className}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);

const CourseDetails = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const { data: categories } = useGetCategories(true);

  const getObjectsndTargetAudience = async () => {
    try {
      let getOjbectives = await getObjectiveService.getObjective(true, true);
      setObjectiveData(getOjbectives['$values']);

      let getTargetAudiences = await getTargetAudience.getTargetAudience(true, true);
      setTargetAudienceData(getTargetAudiences['$values']);
    } catch (error) {
      console.log("error fetching objecitve and target", error);
    }
  }
  React.useEffect(() => {
    getObjectsndTargetAudience();
  }, []);
  console.log(props, "propsdata");
  const [getObjectiveData, setObjectiveData] = React.useState([]);
  const [getTargetAudienceData, setTargetAudienceData] = React.useState([]);

  const [courseCode, setCourseCode] = React.useState(props.st.code);
  const [courseCodeState, setCourseCodeState] = React.useState("");

  const [text, settext] = React.useState(props.st.text);
  const [textState, settextState] = React.useState("");

  const [courseId, setcourseId] = React.useState(props?.st?.courseId);
  const [objective, setObjective] = React.useState(isJSON(props.st.objective) ? JSON.parse(props.st.objective) : props.st.objective);
  const [targetAudience, setTargetAudience] = React.useState(isJSON(props.st.targetAudience) ? JSON.parse(props.st.targetAudience) : props.st.targetAudience);


  const [description, setDescription] = React.useState(props.st.description);

  const [image, setImage] = React.useState(props.st.image);

  const [categoryTree, setCategoryTree] = React.useState(null);

  const [selectedCategories, setSelectedCategories] = React.useState('1');  //props.st.categories
  const [selectedTopics, setSelectedTopics] = React.useState(props.st.topics?.split(',').map(Number));
  console.log(selectedTopics, 'kkkkk')
  const [selectedCategoriesState, setSelectedCategoriesState] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(null)
  const [status, setStatus] = React.useState(1);    //props.st.status ??

  const getTopicIds = (node, topics) => {
    console.log(categories, 'categoryis111')
    const children = categories.filter((x) => x.parent == node.id);
    // if (!children || children.length == 0) {
    topics.push(node.topicId);
    // }

    if (children && children.length > 0) {
      children.forEach((y) => {
        getTopicIds(y, topics);
      });
    }

  };
  const setTopicIds = (root, tops) => {

    const children = categories.filter((x) => x.parent == root.id);
    // if (!children || children.length == 0) {
    if (tops.includes(root.topicId)) {
      root.checked = true;
    }
    else {
      root.checked = false;
    }
    // }

    if (children && children.length > 0) {
      children.forEach((y) => {
        setTopicIds(y, tops);
      });
    }

  };
  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // TODO
  let isTestCodeExist;
  let isTestTextExist;
  const isTestSeriesCodeExists = async () => {
    if (courseId) {
      isTestCodeExist = await isCodeandTextExist.dupulicateCodeCheck(courseId, courseCode);
      isTestTextExist = await isCodeandTextExist.dupulicateTextCheck(courseId, text);
    } else {
      isTestCodeExist = await isCodeandTextExist.dupulicateCodeCheck(0, courseCode);
      isTestTextExist = await isCodeandTextExist.dupulicateTextCheck(0, text);
    }

    console.log(isTestCodeExist, 'isCodeExist', 'validornot ', isTestCodeExist == false)

    if (isTestCodeExist == true) {
      setCourseCodeState("error");
      console.log('got error')
    } else {
      console.log('not error')
    }
    if (isTestTextExist == true) {
      settextState("error");
      console.log('got error')
    } else {
      console.log('not error')
    }

  }
  console.log(props.st, 'propsstcourseId')

  React.useEffect(() => {
    setcourseId(props?.st?.courseId);
  }, props?.st?.courseId);
  React.useEffect(() => {
    if (props.st.courseId == undefined) {
      isTestSeriesCodeExists();
    }
    if (props?.st?.courseId) {
      isTestSeriesCodeExists();
    }
    if (!verifyLength(courseCode, 1)) {
      setCourseCodeState("error");
    } else {
      setCourseCodeState("success");
    }

    if (!verifyLength(text, 1)) {
      settextState("error");
    } else {
      settextState("success");
    }

    // if (!verifyLength(objective, 1)) {
    //   setObjectiveState("error");
    // } else {
    //   setObjectiveState("success");
    // }
    // if(selectedCategories == undefined || selectedCategories.length == 0){
    //   setSelectedCategoriesState("error");
    // }else{
    //   setSelectedCategoriesState("success");
    // }
  }, [courseCode, text]);


  const createHierarchy = (root) => {


    if (!root) return;

    root.label = root.name;
    root.value = root.id;
    if (props.st.topics == undefined) {
      root.checked = false;
    }
    // commented code for multiple category topic hierarchy added.
    // start
    // let children = [...categories].map(x => { return { ...x } }).filter(
    //   (x) => x.parent == root.id
    //   **// && x.isCategory == true**
    // );
    // end
    let children = [...categories].filter((x) => x.parent == root.id);

    // if (props.showCategoriesOnly == true) {
    //   children = children.filter((x) => x.isCategory == true);
    // }
    if (children && children.length > 0) {

      root.children = [];

      children.forEach((x) => {
        x.value = x.id;
        x.label = x.name;
        root.children.push(x);
        createHierarchy(x);
      });
    }

    // if (children && children.length > 0) {
    //   root.children = [];
    //   let cats = [];

    //   if (props.st.categories) {
    //     cats = props.st.categories.split(",");
    //   }

    // children.forEach((x) => {
    //   x.children = [];
    //   if (x.isCategory == true) {
    //     if (cats && cats.length > 0) {
    //       if (cats.includes(x.id)) {
    //         x.checked = true;
    //       } else {
    //         x.checked = false;
    //       }
    //     }

    //     x.value = x.id;
    //     x.label = x.name;
    //     root.children.push(x);
    //     createHierarchy(x);
    //   }
    // });
    // }
    return;
  };
  console.log(props?.st?.topics, 'propstopics')
  React.useEffect(() => {
    if (categories) {
      console.log(categories, 'root1234')
      // let root = [...categories].map(x => { return { ...x } }).find((x) => x.parent == null);
      let root = [...categories].filter((x) => x.parent == null && x.isCategory == true);
      createHierarchy(root);

      console.log(root, 'root123')
      if (props.st.topics) {
        const tops = props.st.topics.split(",").map(Number);
        setTopicIds(root, tops);
      }
      setCategoryTree(root)
    }
  }, [categories]);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      courseCode,
      courseCodeState,
      text,
      textState,
      objective,
      targetAudience,
      description,
      type: "details",
      image,
      selectedCategories,
      selectedTopics,
      status,
    },
  }));
  const sendState = () => {
    return {
      courseCode,
      courseCodeState,
      text,
      textState,
      objective,
      targetAudience,
      type: "details",
      description,
      image,
      selectedCategories,
      selectedTopics,
      status,
    };
  };
  // function that returns true if value is email, false otherwise
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (!value) return false;
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const isValidated = () => {
    if (
      courseCodeState === "success" &&
      textState === "success"
    ) {
      return true;
    } else {
      if (courseCodeState !== "success") {
        setCourseCodeState("error");
      }
      if (textState !== "success") {
        settextState("error");
      }

    }
    return false;
  };

  const onChange = (currentNode, selectedNodes) => {
    let topicIds = [];

    selectedNodes.forEach((x) => {
      // topicIds.push(x.topicId);
      getTopicIds(x, topicIds);
      console.log(topicIds, 'topicIds')
    });
    setSelectedTopics(topicIds);
  };
  console.log(props.st, 'stateofCourse')
  return (
    <GridContainer justify="center">
      {showAlert}
      <GridItem xs={12} sm={12} md={3}>
        <ImageUpload source={image} setImageValue={(e) => setImage(e)} />
      </GridItem>
      <GridItem xs={12} sm={12} md={9}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5}>
            <CustomInput
              success={props.st.code != undefined || props?.st?.courseId ? courseCodeState === "" : courseCodeState === "success"}
              error={courseCodeState === "error"}
              labelProps={{
                shrink: courseCode,
              }}
              labelText={
                <span>
                  Course Code <small>(required)</small>
                </span>
              }
              id="courseCode"
              formControlProps={{
                fullWidth: true,
              }}
              //disabled={props.st.code != undefined || props?.st?.courseId}
              inputProps={{
                onChange: (event) => {
                  if (!verifyLength(event.target.value, 3)) {
                    setCourseCodeState("error");
                  } else {
                    setCourseCodeState("success");
                  }
                  setCourseCode(event.target.value.trim());
                },
                maxLength: 20,
                value: courseCode,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <CodeIcon className={props.st.code != undefined || props?.st?.courseId ? classes.inputAdornmentDisabled : classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <small style={{ color: 'red' }}>
              {
                courseCodeState == 'error' && courseCode ?
                  'Course code already present' : ''
              }
            </small>
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            <CustomInput
              success={props.st.code != undefined || props?.st?.courseId ? textState === "" : textState === "success"}
              error={textState === "error"}
              labelText={
                <span>
                  Course Name <small>(required)</small>
                </span>
              }
              labelProps={{
                shrink: text,
              }}
              id="testText"
              formControlProps={{
                fullWidth: true,
              }}
              //disabled={props.st.code != undefined || props?.st?.courseId}
              inputProps={{
                onChange: (event) => {
                  if (!verifyLength(event.target.value, 3)) {
                    settextState("error");
                  } else {
                    settextState("success");
                  }
                  settext(event.target.value.trimStart());
                },
                value: text,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <PermIdentityIcon className={props.st.code != undefined || props?.st?.courseId ? classes.inputAdornmentDisabled : classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <small style={{ color: 'red' }}>
              {
                textState == 'error' && text ?
                  'Course name already present' : ''
              }
            </small>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{ marginTop: '54px' }}>
            <CustomSelect
              label="Objective"
              selectLabel="Assp"
              options={getObjectiveData && getObjectiveData.map((x) => ({
                id: x.id,
                name: x.valueString
              }))}
              showNone={false}
              showNotConf={true}
              setvalue={(v) => {
                setObjective(v)
              }}
              id="objective"
              name="objective"
              value={objective}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={3} style={{ marginTop: '54px' }}>
            <CustomSelect
              label=" Target Audience"
              selectLabel="Assp"
              options={getTargetAudienceData && getTargetAudienceData.map((x) => ({
                id: x.id,
                name: x.valueString
              }))}
              showNone={false}
              showNotConf={true}
              setvalue={(v) => {
                setTargetAudience(v)
              }}
              id="targetAudience"
              name="targetAudience"
              value={targetAudience}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={5}>
            <CustomInput
              success={true}
              labelText={<span>Description</span>}
              labelProps={{
                shrink: description,
              }}
              id="description"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => {
                  setDescription(event.target.value);
                },
                value: description,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <FormatAlignJustifyIcon
                      className={classes.inputAdornmentIcon}
                    />
                  </InputAdornment>
                ),
                rows: 3,
                multiline: true,
              }}
            />
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={12} lg={5}> */}
          {/* {categoryTree && (
              <TreeDropDown
                data={categoryTree}
                onChange={onChange}
                className="mdl-demo"
              />
            )} */}
          {/* <DropDownSelect setTopics={setSelectedTopics} showCategoriesOnly={false}/>  */}
          {/* <small style={{color:"red"}}> Note:- Category selection is mandatory for next steps. </small> */}
          {/* </GridItem> */}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
});

CourseDetails.displayName = "Step1";

export default CourseDetails;
