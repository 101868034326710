import React, { useContext } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Wizard from "components/Wizard/Wizard.js";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FileCopy } from "@material-ui/icons";
import CustomAlert from "components/CustomAlert/CustomAlert.js";
import { LoginContext } from "contexts/LoginContext.js";
import { useCreateTopicwiseTest } from "hooks/topicwisetestHooks/useCreateTopicwiseTest.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router";
import TopicwiseSelectTopics from "./TopicwiseWizardSteps/TopicwiseSelectTopics.js";
import TopicwiseTestConfiguration from "./TopicwiseWizardSteps/TopicwiseTestConfiguration.js";
import TopicwiseTestTopics from "./TopicwiseWizardSteps/TopicwiseTestTopics.js";

function TopicwiseTestWizard(props, ref) {

  // const { mutate: createTopicwiseTest } = useCreateTopicwiseTest();
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  let state = null;
  const [courseId, setCourseId] = React.useState(state ? state.courseId : null);
  const [details, setDetails] = React.useState({ ...state });
  const [flag, setFlag] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(null)
  const [Loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState(null);


  const { setTestPageState, setQuestionPageState, setCoursePageState } = useContext(LoginContext);
  React.useEffect(() => {
    setTestPageState({ pageSize: 10, page: 1 });
    setQuestionPageState({ pageSize: 10, page: 1 });
    setCoursePageState({ pageSize: 10, page: 1 })
  }, [])

  const errorAlert = (errorResponse) => {
    console.log(errorResponse, 'Message&Content');
    const itemsToShow = errorResponse.codes.$values.slice(0, 4);
    const remainingItems = errorResponse.codes.$values.length - itemsToShow.length;
    const allItems = errorResponse.codes.$values.join(", ");

    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        customButtons={
          <React.Fragment>
            <Button
              onClick={() => hideAlert()}
              style={{
                backgroundColor: 'orange',
                borderColor: 'orange',
                color: 'white',
                fontSize: '11px',
                padding: '8px 12px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              OK
            </Button>
          </React.Fragment>
        }
      >
        {itemsToShow.length > 0 ? (
          <div style={{ marginBottom: "10px" }}>
            <strong>{errorResponse.message}</strong>
            <FileCopy
              onClick={() => copyToClipboard(allItems)}
              fontSize='small'
              titleAccess="Copy All"
              style={{ cursor: 'pointer', color: "#757575", position: "absolute", marginTop: '1px', marginLeft: '8px' }}
              onMouseEnter={(e) => e.target.style.color = "#424242"}
              onMouseLeave={(e) => e.target.style.color = "#757575"}
            />
            <ul>
              {itemsToShow.map((contentItem, index) => (
                <li key={index} style={{ listStyleType: "none" }}>{contentItem}</li>
              ))}
              {remainingItems > 0 && (
                <li style={{ listStyleType: "none" }}>...and {remainingItems} more</li>
              )}
            </ul>
          </div>
        ) : (
          "Not all tests were created..."
        )}
      </SweetAlert>
    );
  };
  const hideAlert = async () => {
    setAlert(null);
    props.history.push("/admin/testhome");

  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Copied to clipboard");
    }, (err) => {
      console.error("Failed to copy text: ", err);
    });
  };

  const onNextClick = (data) => {
    let mergedData = { ...details, ...data };
    console.log("mergedDataC", mergedData);
    if (mergedData?.packageExceded == true) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/testhome"}
          severity="warning"
          alertMessage={`Please upgrade to higher package to create additional tests.`}
        />
      );
      return;
    }

    const targetAudienceIsEmpty = mergedData?.targetAudience === null || mergedData?.targetAudience === "[]";
    const objectiveIsEmpty = mergedData?.objective === null || mergedData?.objective === "[]";


    if (!targetAudienceIsEmpty && objectiveIsEmpty) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/testhome"}
          severity="warning"
          alertMessage={`Please select objective.`}
        />
      );
      return;
    }
    setDetails(mergedData);

    console.log("onnextmerged Data", mergedData);
    console.log("onnextData", data);
    console.log("onnextDetails", details);
  }

  const submitTest = async (data) => {
    setLoading(true);
    console.log("Test2352", data);
    data.TestConfiguration.objective = data.TestConfiguration.objective != null && data.TestConfiguration.objective != "none" ? JSON.stringify(data.TestConfiguration.objective) : null;
    data.TestConfiguration.targetAudience = data.TestConfiguration.targetAudience != null && data.TestConfiguration.targetAudience != "none" ? JSON.stringify(data.TestConfiguration.targetAudience) : null;
    data.TestConfiguration.navigationAllowed = data.TestConfiguration.navigationAllowed == true ? 2 : 1;
    data.TestConfiguration.showHint = data.TestConfiguration.showHint == true ? 2 : 1;
    data.TestConfiguration.showBookmark = data.TestConfiguration.showBookmark == true ? 2 : 1;
    data.TestConfiguration.showResult = data.TestConfiguration.showResult == true ? 2 : 1;
    data.TestConfiguration.showTimer = data.TestConfiguration.showTimer == true ? 2 : 1;

    if (data?.TestConfiguration?.objective === null && data?.TestConfiguration?.targetAudience !== null) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/testhome"}
          severity="warning"
          alertMessage={`Please select objective.`}
        />
      );
      setLoading(false);
      return;
    }

    let mergedData = { ...details, ...data };
    let poolconfiguration = mergedData?.PoolConfiguration;
    let testconfiguration = mergedData?.TestConfiguration;
    poolconfiguration.questionObjective = mergedData?.Topics?.objective;
    poolconfiguration.questionTargetAudience = mergedData?.Topics?.targetAudience;
    let isAllowPrevAddedQuestions = mergedData?.TestConfiguration?.isAllowPrevAddedQuestions;
    let topics = mergedData?.Topics;
    if (isAllowPrevAddedQuestions == true) {
      isAllowPrevAddedQuestions = 1;
    } else if (isAllowPrevAddedQuestions == false) {
      isAllowPrevAddedQuestions = 2;
    } else {
      isAllowPrevAddedQuestions = 0;
    }
    poolconfiguration.isAllowPrevAddedQuestions = isAllowPrevAddedQuestions;
    let postmergedData = { topics, poolconfiguration, testconfiguration }
    console.log("onsavemerged Data", postmergedData);
    if (postmergedData?.testconfiguration?.passingScore == '') {
      postmergedData.testconfiguration.passingScore = undefined;
    }
    if (postmergedData?.testconfiguration?.masteryScore == '') {
      postmergedData.testconfiguration.masteryScore = undefined;
    }
    const postPayload = await useCreateTopicwiseTest(postmergedData);

    console.log("postPayload", postPayload)
    if (postPayload.status == 200) {
      setShowAlert(
        postPayload.data == "" ?
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            // onOk={"/admin/testhome"}
            severity="success"
            alertMessage={postPayload.data == "" ? "Tests created successfully." : postPayload.data.message + "and test already created"}
          />
          :
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            // onOk={"/admin/testhome"}
            severity="error"
            alertMessage={postPayload.data.message}
          />
      )
      if (postPayload?.data?.message == "Test Code already exists" || postPayload?.data?.message == "Test Name already exists") {
        setLoading(false);
        return;
      }
      setTimeout(() => {
        props.history.push("/admin/testhome");
      }, 2000);
      setLoading(false);
    } else {
      postPayload?.data?.codes ? errorAlert(postPayload?.data) :
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            // onOk={"/admin/testhome"}
            severity="error"
            alertMessage={postPayload?.data?.message}
          />
        )

      // setTimeout(() => {
      //   props.history.push("/admin/testhome");
      // }, 4000);
      // setLoading(false);
    }
    // props.history.push("/admin/testhome");
  };

  const updateTestAsDraft = async () => {
    setShowAlert(
      <CustomAlert
        open={true}
        openFlag={() => setFlag(flag ? false : true)}
        // onOk={"/admin/testhome"}
        severity="success"
        alertMessage="Test Created in Draft."
      />
    )
    setTimeout(() => {
      props.history.push("/admin/testhome");
    }, 2000);

    /*  if (props.afterFinish) {
      props.afterFinish();
    } */
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Wizard
          validate
          steps={[
            {
              stepName: "Select Topics",
              stepComponent: TopicwiseSelectTopics,
              stepId: "Topics",
              st: { ...state },
            },
            {
              stepName: "Section Configuration",
              stepComponent: TopicwiseTestTopics,
              stepId: "PoolConfiguration",
              st: { courseId: courseId },
            },
            {
              stepName: "Test Configuration",
              stepComponent: TopicwiseTestConfiguration,
              stepId: "TestConfiguration",
              st: { ...state },
            },
          ]}
          hideTitle={props.hideTitle}
          title={"Topicwise Test"}
          subtitle=""
          finishButtonClick={submitTest}
          onNextClick={onNextClick}
          showDraftButton={false}
          draftButtonClick={updateTestAsDraft}
          finishButtonText={Loading == true ? "Creating Tests..." : "Create Tests"}
          isLoading={Loading}
          draftButtonText="Save as Draft"
          routeKey="testhome"
        />
      </GridItem>
      {showAlert}
      {alert}
    </GridContainer>
  );
}

export default withRouter(TopicwiseTestWizard);
