import { makeStyles } from "@material-ui/core";
import { WarningOutlined } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import generateQuestionService from "services/QuestionService/generateQuestionService";

const useStyles = makeStyles((theme) => ({
    ...styles
}));
export default function ContentCuratorCourseScheduleComponent(props) {
    const classes = useStyles();

    const { coursePageState } = useContext(LoginContext);
    const assignTest = async () => {

        const startDateTime = null //new Date(scheduleStartDateValue);
        const endDateTime = null //new Date(scheduleEndDateValue);
        const assignedDateTime = new Date();
        let response = await generateQuestionService.generateQuestion(props?.course?.courseId, startDateTime, endDateTime, assignedDateTime);

        if (response?.status == 200 || response?.status == 204) {
            props.afterFinish()
            props?.reloadGridFunction(coursePageState.page, coursePageState.pageSize);
            // showSnackBar("Test assigned successfully")
            // setTimeout(() => {
            //   setAlert(null);
            // }, 2000);
        } else if (response?.status == 400 || response?.status == 404) {
            // showSnackBar("Error occured", 'error');
        }
    }
    const handleCancel = () => {
        props.afterFinish()
        props?.reloadGridFunction(coursePageState.page, coursePageState.pageSize);
    }
    return (
        <div>
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title={''}
                onConfirm={() => assignTest()}
                showCancel={true}
                cancelBtnText={'CANCEL'}
                confirmBtnText={'OK'}
                onCancel={() => handleCancel()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.warning}
            >
                <div style={{ display: 'inline-flex' }}>
                    <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                    <h4> Are you sure you want to schedule course?</h4>
                </div>
            </SweetAlert>
        </div>
    )
}
