import { Grid, Snackbar } from "@material-ui/core";
import { CloudUploadSharp } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { re1 } from 'config';
import uploadOmrzip from "hooks/testsHooks/uploadOmrzip";
import React, { useEffect } from "react";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function OMRSheetsUpload({ testId, afterFinish }) {

    let fileInput = React.createRef();
    const OmrzipUpload = uploadOmrzip();

    const [filenames, setFileNames] = React.useState([]);
    const [fileIn, setFileIn] = React.useState([]);
    const [file, setFile] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [error1, setError1] = React.useState(false);
    const [severty, setSeverty] = React.useState("error");

    useEffect(() => {
        if (OmrzipUpload.isSuccess) {
            setSeverty("success")
            setErrorMessage(
                "OMR upload success. "
            );
            showErrorSnackBar();
            setTimeout(() => {
                afterFinish();
            }, 3000);
        }
    }, [OmrzipUpload.isSuccess])

    useEffect(() => {
        if (OmrzipUpload.isError) {
            setErrorMessage(
                "OMR upload failed. "
            );
            showErrorSnackBar();
            // afterFinish();
        }
    }, [OmrzipUpload.isError])

    const deleteFile = (index) => {
        console.log(index);
        let tempFile = fileIn[index];
        let l = fileIn.filter((el) => el !== tempFile);
        console.log(l);
        setFileIn(l);
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        // setOpen(false);
        setError1(false);
    };
    const showErrorSnackBar = () => {
        setError1(true);
    };
    let filelengthValidate = fileIn.length + filenames.length;

    const handleImageChange = (e) => {
        console.log(e.target.files[0], "handleIMg");
        const file = e.target.files[0].name;
        const up = [];
        console.log("selected file", e.target.files[0])

        console.log(filelengthValidate.length);
        if (!re1.exec(e.target.files[0].name)) {
            setErrorMessage(
                "This file extension is not supported!. Suported extension is zip. "
            );
            showErrorSnackBar();
            return;
        }
        const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
        if (e.target.files[0].size > maxSize) {
            setErrorMessage("Please upload file less than 10 MB. ");
            showErrorSnackBar();
            return;
        } else if (filelengthValidate >= 1) {
            setErrorMessage("Please do not upload more than 1 file.");
            showErrorSnackBar();
            return;
        } else if (
            fileIn.find((e) => e.name === file) ||
            filenames.find((e) => e === file)
        ) {
            setErrorMessage("Dublicate file is not Attached.");
            showErrorSnackBar();
            return;
        } else {
            setFileIn([...fileIn, e.target.files[0]]);
            console.log("selected file", fileIn);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        fileInput.current.value = null;
        fileInput.current.click();

        // props.refresh()
    };

    const handleSubmit = async () => {
        if (filelengthValidate > 4) {
            errorText =
                errorText + "Please do not select more than 4 file.";
        }
        if (file.length > 2) {
            errorText =
                errorText +
                "Please add minmum three attachment files...";
        }
        var tempFormData = new FormData();

        fileIn.forEach(async (element) => {
            tempFormData.append("zipFile", element);
            tempFormData.append('testId', testId);
            console.log(element);
        });

        console.log("omr3", Object.fromEntries(tempFormData))

        OmrzipUpload.mutate({ tempFormData, testId });
    }

    return (
        <Grid container spacing={3} style={{ width: '900px', Height: '700px', minWidth: '700px', margin: 'auto' }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Button
                        color="white"
                        //   disabled={OmrzipUpload.isLoading == true} 
                        onClick={(e) => handleClick(e)}
                        style={{ marginTop: 20, textTransform: 'none', border: 'none', color: 'gray', boxshadow: 'none !important' }}>
                        <CloudUploadSharp />
                        Select File
                    </Button>
                    <input
                        type="file"
                        multiple={true}
                        onChange={handleImageChange}
                        ref={fileInput}
                        style={{ display: "none" }}
                    />
                    <GridItem xs={12}>
                        {fileIn.map((item, index) => {
                            console.log(item.name);
                            console.log(index);
                            return (
                                <GridContainer>
                                    <GridItem>
                                        <h6>{item.name} </h6>
                                    </GridItem>
                                    <GridItem>
                                        {/* <span    
                                > */}
                                        <HighlightOffIcon
                                            onClick={() => {
                                                //BSS121 - was added for file should not delete while uploading
                                                // if (OmrzipUpload.isLoading == true) {
                                                //     return
                                                // } else {
                                                deleteFile(index);
                                                // }
                                            }}
                                        />
                                        {/* </HighlightOffIcon> */}
                                        {/* </span> */}
                                    </GridItem>
                                </GridContainer>
                            );
                        })}
                    </GridItem>
                    <GridItem xs={12}>
                        {filenames.map((item, index) => {
                            console.log(item);
                            console.log(index);
                            return (
                                <GridContainer>
                                    <GridItem>
                                        <h6>{item} </h6>
                                    </GridItem>
                                    <GridItem>
                                        <HighlightOffIcon
                                            onClick={() => {
                                                formgefiledetete(index);
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            );
                        })}
                    </GridItem>
                </GridItem>
                <GridItem sm={12} md={6} lg={6}>
                    <Button
                        color="rose"
                        // round
                        onClick={handleSubmit}
                        style={{ marginTop: 20, marginLeft: 20, float: 'right', textTransform: 'none' }}
                        disabled={OmrzipUpload.isLoading == true}
                    >
                        {
                            OmrzipUpload.isLoading == true ?
                                "Uploading..." :
                                "Upload"
                        }
                    </Button>
                </GridItem>

            </GridContainer>
            <Snackbar
                open={error1}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severty}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Grid>
    )
}
