import { makeStyles, Snackbar } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";

import axios from "axios";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { configUrl } from "config";
import { LoginContext } from "contexts/LoginContext";
import React, { useContext, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router";
import Button from '../../components/CustomButtons/Button';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    input: {
        display: "none",
    },
    uploadButton: {
        marginTop: theme.spacing(2),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CreatePyqTests(props) {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState(null);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [severity, setSeverity] = React.useState("success");
    const { setTestPageState, setQuestionPageState, setCoursePageState } = useContext(LoginContext);
    const [alert, setAlert] = React.useState(null);

    const errorAlert = (errorResponse) => {
        const itemsToShow = errorResponse?.codes?.$values?.slice(0, 4);
        const remainingItems = errorResponse?.codes?.$values?.length - itemsToShow.length;
        const allItems = errorResponse?.codes?.$values?.join(", ");

        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                customButtons={
                    <React.Fragment>
                        <Button
                            onClick={() => hideAlert()}
                            style={{
                                backgroundColor: 'orange',
                                borderColor: 'orange',
                                color: 'white',
                                fontSize: '11px',
                                padding: '8px 12px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            OK
                        </Button>
                    </React.Fragment>
                }
            >
                {itemsToShow?.length > 0 ? (
                    <div style={{ marginBottom: "10px" }}>
                        <strong>{errorResponse?.message}</strong>
                        <FileCopy
                            onClick={() => copyToClipboard(allItems)}
                            fontSize='small'
                            titleAccess="Copy All"
                            style={{ cursor: 'pointer', color: "#757575", position: "absolute", marginTop: '1px', marginLeft: '10px' }}
                            onMouseEnter={(e) => e.target.style.color = "#424242"}
                            onMouseLeave={(e) => e.target.style.color = "#757575"}
                        />
                        <ul>
                            {itemsToShow.map((contentItem, i) => (
                                <li key={i} style={{ listStyleType: "none" }}>{contentItem}</li>
                            ))}
                            {remainingItems > 0 && (
                                <li style={{ listStyleType: "none" }}>...and {remainingItems} more</li>
                            )}
                        </ul>
                    </div>
                ) : ("Error occurred.")}
            </SweetAlert>
        );
    };

    const hideAlert = async () => {
        setAlert(null);
        props.history.push("/admin/testhome");
    };
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log("Copied to clipboard");
        }, (err) => {
            console.error("Failed to copy text: ", err);
        });
    };


    React.useEffect(() => {
        setTestPageState({ pageSize: 10, page: 1 });
        setQuestionPageState({ pageSize: 10, page: 1 });
        setCoursePageState({ pageSize: 10, page: 1 })

    }, [])
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post(`${configUrl.AssessmentServer}/api/TestsCreator/UploadExcelTests`, formData);
            console.log(response?.data);
            if (response.status === 200) {
                if (response?.data?.codes) {
                    errorAlert(response?.data)
                } else {
                    showSnackBar('Test(s) created successfully.', "success");
                    setTimeout(() => {
                        props.history.push("/admin/testhome");
                    }, 2000);
                }
            } else {
                showSnackBar('error occured ', "error");
            }
        } catch (error) {
            console.log("error");
            showSnackBar('error occured.', "error");
        } finally {
            setLoading(false);
        }
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <GridContainer container justifyContent="center">
                <GridItem item xs={12} sm={12} md={12}>
                    <h3 style={{ textAlign: "center" }}>
                        Upload Excel File
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <input
                                accept=".xlsx, .xls"
                                className={classes.input}
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="file-upload">
                                <Button variant="contained" color="secondary" component="span">
                                    Choose File
                                </Button>
                            </label>
                            {selectedFile && (
                                <h6 style={{ margin: 0 }}>
                                    {selectedFile.name}
                                </h6>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.uploadButton}
                                disabled={loading}
                            >
                                {loading ? 'Uploading' : "Upload"}
                            </Button>
                        </div>
                    </form>
                </GridItem>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert onClose={handleClose} severity={severity}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
                {alert}
            </GridContainer>
        </div>
    );
}


export default withRouter(CreatePyqTests);
