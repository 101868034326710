import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import "assets/css/tree-dropdown-main.css";
import "assets/css/tree-dropdown.css";
import styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle';
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomAlert from "components/CustomAlert/CustomAlert";
import CustomInput from 'components/CustomInput/CustomInput';
import CustomMultipleSelect from "components/CustomSelect/CustomMultipleSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import UserFileUpload from "components/CustomUpload/UserFileUpload";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useBulkUploadUsers from "hooks/userHooks/useBulkUploadUsers";
import useCreateUser from "hooks/userHooks/useCreateUser";
import useGetUserByUserName from "hooks/userHooks/useGetUserByUserName";
import useUpdateUser from "hooks/userHooks/useUpdateUser";
import React from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
// import useCreateUserRegiste from "hooks/userHooks/useCreateUserRegiste";
const TreeDropDown = React.memo(
  ({ data, onChange, className }) => {
    return (
      <>
        <DropdownTreeSelect
          data={data}
          onChange={onChange}
          className={className}
          texts={{ placeholder: "Select Category" }}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);
const useStyles = makeStyles(styles);

function UploadefileUsers(props) {
  const classes = useStyles();
  console.log(props)
  console.log("courses", props.courses)
  // console.log(props.statevaluint[0].valueInt)

  const [user, setUser] = React.useState(props.user);
  const [rows, setRows] = React.useState([]);
  console.log(rows)
  // let a =rows.find(o=>o.isDefault=true)
  // console.log(a)
  const [citys, setCitys] = React.useState([]);
  const [language, setLanguage] = React.useState([]);
  const [educationbord, setEducattionBord] = React.useState([]);
  const [clas, setClas] = React.useState([]);
  const [interest, setInterest] = React.useState([]);
  const [s, setS] = React.useState(null);
  const [passwordType, setPasswordType] = React.useState("password");
  const [passwordTypeConf, setPasswordTypeConf] = React.useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    else {
      setPasswordType("password")
      return;
    }
  }
  const togglePasswordConf = () => {
    if (passwordTypeConf === "password") {
      setPasswordTypeConf("text")
      return;
    }
    else {
      setPasswordTypeConf("password")
      return;
    }
  }
  const [studentinfo, setStudenInfo] = React.useState([]);
  console.log(studentinfo)
  const validPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])/;
  const updateUser = useUpdateUser();
  const createUser = useCreateUser();

  const { mutate: bulkUpload, isSuccess, isLooading, isError } = useBulkUploadUsers();

  const [model, setModel] = React.useState({
    state: props?.state[0]?.valueInt,
    city: props?.citys[0]?.valueInt,
    language: props?.language[0]?.valueInt,
    class: props?.clas[0]?.valueInt,
    course: [],
    interest: 0,
    eduBoard: props?.educationbord[0]?.valueInt,
    passCode: "",
    confirm: "",
  })
  const {
    data: userDetails,
    isLoading,
    mutate: getUserDetails,
    error: userFetchError,
  } = useGetUserByUserName();

  const hideAlert = () => {
    setAlert(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target, 'triggeredVals')

    setModel({ ...model, [name]: value })

  }

  React.useEffect(() => {
    if (updateUser.isSuccess) {
      props.afterFinish();
    }
  }, [updateUser.isSuccess]);

  React.useEffect(() => {
    if (createUser.isSuccess) {
      props.afterFinish();
    }
  }, [createUser.isSuccess]);
  console.log(model, 'modellogg')
  return (
    <div>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <props.icon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer>

            <GridItem xs={12} sm={6} md={3}>
              <CustomSelect
                label="Select State"
                selectLabel="Assp"
                options={props.state}
                // onChange={(e)=>{console.log(e)}}
                setvalue={(v) => {
                  console.log('slected field', v);

                  handleChange({
                    // console.log(),
                    target: { name: "state", value: v },
                  });

                }}
                id="state"
                name="state"
                value={model.state}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomSelect
                label=" Select City"
                selectLabel="Assp"
                options={props.citys}
                setvalue={(v) => {
                  console.log('slected field', v)
                  handleChange({
                    target: { name: "city", value: v },
                  });

                }}
                id="city"
                name="city"
                value={model.city}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomSelect
                label=" Select Native Language"
                selectLabel="Assp"

                options={props.language}
                setvalue={(v) => {
                  console.log('slected field', v)
                  handleChange({
                    target: { name: "language", value: v },
                  });

                }}
                id="language"
                name="language"
                value={model.language}
              />

            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomSelect
                label=" Select Education Bord"
                selectLabel="Assp"
                options={props.educationbord}
                setvalue={(v) => {
                  console.log('slected field', v)
                  handleChange({
                    target: { name: "eduBoard", value: v },
                  });

                }}
                id="eduBoard"
                name="eduBoard"
                value={model.eduBoard}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomSelect
                label=" Select Class"
                selectLabel="Assp"
                options={props.clas}
                setvalue={(v) => {
                  console.log('slected field', v)
                  handleChange({
                    target: { name: "class", value: v },
                  });

                }}
                id="class"
                name="class"
                value={model.class}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomMultipleSelect
                label=" Select Test Series"
                selectLabel="Assp"
                options={props.courses ? props.courses : ''}
                setvalue={(v) => {
                  console.log('slected field', v)
                  handleChange({
                    target: { name: "course", value: v },
                  });

                }}
                id="course"
                name="course"
                value={model.course}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomInput
                labelText={<span>Password</span>}
                id="password"
                success={model.passCode && (validPasswordRegex.test(model.passCode))}
                error={model.passCode && (!validPasswordRegex.test(model.passCode))}
                formControlProps={{
                  fullWidth: true,
                }}
                helperText={model.passCode && (!validPasswordRegex.test(model.passCode)) ? "Password must include special characters,uppercase and lowercase letters,number and meet required length." : ""}
                // required={true}
                inputProps={{
                  type: passwordType,
                  name: "passCode",
                  onChange: handleChange,
                  value: model.passCode,
                  endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                      <IconButton
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                noValidate
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomInput
                labelText={<span>Confirm Password</span>}
                id="password"
                success={model.confirm || (model.confirm == model.passCode)}
                error={model.confirm ? (model.confirm != model.passCode) : ""}

                formControlProps={{
                  fullWidth: true,
                }}
                // required={true}
                helperText={(model.confirm != model.passCode) ? "The confirmed password does not match the original." : ""}
                inputProps={{
                  type: passwordTypeConf,
                  name: "confirm",
                  onChange: handleChange,
                  value: model.confirm,
                  endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                      <IconButton
                        onClick={togglePasswordConf}
                      >
                        {passwordTypeConf === "password" ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  // type: "email",
                }}
                noValidate
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>

              <UserFileUpload afterFinish={props.afterFinish} userTypeId={4} state={model.state} city={model.city} course={model.course} language={model.language} eduBoard={model.eduBoard} class={model.class} passCode={model.passCode} func={setStudenInfo} label="Select File" icon={<CloudUploadIcon />} />
            </GridItem>
            {/* <GridItem xs={12} sm={6} md={4}>
            <CustomSelect
              label="Interest"
              selectLabel="Assp"
              options={interest}
              setvalue={(v) => {
                console.log('slected field', v)
                handleChange({
                  target: { name: "class", value: v },
                });

              }}
              id="class"
              name="class"
              value={model.interest}
            />
          </GridItem> */}

            {/* <GridItem xs={12}  sm={12} md={12} style={{ textAlign: "right" }}>
            
            
            <Button
              color="rose"
             onClick={handleSubmit}
            >
              Save
            </Button>
          </GridItem> */}
          </GridContainer>

          {updateUser.isError && (
            <CustomAlert
              open={true}
              severity="error"
              alertMessage="User update failed"
            />
          )}

          {createUser.isError && (
            <CustomAlert
              open={true}
              severity="error"
              alertMessage="User creation failed"
            />
          )}

        </CardBody>
      </Card>
    </div>
  );
}

export default UploadefileUsers;
