import React, { useContext, useEffect, useState } from "react";
// @material-ui/icons

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

// core components
import { TextField } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomMultipleSelect from "components/CustomSelect/CustomMultipleSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { LoginContext } from "contexts/LoginContext";
import {
  providerEmailExists,
  providerMobileExists,
  providerNameExists,
} from "hooks/providerHooks/useGetAllProvider";
import getallProviderService from "services/ProviderServices/getallProviderService";
const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const ProviderDetails = React.forwardRef((props, ref) => {
  console.log(props);
  console.log(props?.st?.providerId);
  const classes = useStyles();
  const { providerId, providerType } = useContext(LoginContext);
  const [name, setname] = React.useState("");
  const [nameState, setnameState] = React.useState("");
  const [detailedName, setdetailedName] = React.useState("");
  const [detailedNameState, setdetailedNameState] = React.useState("");
  const [website, setwebsite] = React.useState("");
  const [websiteState, setwebsiteState] = React.useState("");

  const [address, setaddress] = React.useState("");
  const [addressState, setaddressState] = React.useState("");
  const [providerTypeId, setProviderTypeId] = React.useState(0);
  const [providerTypeIdState, setproviderTypeIdState] = React.useState("");
  const [packageId, setPackageTypeId] = React.useState(0);
  const [mobile, setmobile] = React.useState("");
  const [mobileState, setmobileState] = React.useState("");
  const [phone, setphone] = React.useState("");
  // const [providerType, setProviderType] = React.useState("");
  const [phoneState, setphoneState] = React.useState("");
  const [email, setemail] = React.useState("");
  const [emailState, setemailState] = React.useState("");
  //const [validateState, setValidateState] = React.useState(false);
  const [isProviderExist, setIsProviderExist] = React.useState(false);
  const [isProviderExistEmail, setIsProviderExistEmail] = React.useState(false);
  const [isProviderExistMobile, setIsProviderExistMobile] = React.useState(
    false
  );
  const [existingProviderId, setExistingProviderId] = React.useState(
    props?.st?.providerId ? props?.st?.providerId : 0
  );
  const [fromDateValue, setFromDateValue] = useState(null);
  const [toEndDateValue, setToEndDateValue] = useState(null);

  const [model, setModel] = React.useState({
    contentProvider: [],
  });
  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 1000,
  });

  const packageOptions = [
    { id: 1, name: "Silver" },
    { id: 2, name: "Gold" },
    { id: 3, name: "Platinum" },
  ];

  const providerTypeOptions = [
    { id: 1, name: "ERP" },
    { id: 2, name: "Content Partner" },
    { id: 3, name: "Subscriber" },
    { id: 4, name: "Simplified Test" },
    // 5 is content publisher, 6 is learner
    { id: 5, name: "Aggregator" },
  ];

  useEffect(() => {
    setExistingProviderId(props?.st?.providerId ?? 0);
  }, [props]);
  const fetchData = async (pageNumber, pageSize) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    let providerTypeId = 2;
    let getProviders = await getallProviderService.getAllProviders(
      pageNumber,
      pageSize,
      providerTypeId
    );

    console.log(getProviders, "getObjective1234");
    let filteredProviders = getProviders["$values"].map((x, i) => {
      return { ...x, id: x.providerId };
    });
    console.log(filteredProviders, "getObjective1234");
    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: filteredProviders,
      total:
        filteredProviders?.length > 0 ? filteredProviders[0].totalCount : 0,
    }));
  };
  React.useEffect(() => {
    if (pageState.page > 0) {
      fetchData(pageState.page, pageState.pageSize);
    }
    setModel({ ...model, ["contentProvider"]: [] });
  }, [providerTypeId]);
  console.log(model.contentProvider, "selected Courses");
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      provider: {
        providerId: 0,
        name,
        detailedName,
        providerTypeId,
        ParentProviderId: providerType == 5 ? providerId : null,
        packageId,
        logo: "",
        website,
        address,
        mobile,
        phone,
        email,
        gstInPercentage: 0,
        gstNumber: "",
        isActive: true,
        // createdBy: "Balaji",
        createdDate: new Date(),
        // updatedBy: "Balaji ",
        updatedDate: new Date(),
        nameState,
        // providerTypeIdState,
        mobileState,
        // phoneState,
        emailState,
        detailedNameState,
        addressState,
        isProviderExist,
        isProviderExistEmail,
        isProviderExistMobile,
        validFrom:
          fromDateValue == null || fromDateValue == ""
            ? null
            : new Date(fromDateValue),
        validTo:
          toEndDateValue == null || toEndDateValue == ""
            ? null
            : new Date(toEndDateValue),
        // websiteState,
        type: "details",
        // validate: validateState,
      },
      contentsCurator: model.contentProvider,
    },
  }));
  const sendState = () => {
    return {
      provider: {
        providerId: 0,
        name,
        detailedName,
        providerTypeId,
        ParentProviderId: providerType == 5 ? providerId : null,
        packageId,
        logo: "",
        website,
        address,
        mobile,
        phone,
        email,
        gstInPercentage: 0,
        gstNumber: "",
        isActive: true,
        // createdBy: "Balaji",
        createdDate: new Date(),
        // updatedBy: "Balaji ",
        updatedDate: new Date(),
        nameState,
        // providerTypeIdState,
        mobileState,
        // phoneState,
        emailState,
        detailedNameState,
        addressState,
        isProviderExist,
        isProviderExistEmail,
        isProviderExistMobile,
        validFrom: fromDateValue == null ? null : new Date(fromDateValue),
        validTo: toEndDateValue == null ? null : new Date(toEndDateValue),
        // websiteState,
        type: "details",
        //validate: validateState,
      },
      contentsCurator: model.contentProvider,
    };
  };
  // function that returns true if value is email, false otherwise
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target, "triggeredVals");

    setModel({ ...model, [name]: value });
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyMobileLength = (value, length) => {
    if (!/^\d+$/.test(value)) {
      return false; // If the value contains non-digit characters, return false
    }
    if (value.length === length) {
      return true; // If the length matches, return true
    }
    return false; // Otherwise, return false
  };

  const isExist = async (name) => {
    try {
      const isExist = await providerNameExists(name, existingProviderId);
      return isExist;
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const checkExistence = async () => {
      try {
        const temp = await isExist(name);
        setIsProviderExist(temp);
        console.log(temp, isProviderExist, "states");
      } catch (error) {
        console.log(error);
      }
    };

    checkExistence();
  }, [name]);

  React.useEffect(() => {
    const checkExistence = async () => {
      try {
        const temp = await providerEmailExists(email, existingProviderId);
        setIsProviderExistEmail(temp);
        console.log(temp, isProviderExistEmail, "states email");
      } catch (error) {
        console.log(error);
      }
    };

    checkExistence();
  }, [email]);

  React.useEffect(() => {
    const checkExistence = async () => {
      try {
        const temp = await providerMobileExists(mobile, existingProviderId);
        setIsProviderExistMobile(temp);
        console.log(temp, isProviderExistMobile, "states mobile");
      } catch (error) {
        console.log(error);
      }
    };

    checkExistence();
  }, [mobile]);

  // React.useEffect(() => {

  //   if (nameState === "success" && emailState === "success" && mobileState === "success" && addressState === "success" && detailedNameState === "success" && providerTypeId !== 0 && isProviderExist === false) {
  //     setValidateState(true);
  //   }
  // }, [nameState, emailState, mobileState, addressState, detailedNameState, providerTypeId, isProviderExist]);

  const isValidated = () => {
    // isExist(name)
    //   .then(() => {
    //     console.log("providerexistcall", isProviderExist, name);
    //   });

    //console.log(providerTypeId === 0);
    console.log(
      fromDateValue,
      "fromDateValue",
      toEndDateValue,
      "toEndDateValue"
    );
    const validFrom = new Date(fromDateValue);
    const validTo = new Date(toEndDateValue);

    if (
      nameState === "success" &&
      emailState === "success" &&
      mobileState === "success" &&
      addressState === "success" &&
      packageId !== 0 &&
      fromDateValue !== null &&
      toEndDateValue !== null &&
      fromDateValue !== "" &&
      toEndDateValue !== "" &&
      validTo > validFrom &&
      providerTypeId !== 0 &&
      detailedNameState === "success" &&
      isProviderExist === false &&
      isProviderExistEmail === false &&
      isProviderExistMobile == false
    ) {
      return true;
    } else {
      if (nameState !== "success") {
        setnameState("error");
      }
      // if (providerTypeIdState !== "success") {
      //   setproviderTypeIdState("error");
      // }
      if (emailState !== "success") {
        setemailState("error");
      }
      if (addressState !== "success") {
        setaddressState("error");
      }
      if (detailedNameState !== "success") {
        setdetailedNameState("error");
      }
      if (mobileState !== "success") {
        setmobileState("error");
      }
      // if (phoneState !== "success") {
      //   setphoneState("error");
      // }
      // if (websiteState !== "success") {
      //   setwebsiteState("error");
      // }
    }
    return false;
  };

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    console.log(newStartDate, "newStartDate");
    if (newStartDate !== "") {
      setFromDateValue(newStartDate);
    } else {
      setFromDateValue(null);
    }
  };
  const handleEndDateChange = (event) => {
    if (event.target.value !== "") {
      setToEndDateValue(event.target.value);
    } else {
      setToEndDateValue(null);
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 10); // Format: YYYY-MM-DDTHH:MM
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={4}>
        <CustomInput
          success={nameState === "success"}
          error={nameState === "error"}
          labelText={
            <span>
              Provider Name <small>(required)</small>
            </span>
          }
          id="name"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                setnameState("error");
              } else {
                setnameState("success");
              }
              setname(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                {/* <Face className={classes.inputAdornmentIcon} /> */}
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={6} md={4}>
        <CustomInput
          success={detailedNameState === "success"}
          error={detailedNameState === "error"}
          labelText={
            <span>
              Details <small>(required)</small>
            </span>
          }
          id="detailedName"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                setdetailedNameState("error");
              } else {
                setdetailedNameState("success");
              }
              setdetailedName(event.target.value);
            },
            maxLength: 200,
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                {/* <RecordVoiceOver className={classes.inputAdornmentIcon} /> */}
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={6} md={4}>
        <CustomInput
          success={websiteState === "success"}
          error={websiteState === "error"}
          labelText={
            <span>
              Website
              {/* <small>(required)</small> */}
            </span>
          }
          id="website"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              // if (!verifyLength(event.target.value, 3)) {
              //   setproviderTypeState("error");
              // } else {
              //   setproviderTypeState("success");
              // }
              setwebsite(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                {/* <RecordVoiceOver className={classes.inputAdornmentIcon} /> */}
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      {/* <GridItem xs={12} sm={6} md={4}>
        <CustomInput
          // success={providerTypeIdState === "success"}
          // error={providerTypeIdState === "error"}
          labelText={
            <span>
              Type Name <small>(required)</small>
            </span>
          }
          id="providerType"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                setproviderTypeIdState("error");
              } else {
                setproviderTypeIdState("success");
              }
              setproviderTypeId(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                //<RecordVoiceOver className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
      </GridItem> */}
      <GridItem xs={12} sm={6} md={6}>
        <CustomInput
          success={addressState === "success"}
          error={addressState === "error"}
          labelText={
            <span>
              Address<small>(required)</small>
            </span>
          }
          id="address"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyLength(event.target.value, 3)) {
                setaddressState("error");
              } else {
                setaddressState("success");
              }
              setaddress(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                {/* <RecordVoiceOver className={classes.inputAdornmentIcon} /> */}
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        <CustomInput
          success={emailState === "success"}
          error={emailState === "error"}
          labelText={
            <span>
              Email <small>(required)</small>
            </span>
          }
          id="email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyEmail(event.target.value)) {
                setemailState("error");
              } else {
                setemailState("success");
              }
              setemail(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                {/* <Email className={classes.inputAdornmentIcon} /> */}
              </InputAdornment>
            ),
          }}
        />
      </GridItem>

      {/* <GridItem xs={12} sm={6} md={4}>
        <CustomInput
          // success={websiteState === "success"}
          // error={websiteState === "error"}
          labelText={
            <span>
              Log <small>(required)</small>
            </span>
          }
          id="website"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              // if (!verifyLength(event.target.value, 3)) {
              //   setproviderTypeState("error");
              // } else {
              //   setproviderTypeState("success");
              // }
              // setwebsite(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                //<RecordVoiceOver className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
          }}
        />
      </GridItem> */}
      <GridItem xs={12} sm={6} md={3}>
        <CustomInput
          success={mobileState === "success"}
          error={mobileState === "error"}
          labelText={
            <span>
              Mobile <small>(required)</small>
            </span>
          }
          id="mobile"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              if (!verifyMobileLength(event.target.value, 10)) {
                setmobileState("error");
              } else {
                setmobileState("success");
              }
              setmobile(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                {/* <RecordVoiceOver className={classes.inputAdornmentIcon} /> */}
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        <CustomInput
          success={phoneState === "success"}
          error={phoneState === "error"}
          labelText={
            <span>
              Phone
              {/* <small>(required)</small> */}
            </span>
          }
          id="phone"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              // if (!verifyLength(event.target.value, 3)) {
              //   setproviderTypeState("error");
              // } else {
              //   setproviderTypeState("success");
              // }
              setphone(event.target.value);
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                {/* <RecordVoiceOver className={classes.inputAdornmentIcon} /> */}
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          success={false}
          error={true}
          name="packageType"
          options={packageOptions.filter((option) => {
            if (providerTypeId === 4 || providerTypeId === 5) {
              return option.id === 3;
            }
            return true;
          })}
          setvalue={(v) => setPackageTypeId(v)}
          value={packageId}
          label={
            <span>
              Package Type <small>(required)</small>
            </span>
          }
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <CustomSelect
          success={false}
          error={true}
          name="providerType"
          options={providerTypeOptions.filter((option) => {
            if (providerType == 5) {
              return (
                option.id !== 1 &&
                option.id !== 2 &&
                option.id !== 4 &&
                option.id !== 5
              );
            } else if (packageId === 1 || packageId === 2) {
              return option.id !== 4 && option.id !== 5;
            }
            return true;
          })}
          setvalue={(v) => setProviderTypeId(v)}
          value={providerTypeId}
          label={
            <span>
              Provider Type <small>(required)</small>
            </span>
          }
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        {providerTypeId == 3 || providerTypeId === 1 || providerTypeId === 5 ? (
          <CustomMultipleSelect
            label={
              <span>
                Content Providers <small>(required)</small>
              </span>
            }
            selectLabel="Assp"
            options={pageState.data ? pageState.data : ""}
            setvalue={(v) => {
              console.log("slected field", v);
              handleChange({
                target: { name: "contentProvider", value: v },
              });
            }}
            id="contentProvider"
            name="contentProvider"
            value={model.contentProvider}
          />
        ) : null}
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <TextField
          type="date"
          name="fromDateValue"
          value={fromDateValue}
          onChange={handleStartDateChange}
          label="Valid From"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: getCurrentDateTime(),
            // max: toEndDateValue, // Disable dates greater than maxEndDate
          }}
          style={{ borderColor: "primary", marginBottom: 11 }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <TextField
          type="date"
          name="toEndDateValue"
          value={toEndDateValue}
          onChange={handleEndDateChange}
          label="Valid To"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: getCurrentDateTime(),
          }}
          style={{ borderColor: "primary", marginBottom: 11 }}
        />
      </GridItem>
    </GridContainer>
  );
});

export default ProviderDetails;
