import React, { useRef } from "react";

import Switch from "@material-ui/core/Switch";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import RefreshIcon from "@material- ui/icons/Refresh";
import CustomAlert from "components/CustomAlert/CustomAlert";
import useCreateConfigurationClass from "hooks/configurationsHooks/useCreateConfigurationClass";
import useDeleteConfiguration from "hooks/configurationsHooks/useDeleteConfiguration";
import useGetAllClass from "hooks/configurationsHooks/useGetAllClass";
import useUpdateConfiguration from "hooks/configurationsHooks/useUpdateConfiguration";
// import useIsConfigurationExists from "hooks/configurationsHooks/useIsConfigurationExists";

import CustomModal from "components/CustomModal/CustomModal";
// import Configurations from "views/Forms/Configurations";
import TextField from "@material-ui/core/TextField";
// import { render } from 'react-dom';
// import { WithContext as ReactTags } from 'react-tag-input';
// import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { LoginContext } from "contexts/LoginContext";
import "react-tagsinput/react-tagsinput.css";

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SystemConfigurations(props) {
  console.log("props", props);
  console.log(props.configurationType);

  const {
    data: configurations,
    error: configurationsFetchError,
    // mutate: getconfigurations,
    isLoading: configurationsFetchLoading,
    refetch: refetchConfigurations,
  } = useGetAllClass(props.configurationType, "");

  // const configurations = useGetAllConfigurations(props.configurationType);
  const { licensePackageObject, userPrivileges } = React.useContext(
    LoginContext
  );
  console.log(configurations);
  const createConfiguration = useCreateConfigurationClass();
  console.log(createConfiguration);
  const updateConfiguration = useUpdateConfiguration();
  const deleteConfiguration = useDeleteConfiguration();
  // const isinterexist = useIsConfigurationExists();

  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [configurationsEditForm, setConfigurationsEditForm] = React.useState(
    null
  );

  const [deleteModal, setDeleteModal] = React.useState(null);
  const [configuration, setConfiguration] = React.useState({
    id: 0,
    providerId: 0,
    key: props.configurationType,
    sequenceId: 0,
    valueInt: 0,
    valueString: "",
    isActive: true,
    createdBy: "",
    createdDate: new Date(),
    updatedBy: "",
    updatedDate: new Date(),
  });
  const [error1, setError1] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError1(false);
  };
  const showErrorSnackBar = () => {
    setError1(true);
  };

  console.log(configurations);

  // const [tags, setTags] = React.useState([]);

  // const handleChange = value => {
  //   setTags(value);
  // }
  const ref = useRef(null);

  const classes = useStyles();

  const handleChange = (event) => {
    setConfiguration({
      ...configuration,
      [event.target.name]: event.target.value,
    });
    console.log({ ...configuration, [event.target.name]: event.target.value });
  };
  const OnSubmit = async () => {
    console.log(configuration);
    console.log(
      "classData",
      configurations?.length,
      licensePackageObject?.SystemConfiguration
    );
    if (configurations) {
      if (
        configurations?.length + 1 >
        licensePackageObject?.SystemConfiguration
      ) {
        setErrorMessage(
          `Please upgrade to higher package for adding more than ${licensePackageObject?.SystemConfiguration} configurations.`
        );
        showErrorSnackBar();
        return;
      }
    }
    const found = rows.find(
      (element) =>
        element.valueString.toLowerCase().trim() ===
        configuration.valueString.toLowerCase().trim()
    );
    console.log("found..", found);
    let errorText = "";
    if (configuration.valueString === "") {
      errorText = errorText + "Please enter " + props.configurationType;
    }
    if (found) {
      errorText =
        errorText +
        '"' +
        configuration.valueString +
        '"' +
        " Class already exist";
    }
    if (errorText != "") {
      // alert(errorText);
      //  setMessages(errorText);
      // showError();
      setErrorMessage(errorText);
      showErrorSnackBar();
      return;
    } else {
      let a = createConfiguration.mutate(configuration);
      console.log("a....", a);
      setConfiguration({ ...configuration, valueString: "" });
    }
  };

  const showDeleteConfirmation = (id) => {
    setDeleteModal(
      <CustomModal
        modal={true}
        setModal={() => hideDeleteCustomModal()}
        content={<h3>Are you sure you want to delete the configurations?</h3>}
        cancelButtonMessage="Cancel"
        okButtonMessage={deleteConfiguration.isLoading ? "Deleting" : "Yes"}
        showOk={true}
        onCancelClick={() => hideDeleteCustomModal()}
        title="Delete Configuration"
        maxWidth="sm"
        onOkClick={async () => {
          await deleteConfiguration.mutateAsync(id);
          hideDeleteCustomModal();
        }}
        showCancel={true}
        padding1={true}
      />
    );
  };

  const hideDeleteCustomModal = () => {
    setDeleteModal(null);
  };

  const onDelete = async (e, id) => {
    showDeleteConfirmation(id);
  };

  const onUpdate = (val) => {
    // e.preventDefault();

    updateConfiguration.mutate(val);
  };

  const onInfo = (e, id) => {
    alert("info");
  };
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.SystemConfiguration.indexOf(x) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  const handleRowSelection = (e) => {
    setSelection(e);
  };

  const onCellEditCommit = (p) => {};

  const onCellBlur = (e, p) => {};

  const handleEdit = (p, e) => {};

  React.useEffect(() => {
    let cols = [
      {
        field: "valueString",
        headerName: "Class",
        editable: false,
        width: 130,
      },
      {
        field: "actions",
        headerName: "Action",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            // fillButtons
            <>
              {isPrivilege("Active/InactiveSystemConfiguration") == true ? (
                <Switch
                  value={cellValues.row.isActive}
                  onChange={(e) => {
                    let val = {
                      target: {
                        name: "isActive",
                        value: !cellValues.row.isActive,
                      },
                    };
                    //handleChange(val);
                    cellValues.row.isActive = !cellValues.row.isActive;
                    onUpdate(cellValues);
                  }}
                  // console.log(cellValues.row.isActive)
                  checked={cellValues.row.isActive}
                />
              ) : (
                ""
              )}
            </>
          );
        },
      },
    ];

    setColumns(cols);
  }, []);

  React.useEffect(() => {
    if (createConfiguration.isSuccess == true) {
      refetchConfigurations();
    }
  }, [createConfiguration.isSuccess, refetchConfigurations]);

  React.useEffect(() => {
    if (configurations != undefined) {
      setRows(
        configurations.map((x, i) => {
          // alert({...x})
          return { ...x };
        })
      );
    }
  }, [configurations]);
  React.useEffect(() => {
    if (updateConfiguration.isError)
      if (configurations != undefined) {
        setRows(
          configurations.map((x, i) => {
            // alert({...x})
            return { ...x };
          })
        );
      }
  }, [configurations, updateConfiguration.isError]);

  const getRowClassName = (row) => {
    return null;
  };

  return (
    <>
      <Card>
        <CardBody style={{ marginTop: 5 }}>
          {/* <GridContainer spacing={1} justifyContain="center"> */}
          <Grid container spacing={2}>
            <GridItem>
              <TextField
                // {...params}
                // style={{ ma }}
                style={{ marginTop: 5, width: 300 }}
                variant="outlined"
                // label="filterSelectedOptions"
                // placeholder="Favorit
                size="small"
                type="text"
                name="valueString"
                // styles={{ width: 100 }}
                value={configuration.valueString}
                onChange={handleChange}
              />
              {/* <span> </span> */}
            </GridItem>
            <GridItem>
              {isPrivilege("AddSystemConfiguration") == true ? (
                <Button
                  // style={{ height: 54 }}
                  color="rose"
                  onClick={OnSubmit}
                  style={{ textTransform: "none" }}
                  disabled={createConfiguration.isLoading == true}
                >
                  {createConfiguration.isLoading == true
                    ? "Adding..."
                    : "+ Add"}
                  {/* {props.configurationType} */}
                </Button>
              ) : (
                ""
              )}
            </GridItem>
            <GridItem></GridItem>
          </Grid>
          {/* </GridContainer> */}
          <GridContainer spacing={1} justifyContain="center">
            <span> </span>
          </GridContainer>{" "}
          <br />
          <GridContainer justifyContain="center">
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ height: 500, width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  {rows ? (
                    <div ref={ref} style={{ flexGrow: 1 }}>
                      <CustomDataGrid
                        columns={columns}
                        rows={rows}
                        autoPageSize={true}
                        onEditCellPropsChange={handleEdit}
                        onCellEditCommit={onCellEditCommit}
                        onSelectionModelChange={handleRowSelection}
                        getRowClassName={getRowClassName}
                        onCellBlur={onCellBlur}
                      />
                    </div>
                  ) : (
                    <div style={{ flexGrow: 1 }}>
                      <CircularProgress /> Loading configuration...
                    </div>
                  )}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      {updateConfiguration.isError == true && (
        <CustomAlert
          open={true}
          severity="error"
          alertMessage="The Class is in use."
        />
      )}
      {createConfiguration.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="Class Added successfully"
        />
      )}
      {updateConfiguration.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="Configuration Updated successfully"
        />
      )}
      {deleteConfiguration.isSuccess == true && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="Configuration Deleted successfully"
        />
      )}
      {createConfiguration.isError == true && (
        <CustomAlert
          open={true}
          severity="error"
          alertMessage="Please select class"
        />
      )}
      <Snackbar
        open={error1}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      {configurationsEditForm}
      {deleteModal}
    </>
  );
}
