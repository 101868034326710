import React from "react";

import { CircularProgress } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Tooltip } from "@mui/material";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useDeleteCourseUserMapping from "hooks/courseTestMappingHooks/useDeleteCourseUserMapping";
import useGetCourseUserMappingByCourseId from "hooks/courseTestMappingHooks/useGetCourseUserMappingByCourseId";
import useUpdateUserSubscription from "hooks/courseTestMappingHooks/useUpdateUserSubscription";

function CourseUserMappedGrid(props) {
  console.log("props", props);

  const { data: users, isLoading, refetch } = useGetCourseUserMappingByCourseId(
    props.courseId
  );

  const deleteUser = useDeleteCourseUserMapping(props.courseId);
  const updateUserSubscription = useUpdateUserSubscription();

  console.log("users", users);

  React.useEffect(() => {
    refetch();
  }, [updateUserSubscription?.isSuccess, updateUserSubscription?.isError]);

  const [columns, setColumns] = React.useState([]);

  const handleSubscriptionStatusClick = async (userId, userSubscriptionStatus) => {
    try {
      let newStatus = (userSubscriptionStatus === null || userSubscriptionStatus === 0) ? 1 : userSubscriptionStatus;

      newStatus = newStatus === 1 ? 2 : 1;

      console.log("userSubscrip22", newStatus);

      await updateUserSubscription.mutateAsync({
        userId,
        courseId: props.courseId,
        subscriptionStatus: newStatus,
      });
    } catch (error) {
      console.error("Failed to update subscription status:", error);
    }
  };


  React.useEffect(() => {
    setColumns([
      {
        field: "userId",
        headerName: "Id",
        editable: false,
        width: 100,
        hide: true,
      },
      {
        field: "fullName",
        headerName: "Full Name",
        editable: false,
        width: 200,
      },
      {
        field: "userName",
        headerName: "User Name",
        editable: false,
        width: 200,
      },
      {
        field: "mobile",
        headerName: "Mobile No",
        editable: false,
        width: 200,
      },
      {
        field: "actions",
        headerName: "Action",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            <div>
              <Tooltip title="Delete">
                <Button
                  size="sm"
                  justIcon
                  round
                  color="reddit"
                  disabled={props.status >= 4 ? true : false}
                  onClick={(e) => {
                    e.preventDefault();
                    deleteUser.mutate(cellValues.row.userId);
                  }}
                >
                  <DeleteForeverIcon />
                </Button>
              </Tooltip>
              <Tooltip title={cellValues.row.userSubscriptionStatus === 2 ? "Subscribed" : "Unsubscribed"}>
                <Button
                  size="sm"
                  justIcon
                  round
                  color={cellValues.row.userSubscriptionStatus === 2 ? "info" : "grey"}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubscriptionStatusClick(
                      cellValues.row.userId,
                      cellValues.row.userSubscriptionStatus
                    );
                  }}
                >
                  {<CheckCircleIcon />}
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    ]);
  }, []);

  return (
    <>
      <GridContainer>
        {isLoading == false && users ? (
          <GridItem xs={12} sm={12} md={12}>
            <div style={{ height: 400, width: "100%" }}>
              <div style={{ display: "flex", height: "90%" }}>
                <div style={{ flexGrow: 1 }}>
                  <CustomDataGrid
                    columns={columns}
                    rows={users}
                    autoPageSize={true}
                  />
                </div>
              </div>
            </div>
          </GridItem>
        ) : (
          <CircularProgress />
        )}
      </GridContainer>
      {updateUserSubscription?.isSuccess && (
        <CustomAlert
          open={true}
          severity="success"
          alertMessage="User subscription changed!"
        />
      )}
    </>
  );
}

export default CourseUserMappedGrid;
