import React, { useContext, useEffect, useRef, useState } from "react";
//import QuestionComponent from "./QuestionComponent";

import Box from "@material-ui/core/Box";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Question from "components/Questions/Question";

// @material-ui/icons

// core components
import { CircularProgress, makeStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { ImportContactsSharp } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import PrintPreviewComponent from "components/PreviewPrintComponent/PrintPreviewComponent";
import ConcludeScreen from "components/Questions/ConcludeScreen";
import FeedbackFailScreen from "components/Questions/FeedbackFailScreen";
import FeedbackMasteryScreen from "components/Questions/FeedbackMasteryScreen";
import FeedbackPassScreen from "components/Questions/FeedbackPassScreen";
import WelcomeScreen from "components/Questions/WelcomeScreen";
import Solution from "components/Solution/Solution";
import { TestContext } from "contexts/TestContext";
import usePublishQuestion from "hooks/questionHooks/usePublishQuestion";
import useCreateTestResult from "hooks/resultHooks/useCreateTestResult";
import usePublishTest from "hooks/testsHooks/usePublishTest";
import SweetAlert from "react-bootstrap-sweetalert";
import testPreviewService from "services/Preview/testPreviewService";

const useStyles = makeStyles(styles);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const QuestionWrapper = ({ onClose, isQuestionPreview, isShowPublishQuestion, isShowPublish, attempt, preview, isCourse, totalScore, testStatus, testName }) => {
  console.log(testStatus, 'testStatus')
  const classes = useStyles();

  const { test, setTest, testAnswers, setTestAnswers, testAttemptDetails, setTestAttemptDetails, testId, userId } = useContext(
    TestContext
  );
  console.log("preview", preview)
  console.log(testAttemptDetails, 'testAttemptDetails')
  const myRef = useRef();
  console.log(test, "test datadata");
  const [showWM, setShowWM] = useState(
    test.welcomeMessage && test.welcomeMessage != null
  );
  // console.log(test.welcomeMessage);
  let questionsPerPage = 10;
  const [showCM, setShowCM] = useState(false);

  const [showFP, setShowFP] = useState(false);
  const [showFF, setShowFF] = useState(false);
  const [showFM, setShowFM] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [actualScore, setActualScore] = useState(0);
  const [alert, setAlert] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTestSubmitted, setIsTestSubmitted] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isShowPreview, setIsShowPreview] = useState(true);
  const [score, setScore] = useState(0);
  const [showSolution, setShowSolution] = useState(false);
  const [showSolutionArray, setShowSolutionArray] = useState(new Array(10).fill(false)); // Create an array of false values initially

  const result = useCreateTestResult();
  const publishTest = usePublishTest();
  const publishQuestionCall = usePublishQuestion();

  const getSync = async () => {
    if (testId != null) {
      let response = await testPreviewService.getPreviewTests(testId, pageNumber, pageSize);
      console.log(response?.data, 'Allquestions');
      setTest(response?.data);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getSync();
  }, [pageNumber]);
  useEffect(() => {
    let rtest = test.pools["$values"].map((x, i) => {
      x.questions["$values"].forEach((y) => {
        y.poolText = x.text;
        y.poolId = x.id;
        y.isMandatoryToPass = x.isMandatoryToPass;
        y.poolScore = x.poolScore;
        y.response = null;
        y.isCorrect = false;
      });

      return x.questions["$values"];
    });

    rtest = rtest.flat();
    const qPerPage = questionsPerPage;
    // console.log("ExpiryDate",test.ExpiryDate);
    let c = 0;
    let currentPool = "";
    let index = 0;

    let mdata = rtest.reduce((acc, cur) => {
      console.log("Innney", isQuestionPreview);
      if (cur.id === 0 && isQuestionPreview != true) {
        return acc;
      }
      if (currentPool == "") {
        currentPool = cur.poolText;
      }

      if (c == qPerPage || currentPool != cur.poolText) {
        c = 0;
        currentPool = cur.poolText;
        index++;
      }

      if (!acc[index]) {
        acc[index] = [];
      }
      console.log("acbjaka", acc);
      acc[index].push(cur);
      c++;

      return acc;
    }, []);

    let mergedArray = [];   // code added for merging all questions i.e. 10 Questions per page.
    if (mdata?.length > 0) {
      mergedArray[0] = mdata?.flat();
    }
    setQuestions(mergedArray);

    console.log("mdata", mdata, mergedArray);
  }, [test]);

  const updateQuestion = (question) => { };
  // console.log("attemptDetails1223",testAttemptDetails);
  const captureResult = async (questions, actualScore, isPassed) => {
    // let testResult = {
    //   testId: testId,
    //   userId: userId,
    //   attemptNumber: attempt,
    //   isCourse: isCourse,
    //   result:isPassed == true ? 2 : 1,
    //   totalScore: t,
    //   actualScore: a,
    //   attemptAwnsers: JSON.stringify(q),
    //   attemptedOn: new Date()
    // };
    testAttemptDetails.isCourse = isCourse;
    testAttemptDetails.result = isPassed == true ? 2 : 1;
    //testAttemptDetails.totalScore = test.totalScore;
    testAttemptDetails.actualScore = actualScore;
    testAttemptDetails.attemptAnswers = JSON.stringify(questions);
    testAttemptDetails.attemptedOn = new Date()
    testAttemptDetails.updatedDate = new Date()

    // console.log("testResult",testAttemptDetails);

    await result.mutateAsync(testAttemptDetails);
  };
  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };
  const handleSubmit = async () => {
    if (preview == true) {
      console.log("disabled")
    }
    questions[currentQuestionIndex].isSubmitted = true;

    let score = calculateScore();

    //let total = test.pointsPerQuestion * questions.flat().length;
    // console.log(total, test.pointsPerQuestion, questions.flat().length);
    let actualScore = 0;

    for (let pool in score) {
      actualScore = actualScore + score[pool].score;
    }

    setActualScore(actualScore);
    // setTScore(total);
    setScore(score);

    let isPassed = isPassedTest(score);
    // console.log("isPassed",isPassed);
    let isMasteryPassed = isMasteryPassedTest(score);

    if (!preview) {
      await captureResult(questions, actualScore, isPassed);
    }

    // console.log(isPassed, isMasteryPassed);

    if (isPassed) {
      setShowFF(false);
      setShowFP(true);
      if (isMasteryPassed) {
        setShowFP(false);
        setShowFM(true);
      }
    }

    if (!isPassed) {
      setShowFF(true);
      setShowFP(false);
      setShowFM(false);
    }
  };

  const handlePublish = async (id) => {
    await publishTest.mutate(id);
    console.log(typeof onClose);
    setAlert(null)
    // will show snackbar on 
    // if 'publishTest.isSuccess '
  }

  const publishQuestion = async (id) => {
    await publishQuestionCall.mutate(id);
    setAlert(null);

  }

  React.useEffect(() => {
    console.log("messagescall", publishQuestionCall);
    if (publishQuestionCall.isSuccess == true) {
      onClose();
      showSnackBar("Question published successfully");
    }
    if (publishQuestionCall.isError == true) {
      onClose();
      showSnackBar("Error occured", 'error');
    }
  }, [publishQuestionCall.isSuccess, publishQuestionCall.isError, publishQuestionCall?.isLoading]);

  React.useEffect(() => {
    if (publishTest.isSuccess == true) {
      onClose();
      showSnackBar("Test published successfully");
    }
    if (publishTest.isError == true) {
      onClose();
      showSnackBar("Error occured", 'error');
    }
  }, [publishTest.isSuccess, publishTest.isError, publishTest.isLoading]);

  console.log(publishTest, 'publishTest123')
  const isPassedTest = (score) => {
    let isPassed = true;
    let totalScore = 0;
    for (let pool in score) {
      if (score[pool].isMandatoryToPass) {
        isPassed = isPassed && score[pool].pass;

        if (!isPassed) return false;
      }

      totalScore = totalScore + score[pool].score;
    }

    console.log("totalScore", totalScore, test.passingScore);
    console.log("totalScore1", totalScore >= test.passingScore);


    return totalScore >= test.passingScore;
  };

  const isMasteryPassedTest = (score) => {
    let totalScore = 0;
    for (let pool in score) {
      totalScore = totalScore + score[pool].score;
    }

    return totalScore >= test.masteryScore;
  };

  const setNextQuestion = () => {
    let questionIndex = currentQuestionIndex;
    setCurrentQuestionIndex(currentQuestionIndex + questionsPerPage);
    if (((questionIndex + 1 + questionsPerPage) + 1) > (questions[0]?.length)) {
      setPageNumber(pageNumber + 1);
      setIsLoading(true);
      setCurrentQuestionIndex(0);
    } else {
      setCurrentQuestionIndex(questionIndex + 1);
    }
    //setShowSolution(false)
    setShowSolutionArray(new Array(10).fill(false));
    myRef.current.focus();
  };

  const setPreviousQuestion = () => {
    let questionIndex = currentQuestionIndex;
    setIsLoading(true);
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      setCurrentQuestionIndex(0);
    }
    // if (questionIndex === 0) {
    //   if (pageNumber > 1) {
    //     setPageNumber(pageNumber - 1);
    //     setCurrentQuestionIndex(questionsPerPage - 1);
    //   }
    // } else {
    //   setCurrentQuestionIndex(questionIndex - 1);
    // }
    //setShowSolution(false);
    setShowSolutionArray(new Array(10).fill(false));
    myRef.current.focus();
  };


  const toggleSolution = (index) => {
    const updatedShowSolutionArray = [...showSolutionArray];
    updatedShowSolutionArray[index] = !updatedShowSolutionArray[index];
    setShowSolutionArray(updatedShowSolutionArray);
  };


  // const setNextQuestion = () => {
  //   let questionIndex = currentQuestionIndex + 1
  //   // if ((questionIndex + 1) % 11 == 0) {
  //   if (questionIndex + 1 == 11) {
  //     setIsLoading(true);
  //     setPageNumber(pageNumber + 1);
  //     setCurrentQuestionIndex(0);
  //   } else {
  //     setCurrentQuestionIndex(questionIndex)
  //   }
  //   setShowSolution(false)
  //   myRef.current.focus();
  // };

  // const setPreviousQuestion = () => {
  //   let questionIndex = currentQuestionIndex - 1
  //   // if ((questionIndex % 10) == 0) {
  //   if (questionIndex == -1) {
  //     if (pageNumber > 1) {
  //       setIsLoading(true);
  //       setPageNumber(pageNumber - 1);
  //       setCurrentQuestionIndex(9);
  //     }
  //   } else {
  //     setCurrentQuestionIndex(questionIndex);
  //   }
  //   setShowSolution(false);
  // };

  const showWelcome = () => {
    return showWM;
  };

  const showQuestions = () => {
    return !showWM && !showCM && !(showFF || showFM || showFP);
  };

  const showFeedPass = () => {
    return showFP;
  };

  const calculateScore = () => {
    let score = {};

    score = questions.flat().reduce((acc, curr) => {
      console.log("Check This", curr);
      if (!(curr.poolId in acc)) {
        acc[curr.poolId] = {};
        acc[curr.poolId].score = 0;
      }
      console.log(curr, "curr123");
      let cscore = curr.points;
      let negativeScore = curr.negativePoints;
      // test.pointsPerQuestion && test.pointsPerQuestion > 0
      //   ? test.pointsPerQuestion
      //   : curr.points;

      if (curr.response != null) {
        if (curr.isCorrect == true) {
          acc[curr.poolId].score = acc[curr.poolId].score + cscore;
        } else {
          acc[curr.poolId].score = acc[curr.poolId].score + negativeScore;
        }
      }


      acc[curr.poolId].pass =
        acc[curr.poolId].score >= curr.poolScore ? true : false;
      acc[curr.poolId].masteryPass =
        acc[curr.poolId].score >= curr.masteryScore ? true : false;
      acc[curr.poolId].isMandatoryToPass = curr.isMandatoryToPass;

      return acc;
    }, {});

    console.log(score);
    return score;
  };
  console.log(questions, "questions124");

  var totalCount = test?.totalCount;
  return (
    <div>
      {showWelcome() && (
        <WelcomeScreen
          message={test.welcomeMessage}
          handleConfirm={() => setShowWM(false)}
        />
      )}

      {showQuestions() && (
        // <Card>
        //   <CardHeader color="rose" icon>
        //     <CardIcon color="rose">
        //       <HelpOutlineIcon />
        //     </CardIcon>
        //   </CardHeader>
        <CardBody>
          {questions && questions.length > 0 && (
            <Box
              display="flex"
              p={1}
              style={{ padding: 0, marginTop: 2, marginBottom: 2 }}
            >
              <Box
                p={1}
                flexGrow={1}
                style={{ padding: 0, marginTop: 2, marginBottom: 2 }}
              >
                {/* <h4 style={{color:'#DE2668',fontWeight:'400'}}>{questions[currentQuestionIndex][0].poolText} </h4> */}
              </Box>
              {isShowPreview == true && test.navigation ? (
                <Box p={1}>
                  <Button
                    justIcon
                    round
                    color="primary"
                    onClick={setPreviousQuestion}
                    disabled={(pageNumber === 1 && currentQuestionIndex === 0) || isLoading}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Box>
              ) : null}
              {isShowPreview == true && test.navigation ? (
                <Box p={1}>
                  <Button
                    ref={myRef}
                    justIcon
                    round
                    color="primary"
                    onClick={setNextQuestion}
                    disabled={((pageNumber) * questionsPerPage + currentQuestionIndex + 1 >= totalCount) || isLoading}
                  >
                    <ArrowForwardIcon />
                  </Button>
                </Box>) : null
              }
            </Box>
          )}
          {
            isShowPreview == true ?
              isLoading == false ?
                (questions &&
                  questions.length > 0 &&
                  questions[currentQuestionIndex] &&
                  questions[currentQuestionIndex].map((x, i) => (
                    <>
                      {/* {console.log(i, 'iteration')} */}
                      {questions[currentQuestionIndex][i]?.poolText && (<h4 style={{ marginLeft: '3%', marginTop: '-4%', position: 'absolute', color: '#E63673', fontWeight: '500' }}>{'Section'}  - {questions[currentQuestionIndex][i]?.poolText} </h4>)}
                      <Question
                        key={currentQuestionIndex}
                        qnoes={(pageNumber - 1) * pageSize + currentQuestionIndex + i + 1}
                        question={x}
                        updateQuestion={updateQuestion}
                        preview={preview}
                        isShowPublish={isShowPublish}
                      />
                      {

                        preview == true ? x.solution || x.solutionText || x?.solutionLatex || x?.solutionMathML ?
                          <Button color="primary" style={{ marginInline: '3%', marginBottom: '5%' }} onClick={() => toggleSolution(i)}>
                            <ImportContactsSharp style={{ marginRight: '5px' }} />
                            {
                              showSolutionArray[i] ? ' Hide Solution ' : 'Show Solution'
                            }
                          </Button> : null : null
                      }
                      {/* 
                    
                    
                    {console.log("xvalues", x)} */}
                      {isShowPublishQuestion == true ?

                        <Button color="primary"
                          round
                          onClick={(e) => {
                            e.preventDefault();
                            console.log("hereIn");
                            setAlert(
                              <SweetAlert
                                style={{ display: "block", marginTop: "-100px" }}
                                title={''}
                                onConfirm={() => publishQuestion(x.id)}
                                showCancel={true}
                                cancelBtnText={'CANCEL'}
                                confirmBtnText={'OK'}
                                onCancel={() => setAlert(null)}
                                disabled={publishTest?.isLoading}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                                cancelBtnCssClass={classes.button + " " + classes.warning}
                              >
                                {/* <div style={{ display: 'inline-flex' }}>
                            <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                            <h4 style={{ color: 'black' }}> Are you sure you want to Publish the selected question? <br /> No further edits will be allowed after published.
                              Please review your content and ensure it's ready for public access. </h4>
                          </div> */}
                                <div>
                                  <div style={{ paddingInline: '7%', color: 'black' }}>
                                    <h4 style={{ fontWeight: '500', color: 'brown' }}>Warning</h4>
                                    <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                                      <li>No further edits will be allowed in the selected question(s) after Published.<br /></li>
                                      <li> Please review and ensure that it's Ready for public access. <br /></li>
                                    </ul>
                                  </div>
                                  <br />
                                  <small> <ul style={{ listStyleType: 'none', textAlign: 'left', color: 'black', fontWeight: '500' }}>
                                    <li>Are you sure you want to Publish the selected question(s)? </li>
                                  </ul>

                                  </small>
                                </div>
                              </SweetAlert >
                            );
                          }} style={{ marginInline: '7px', float: 'right' }}
                          disabled={publishQuestionCall?.isLoading || x.status == 1 || x.status == 3}>
                          Publish
                        </Button> : ''}
                      {
                        showSolutionArray[i] ?
                          <Solution
                            solutionText={x?.solutionText}
                            solution={x?.solution}
                            contentType={x.contentType}

                          />
                          : null
                      }
                    </>
                  ))
                ) : (
                  <>
                    <CircularProgress style={{ marginInline: '45%', marginTop: '21%', textAlign: 'center' }} />
                    <h4 style={{ color: 'black', textAlign: 'center', marginRight: '40px' }}> Please wait...</h4>
                  </>
                ) :
              // <OMRPrintComponent pageSize={test.totalCount} testId={testId} testName={testName} />
              <PrintPreviewComponent pageSize={test.totalCount} testId={testId} testName={testName} />
          }



          {alert}
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={handleClose} severity={severity}>
              {alertMessage}
            </Alert>
          </Snackbar>

          {
            !test.isQuestionPreviewOnly && (
              <div style={{ width: "100%" }}>
                <Box display="flex" p={1} style={{ float: 'right' }}>
                  {console.log(currentQuestionIndex, totalCount, testStatus, questions?.length, "puenej56poo")}
                  {/* Download pdf */}
                  <Box p={1} >
                    <Button color="primary" round onClick={() => setIsShowPreview(isShowPreview ? false : true)}>
                      {
                        isShowPreview == true ?
                          'Show preview' : 'Hide preview'
                      }
                    </Button>
                  </Box>
                  {((pageNumber) * questionsPerPage + currentQuestionIndex + 1 >= totalCount) && testStatus != 1 &&     //&& totalScore != null this condition been removed bcz we are calculating total score at the time of assigning test
                    questions?.length > 0 ? (
                    <Box p={1} >
                      {isShowPublish == true && isShowPreview == true ?
                        <Button color="primary"
                          round
                          onClick={(e) => {
                            e.preventDefault();

                            setAlert(
                              <SweetAlert
                                style={{ display: "block", marginTop: "-100px" }}
                                title={''}
                                onConfirm={() => handlePublish(testId)}
                                showCancel={true}
                                cancelBtnText={'CANCEL'}
                                confirmBtnText={'OK'}
                                onCancel={() => setAlert(null)}
                                disabled={publishTest?.isLoading}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                                cancelBtnCssClass={classes.button + " " + classes.warning}
                              >
                                {/* <b style={{ marginBottom: '10px' }}> </b>  <br />
                              <br /> */}
                                <div style={{ paddingInline: '7%', color: 'black' }}>
                                  <h4 style={{ fontWeight: '500', color: 'brown' }}>Warning</h4>
                                  <ul style={{ padding: 0, textAlign: 'left' }}>
                                    <li>No further edits will be allowed in the selected test and questions after Published. Please review and ensure that it's Ready for public access. <br /></li>
                                    <li>Note that If the test is mapped with topics, then all questions added to the topics will be Published as well. <br /></li>
                                  </ul>
                                </div>
                                <br />
                                <small> <ul style={{ listStyleType: 'none', textAlign: 'left', color: 'black', fontWeight: '500' }}>
                                  <li>Are you sure you want to Publish the selected test and questions added? </li>
                                </ul>

                                </small>
                              </SweetAlert>
                            );
                          }} style={{ marginInline: '7px' }}
                          disabled={publishTest?.isLoading || testStatus == 3}>
                          {publishTest?.isLoading == true ? 'Publishing...' : 'Publish'}
                        </Button> : ''}
                      {
                        ((pageNumber) * questionsPerPage + currentQuestionIndex + 1 >= totalCount) ?? 0 ?
                          isLoading == false && isShowPreview == true ?
                            (
                              <Button color="primary" round onClick={handleSubmit}>
                                Submit
                              </Button>
                            ) : '' : ''
                      }

                    </Box>
                  ) : (
                    <Box p={1} flexGrow={1}></Box>
                  )}
                  {isShowPreview == true && test.navigation ? (
                    <Box p={1} justifyContent={"center"}>
                      <Button
                        justIcon
                        round
                        color="primary"
                        onClick={setPreviousQuestion}
                        disabled={(currentQuestionIndex == 0 && pageNumber == 1) || isLoading}
                      >
                        <ArrowBackIcon />
                      </Button>
                    </Box>
                  ) : null}
                  {
                    isShowPreview == true ?
                      <Box p={1}>
                        <Button
                          justIcon
                          round
                          color="primary"
                          onClick={setNextQuestion}
                          disabled={((pageNumber) * questionsPerPage + currentQuestionIndex + 1 >= totalCount) || isLoading}
                        >
                          <ArrowForwardIcon />
                        </Button>
                      </Box> : null
                  }
                </Box>
                {((pageNumber - 1) * pageSize + currentQuestionIndex + 1 >= totalCount) && currentQuestionIndex >= questions.length - 1 && testStatus == 1
                  ? <small style={{ color: 'red', fontWeight: 400, marginLeft: '30px' }}>Note: You need to change the test status to 'Ready' to submit or 'Publish' the test.</small> : ''
                }
              </div>
            )
          }
        </CardBody >
        // </Card>
      )}

      {
        showFF && !showCM && (
          <FeedbackFailScreen
            message={test.feedbackFail}
            score={actualScore}
            totalScore={testAttemptDetails ? testAttemptDetails?.totalScore : totalScore}
            handleSubmit={() => setShowCM(true)}
          />
        )
      }
      {
        showFP && !showCM && (
          <FeedbackPassScreen
            message={test.feedbackPass}
            score={actualScore}
            totalScore={testAttemptDetails ? testAttemptDetails?.totalScore : totalScore}
            handleSubmit={() => setShowCM(true)}
          />
        )
      }
      {
        showFM && !showCM && (
          <FeedbackMasteryScreen
            message={test.feedbackmastery}
            score={actualScore}
            totalScore={testAttemptDetails ? testAttemptDetails?.totalScore : totalScore}
            handleSubmit={() => setShowCM(true)}
          />
        )
      }
      {showCM && <ConcludeScreen message={test.concludeMessage} />}
    </div >
  );
};

export default QuestionWrapper;
